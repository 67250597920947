import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../login/authService/AuthContextProvider";

const PrivateRoute = ({ allowedRoles }) => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return null;
  }

  if (!user) {
    return <Navigate to="/" />;
  }

  return allowedRoles.includes(user.mobile.role) ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" />
  );
};

export default PrivateRoute;
