import React, { useContext, useEffect, useMemo, useState } from "react";
// import useStyles from './Style';
import HarvestTable from "./HarvestTable";
import { Box, Grid } from "@mui/material";
import { ReactComponent as CropCalander } from "../assets/img/CropCalander.svg";
import { FarmContext } from "../contextAPI/FarmContext";
import { useAuth } from "../login/authService/AuthContextProvider";
import useStyles, { MyFarmBox, TitleBox, TitleName } from "./Style";
import "../i18n";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { convertToDateFormat } from "../utility/Utility";

const HarvestWindow = () => {
  const classes = useStyles();
  const { filteredData, optedCrop } = useContext(FarmContext);
  const { bulkUploadData } = useAuth();
  const { crop_monitor_data: data = [] } = bulkUploadData || {};
  const { t } = useTranslation();
  const [weekData, setWeekData] = useState([]);

  const filteredDataMemoized = useMemo(() => {
    if (optedCrop) {
      return data.filter((farm) => farm.crop === optedCrop);
    }
    return data;
  }, [optedCrop, data]);

  useEffect(() => {
    const currentDate = new Date();
    // const startOfWeek = currentDate.getDay();
    const weeks = getWeeks(currentDate);

    // let weekData = [];
    const weekData = weeks.map((week) => ({
      week: week.label,
      yield: 0,
    }));
    // console.log("filteredDataMemoized length : ", filteredDataMemoized.length)

    // for (let i = 0; i < 5; i++) {
    //   weekData.push({
    //     week: `Week ${i + 1}`,
    //     yield: 0,
    //   });
    // }

    filteredDataMemoized.forEach((farm) => {
      const harvestDate = new Date(farm.harvest_date);
      harvestDate.setHours(0, 0, 0, 0);
      // console.log("weeks : ", weeks)
      // console.log("filteredDataMemoized : ", filteredDataMemoized)
      weeks.forEach((week, index) => {
        const weekStart = new Date(week.start);
        weekStart.setHours(0, 0, 0, 0);
        if (harvestDate >= weekStart && harvestDate <= week.end) {
          if (!isNaN(farm?.predicted_yield)) {
            const cropYield = Number(farm?.predicted_yield);
            weekData[index].yield += cropYield || 0;
            weekData[index].yield = parseFloat(
              weekData[index].yield.toFixed(2)
            );
          }
        }
      });
    });
    // console.log("weekData : ", weekData)
    setWeekData(weekData); // Set the week data state
  }, [filteredDataMemoized]);

  const getWeekRange = (currentDate) => {
    const dayOfWeek = currentDate.getDay();
    const diffToSunday = dayOfWeek; // Sunday is 0
    const sunday = new Date(currentDate);
    sunday.setDate(currentDate.getDate() - diffToSunday); // Set to last Sunday

    const saturday = new Date(sunday);
    saturday.setDate(sunday.getDate() + 6); // Saturday of the current week

    return { sunday, saturday };
  };

  const getWeeks = (currentDate) => {
    const weeks = [];
    let startDate = currentDate;

    // Get the current week's range (Sunday to Saturday)
    let { sunday, saturday } = getWeekRange(startDate);
    weeks.push({
      start: sunday,
      end: saturday,
      label: convertToDateFormat(saturday),
    });

    // Get the next 4 weeks' ranges
    for (let i = 1; i < 5; i++) {
      sunday = new Date(saturday);
      sunday.setDate(saturday.getDate() + 1); // Next Sunday

      saturday = new Date(sunday);
      saturday.setDate(sunday.getDate() + 6); // Saturday

      weeks.push({
        start: sunday,
        end: saturday,
        label: convertToDateFormat(saturday),
      });
    }

    return weeks;
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        padding: "1%",
        margin: "1%",
      }}
    >
      <TitleBox sx={{ ml: "20px" }}>
        <CropCalander className={classes.logo} />

        <TitleName variant="h3">{t("Harvest Calendar")}</TitleName>
      </TitleBox>
      <Grid
        container
        spacing={2}
        sx={{
          height: "100%",
          maxHeight: "75vh",
          marginLeft: "0px",
          mt: "2px",
          paddingBottom: "10px",
        }}
      >
        <Grid item xs={7} md={7} sm={7}>
          <HarvestTable />
        </Grid>
        <Grid
          item
          xs={5}
          md={5}
          sm={5}
          // classes={classes.cardGrid}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            maxHeight: "70vh",
            // ml:"10px"
          }}
        >
          <ResponsiveContainer
            width="95%"
            height={"100%"}
            style={{
              backgroundColor: "#224831",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <BarChart
              data={weekData}
              margin={{ top: 20, right: 10, left: 0, bottom: 20 }}
              style={{ backgroundColor: "#ffffff", borderRadius: "10px" }}
            >
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis dataKey="week">
                <Label
                  value={t("Realtime Harvest Yield Weekly")}
                  position="bottom"
                  offset={0}
                  style={{ fontSize: 16, fill: "#224831", fontWeight: "bold" }}
                />
              </XAxis>
              <YAxis>
                <Label
                  value={t("Total Yield")}
                  angle={270}
                  position="center"
                  dx={-15}
                  style={{ fontSize: 16, fill: "#224831", fontWeight: "bold" }}
                />
              </YAxis>
              <Tooltip formatter={(value) => `${value} Tonnes`} />
              {/* <Legend /> */}
              <Bar dataKey="yield" fill="#224831" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HarvestWindow;
