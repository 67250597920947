import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export default function EnhancedTable({ data }) {
  const [selected, setSelected] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data || []);
  const navigate = useNavigate();

  // Filter data based on the search term
  useEffect(() => {
    const results = data.filter(
      (row) =>
        row.farm_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.predicted_crop.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(results);
  }, [searchTerm, data]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredData.map((n) => n._id);
      setSelected(newSelected);
      setIsAllSelected(true);
    } else {
      setSelected([]);
      setIsAllSelected(false);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    setIsAllSelected(newSelected.length === filteredData.length);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNavigate = (farmData) => {
    navigate(`/dashboard/${farmData.mobile}/${farmData.farm_name}`);
  };

  return (
    <Box sx={{ width: "100%", height: "500px" }}>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          backgroundColor: "#224831",
          borderRadius: "10px",
          // overflow: 'hidden'
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "right" }}>
          <TextField
            variant="standard"
            placeholder="Search by farm or crop"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
              backgroundColor: "white",
              borderRadius: "10px",
              width: "165px",
              "& .MuiOutlinedInput-root": {
                borderColor: "#FF6600",
                borderRadius: "25px",
                height: "50px",
              },
            }}
          />
        </Toolbar>
        <TableContainer
          sx={{
            maxHeight: 500,
            "&::-webkit-scrollbar": { width: "8px" },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(252, 175, 3, 0.5)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "rgba(252, 175, 3, 0.8)",
            },
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    color: "#fcaf03",
                    fontSize: "1.2rem",
                    backgroundColor: "#224831",
                  }}
                >
                  Farm Name
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: "#fcaf03",
                    fontSize: "1.2rem",
                    backgroundColor: "#224831",
                  }}
                >
                  Crop
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: "#fcaf03",
                    fontSize: "1.2rem",
                    backgroundColor: "#224831",
                  }}
                >
                  Sowing Date
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: "#fcaf03",
                    fontSize: "1.2rem",
                    backgroundColor: "#224831",
                  }}
                >
                  Harvest Date
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: "#fcaf03",
                    fontSize: "1.2rem",
                    backgroundColor: "#224831",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.length ? (
                filteredData.map((row) => (
                    <TableRow hover key={row._id}>
                      <TableCell sx={{ color: "#ffffff" }}>
                        {row.farm_name}
                      </TableCell>
                      <TableCell sx={{ color: "#ffffff" }}>
                        {row.predicted_crop}
                      </TableCell>
                      <TableCell sx={{ color: "#ffffff" }}>
                        {row.predicted_sowing_date}
                      </TableCell>
                      <TableCell sx={{ color: "#ffffff" }}>
                        {row.harvest_date}
                      </TableCell>
                      <TableCell>
                        <Button
                          sx={{
                            backgroundColor: "#fcaf03",
                            borderRadius: "10px",
                            color: "#224831",
                          }}
                          variant="contained"
                          onClick={() => handleNavigate(row)}
                        >
                          Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center" sx={{ color: "white" }}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
