import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { CustomButton, TableHeader } from "./Style";
import * as XLSX from "xlsx";
import {
  convertToDateFormat,
  convertToDateFormat_v2,
} from "../utility/Utility";
import SwapVertIcon from "@mui/icons-material/SwapVert";
// import useStyles from "./Style";
import "../i18n";
import { useTranslation } from "react-i18next";
import { useAuth } from "../login/authService/AuthContextProvider";

export default function EnhancedTable({ data }) {
  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState(data || []);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();

  const [sortedData, setSortedData] = useState(data);
  const [sortConfig, setSortConfig] = useState({
    key: "farm_name",
    direction: "ascending",
  });

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedArray = [...sortedData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setSortedData(sortedArray);
    setSortConfig({ key, direction });
  };

  // Filter data based on the search term
  useEffect(() => {
    if (data.length > 0) {
      const results = data.filter((row) => {
        const farmName = row?.farm_name || "";
        const crop = row?.crop || "";
        return (
          farmName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          crop.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      // setFilteredData(results);
      setSortedData(results);
    }
  }, [searchTerm, data]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNavigate = (farmData) => {
    navigate(`/dashboard/${farmData.mobile}/${farmData.farm_name}`);
  };

  const convertDateFormat = (dateString, t) => {
    if (!dateString) return t("N/A");
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}_${month}_${year}`;
  };

  const handleDownload = () => {
    const currentDate = new Date();
    const dateString = convertDateFormat(currentDate);
    // console.log("sortedData : ", sortedData)
    // debugger
    const exportData = sortedData.map((row) => ({
      Mobile: row.mobile,
      "Farm Name": row.farm_name,
      "Crop Season": row.crop_season,
      "Productivity (kg/ha)": Number((row.crop_yield * 1000).toFixed(0)),
      "Total Yield (kg)": Number((row.predicted_yield * 1000).toFixed(0)),
      "Farm Area (ha)": Number((row.area * 0.404686).toFixed(2)),
      "Harvest Date": row.harvest_date ? new Date(row.harvest_date) : null,
      "Harvest Window": convertToDateFormat_v2(row.harvest_window),
      "Predicted Crop": row.crop,
      "Predicted Sowing Date": row.sowing_date
        ? new Date(row.sowing_date)
        : null,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Farms_Data`);

    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "Mobile",
          "Farm Name",
          "Crop Season",
          "Productivity (kg/ha)",
          "Total Yield (kg)",
          "Farm Area (ha)",
          "Harvest Date",
          "Harvest Window",
          "Predicted Crop",
          "Predicted Sowing Date",
        ],
      ],
      { origin: "A1" }
    );

    XLSX.writeFile(workbook, `ELAI_Report_${user?.mobile?.name}_${dateString}.xlsx`);
  };

  // const classes = useStyles();
  return (
    <Box>
      <Paper
        sx={{
          height: "100%",
          // mb: 2,
          backgroundColor: "#224831",
          borderRadius: "10px",

          // minWidth:'600px'
          // overflow: 'hidden'
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "right" }}>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "10px",
              color: "#fcaf03",
              borderColor: "#fcaf03",
              marginRight: "10px",
              "&:hover": { color: "#224831", background: "#fcaf03" },
            }}
            onClick={handleDownload}
          >
            {t("Download Excel")}
          </Button>
          <TextField
            variant="standard"
            placeholder={t("Search by farm or crop")}
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
              backgroundColor: "white",
              borderRadius: "10px",
              width: "165px",
              "& .MuiOutlinedInput-root": {
                borderColor: "#FF6600",
                borderRadius: "25px",
                height: "50px",
              },
            }}
          />
        </Toolbar>
        <TableContainer
          sx={{
            maxHeight: 550,
            minHeight: 550,
            backgroundColor:"#ffffff",
            // overflowX: "hidden",
            "&::-webkit-scrollbar": { width: "8px" },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(252, 175, 3, 0.5)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "rgba(252, 175, 3, 0.8)",
            },
          }}
        >
          <Table
            stickyHeader
            // sx={{ minWidth: "700px" }}
            aria-labelledby="tableTitle"
          >
            <TableHead>
              <TableRow>
                <TableHeader
                  align="left"
                  onClick={() => sortData("farm_name")}
                  sx={{ cursor: "pointer", boxShadow: 5 }}
                >
                  {t("Farm Name")}{" "}
                  {sortConfig.key === "farm_name" && <SwapVertIcon />}
                </TableHeader>
                <TableHeader
                  align="left"
                  onClick={() => sortData("crop")}
                  sx={{ cursor: "pointer", boxShadow: 5 }}
                >
                  {t("Crop")} {sortConfig.key === "crop" && <SwapVertIcon />}
                </TableHeader>
                <TableHeader
                  align="left"
                  onClick={() => sortData("sowing_date")}
                  sx={{ cursor: "pointer", boxShadow: 5 }}
                >
                  {t("Sowing Date")}{" "}
                  {sortConfig.key === "sowing_date" && <SwapVertIcon />}
                </TableHeader>
                <TableHeader
                  align="left"
                  onClick={() => sortData("harvest_date")}
                  sx={{ cursor: "pointer", boxShadow: 5 }}
                >
                  {t("Harvest Date")}{" "}
                  {sortConfig.key === "harvest_date" && <SwapVertIcon />}
                </TableHeader>
                <TableHeader align="left" sx={{ boxShadow: 5 }}>
                  {t("Action")}
                </TableHeader>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                color: "#224831",
                backgroundColor: "#ffffff",
                backgroundBlendMode: "hard-light",
                borderColor: "#224831",
                borderWidth: 2,
                borderStyle: "solid",
              }}
            >
              {sortedData.length > 0 ? (
                sortedData.map((row) => (
                  <TableRow
                    hover
                    key={row._id}
                    sx={{
                      borderColor: "#224831",
                      borderWidth: 2,
                      borderStyle: "solid",
                    }}
                  >
                    <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                      {row.farm_name}
                    </TableCell>
                    <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                      {t(`${row.crop}`)}
                    </TableCell>
                    <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                      {convertToDateFormat(row.sowing_date, t)}
                    </TableCell>
                    <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                      {convertToDateFormat(row.harvest_date, t)}
                    </TableCell>
                    <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                      <CustomButton
                        variant="contained"
                        onClick={() => handleNavigate(row)}
                      >
                        {t("Details")}
                      </CustomButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center" sx={{ color: "#224831", boxShadow: 2 }}>
                    {t("No data available")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
