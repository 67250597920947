import React, { createContext, useContext } from "react";
import { useEffect, useState, useMemo, useCallback } from "react";
import Summarycards from "./Summarycards";
import { Container, Box, Stack, Typography, Button, Grid, Card, CardContent, Avatar } from "@mui/material";
import { ReactComponent as Myfarm } from "../assets/img/Myfarm.svg";
import EnhancedTable from "./Table";
import plant from "../assets/logo/plant.svg";
import { FarmContext } from "../contextAPI/FarmContext";
// import AreaChartIcon from '@mui/icons-material/AreaChart'; // Icon for Area
import MapIcon from '@mui/icons-material/Map'; // Use Map icon for Area
import StoreIcon from '@mui/icons-material/Store';
import useStyles, {
  // CardGrid,
  CustomButton,
  // Logo,
  MyFarmBox,
  TitleBox,
  TitleName,
} from "./Style";
import { useAuth } from "../login/authService/AuthContextProvider";
// import { Height } from "@mui/icons-material";
import '../i18n'
import { useTranslation } from 'react-i18next';
import InfoCard from "../dashboard/InfoCard";

const Myfarms = () => {
  const { bulkUploadData } = useAuth();
  const { setOptedCrop, optedCrop, setFilteredData } = useContext(FarmContext);
  const classes = useStyles();
  const {t} = useTranslation();

  const handleCropClick = (e) => {
    // debugger;
    if (optedCrop !== e.target.value) {
      setOptedCrop(e.target.value);
      setFilteredData(data.filter((farm) => farm.crop === optedCrop))
    }
  };

  const { crop_monitor_data: data = [] } = bulkUploadData || {};

  
  const totalArea = useMemo(
    () => data.reduce((sum, farm) => sum + (farm.area || 0), 0),
    [data]
  );

  const uniqueCrops = useMemo(() => {
    return Array.from(new Set(
      data
      .map((farm) => farm.crop)
      .filter((crop) => crop !== null && crop !== "")
    ));
  }, [data]);

  return (
    <MyFarmBox xs={12} md={12} lg={12} sm={12}>
      <TitleBox sx={{ml:"20px"}}>
        {/* <Box sx={{ height: "75px", width: "65px", display: "inline-block" }}> */}
        <Myfarm className={classes.logo} />
        {/* </Box> */}
        <TitleName variant="h3">{t("My Farms")}</TitleName>
      </TitleBox>
      {/* <Grid
        container
        md={12}
        lg={12}
        sm={12}
        xs={12}
        spacing={2}
        sx={{ height: "100%", marginLeft:"20px", marginTop:"5px" }}
      > */}
      <Grid
        container
        md={12}
        lg={12}
        sm={12}
        xs={12}
        // spacing={5}
        sx={{ height: "100%", marginLeft: "15px" }}
      >
        <Grid item xs={8} md={8} sm={8} lg={8}>
          <EnhancedTable data={data} />
        </Grid>
        {/* </Grid> */}
        {/* <Grid
          container
          md={4}
          lg={4}
          sm={4}
          xs={4}
          spacing={2}
          sx={{ height: "100%", marginTop:"0px", marginLeft:"5px" }}
        > */}
        <Grid
          item
          xs={4}
          md={4}
          sm={4}
          lg={4}
          // className={classes.cardGrid}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "100%",
          }}
        >
          {/* <Stack sx={{ width: "100%", minHeight: "100%" }}> */}
            {/* <Summarycards
              titleone={t("Area under management")}
              contentone={`${totalArea.toFixed(2)} ${t("Acres")}`}
              titletwo={t("Farms under management")}
              contenttwo={`${data.length} ${t("Farms")}`}
              // style={{Height:"50px"}}
            /> */}
            {/* <InfoCard sx={{maxWidth:"10%"}}
                        title={t("Area under management")}
                        content={`${totalArea.toFixed(2)} ${t("Acres")}`}
                      /> */}

<Card variant="outlined" sx={{backgroundColor: '#224831', margin: "0px 20px", borderRadius:"10px"}}>
          <CardContent>
            <Box display="flex" alignItems="center" mb={2}>
              <Avatar sx={{ backgroundColor: 'transparent', marginRight: 2 }}>
              <MapIcon />
              </Avatar>
              <Typography variant="h6" component="div" color="#fcaf03">
              {t("Area under Management")}
              </Typography>
            </Box>
            <Typography variant="h5" component="div" color="#ffffff" sx={{}}>
            {`${totalArea.toFixed(2)} ${t("Acres")}`}
            </Typography>
          </CardContent>
        </Card>

        <Card variant="outlined" sx={{backgroundColor: '#224831', margin: "20px", borderRadius:"10px"}}>
          <CardContent>
            <Box display="flex" alignItems="center" mb={2}>
              <Avatar sx={{ backgroundColor: 'transparent', marginRight: 2 }}>
              <StoreIcon />
              </Avatar>
              <Typography variant="h6" component="div" color="#fcaf03">
              {t("Farms under Management")}
              </Typography>
            </Box>
            <Typography variant="h5" component="div" color="#ffffff" sx={{}}>
            {`${data.length} ${t("Farms")}`}
            </Typography>
          </CardContent>
        </Card>
                      {/* <InfoCard
                        title={t("Farms under management")}
                        content={`${data.length} ${t("Farms")}`}
                      /> */}
            <Summarycards
              titleone={t("Crops under management")}
              subHeader={t("select crop to view details")}
              contentone={uniqueCrops.map((crop, index) => (
                <CustomButton
                  key={index}
                  value={crop}
                  variant="contained"
                  startIcon={
                    <img
                      src={plant}
                      alt={crop}
                      style={{
                        height: "20px", // Maintain aspect ratio
                        width: "20px",
                        maxHeight: "20px", // Fallback max height for larger screens
                        maxWidth: "20px", // Fallback max width for larger screens
                        marginLeft: "0px",
                        paddingLeft:"0px"
                      }}
                    />
                  }
                  sx={{
                    padding: { xs: "2px 2px", sm: "3px 3px" }, // Adjust padding based on screen size
                    width: { xs: "60px", sm: "80px", md: "150px" }, // Width decreases on smaller screens
                    fontSize: { xs: "8px", sm: "10px", md: "15px" }, // Adjust font size for text visibility
                    margin: "2%",
                  }}
                  onClick={(e) => handleCropClick(e)} // Update selected crop on click
                >
                  {t(`${crop}`)}
                </CustomButton>
              ))}
            />
          {/* </Stack> */}
        </Grid>
      </Grid>
      {/* </Grid> */}
    </MyFarmBox>
  );
};

export default Myfarms;
