import React from 'react';
import './App.css';
import Allroutes from './pages/Allroutes';
import NewUser from "./pages/NewUser"
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightRegular: 400, // Default is 400, you can adjust this
    fontWeightMedium: 500,  // You can adjust this according to your preference
    fontWeightBold: 700, 
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Allroutes />
      {/* <NewUser/> */}
    </ThemeProvider>
  );
}

export default App;
