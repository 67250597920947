import {
  Box,
  Button,
  Dialog,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Stack,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { TableHeader } from "../summary/Style";
import { FarmContext } from "../contextAPI/FarmContext";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import SaveIcon from "@mui/icons-material/Save";
import { convertToDateFormat } from "../utility/Utility";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../i18n";
import { useTranslation } from "react-i18next";
import waitingVid from "../assets/img/waiting.mp4";

export default function EditCropModel({ mobile, farm_name, seasonedFarms }) {
  const AWS_URL = process.env.REACT_APP_AWS_LAMBDA_URL;
  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState("");
  const [newSeason, setNewSeason] = useState(false);
  const [sowingDate, setSowingDate] = useState("");
  const { geojsonData } = useContext(FarmContext);
  const currentYear = new Date().getFullYear(); // Default to current year
  const { t } = useTranslation();
  const [seasonedFarmname, setSeasonedFarmname] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const navigate = useNavigate();

  const [rows, setRows] = useState([
    {
      mobile: mobile,
      farm_name: farm_name,
      crop: geojsonData?.crop,
      sowing_date: geojsonData?.sowing_date,
      crop_season: geojsonData?.crop_season,
      new: false,
    },
  ]);

  useEffect(() => {
    if (seasonedFarms?.length > 0) {
      const formattedFarms = seasonedFarms.map(farm => ({
        mobile: farm.mobile,
        farm_name: farm.farm_name,
        crop: farm.crop,
        sowing_date: farm.sowing_date,
        crop_season: farm.crop_season,
        new: false, // Add any other properties you need
      }));
  
      // Filter out any farms that already exist in the rows based on farm_name
    const newFarms = formattedFarms.filter(farm => 
      !rows.some(existingFarm => existingFarm.farm_name === farm.farm_name)
    );

    // If there are new farms, update the rows state
    if (newFarms.length > 0) {
      setRows(prevRows => [...prevRows, ...newFarms]);
    }
    }
  }, [seasonedFarms, rows]);

  const validateInput = async (event) => {
    event.preventDefault();
    alert(`Crop Submitted successfully. Please wait 24 to see the changes.`);
    setOpen((prevState) => !prevState);
    setShowSuccessAlert(true);

    const farmData = {
      mobile: mobile,
      farm_name: seasonedFarmname,
      date: sowingDate,
      crop: crop,
      seasoned_farm: true,
      original_farm: farm_name,
    };

    try {
      // // debugger;
      const response = await axios.post(`${AWS_URL}addNewCropCycle`, farmData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = response.data;
      if (responseData.statusCode === 200) {
        if (responseData.body === "API queries successful") {
          // Skip health score check if API queries are successful
          navigate(`/dashboard/${mobile}/${farm_name}`);
        } else if (responseData.body === "Farm created successfully") {
          // Skip health score check if API queries are successful
          navigate(`/dashboard/${mobile}/${farm_name}`);
        } else if (responseData.body === "no data") {
          // Handle the case when no data is available
          // setShowSuccessAlert(false);
          alert("Something went wrong! Please try again");
        }
      } else if (responseData.statusCode === 504) {
        // Handle timeout scenario: Retry fetching data until health score is available
        fetchDataUntilHealthScore(mobile, seasonedFarmname);
      } else if (responseData.statusCode === 404) {
        // Handle timeout scenario: Retry fetching data until health score is available
        alert("Something went wrong! Please try again");
      } else if (responseData.statusCode === 500) {
        // Handle timeout scenario: Retry fetching data until health score is available
        alert("Something went wrong! Please try again");
      } else if (responseData.body === "Database operation failed") {
        // setShowSuccessAlert(false);
        alert("Something went wrong! Please try again");
      }
    } catch (error) {
      if (error.response?.status === 504) {
        fetchDataUntilHealthScore(mobile, seasonedFarmname);
      } else {
        // setShowSuccessAlert(false);
        alert("Something went wrong! Please try again");
        console.warn("Error saving farm data:", error);
      }
    }
  };

  const fetchDataUntilHealthScore = async (mobile, farmName) => {
    const abortController = new AbortController();
    const timeoutSignal = abortController.signal;

    let hasNavigated = false;
    let alertShown = false;

    const timeout = new Promise((_, reject) => {
      const timer = setTimeout(() => {
        if (!timeoutSignal.aborted && !hasNavigated) {
          alertShown = true;
          setShowSuccessAlert(false);
          alert("Server taking too much time... Please try again!");
          // reject(new Error("Navigation triggered"));
        }
      }, 90000);

      timeoutSignal.addEventListener("abort", () => {
        clearTimeout(timer);
      });
    });

    try {
      let result = await axios.post(
        `${AWS_URL}getCropMonitorData_v2`,
        {
          mobile: mobile,
          farm_name: farmName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          signal: timeoutSignal,
        }
      );

      // Check if health_score is available; if not, continue fetching
      while (!result?.data?.result?.health_score) {
        result = await Promise.race([
          new Promise((resolve) => setTimeout(resolve, 3000)), // Delay before next request
          timeout,
        ]);

        if (result !== timeout) {
          result = await axios.post(
            `${AWS_URL}getCropMonitorData_v2`,
            {
              mobile: mobile,
              farm_name: farmName,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              signal: timeoutSignal,
            }
          );
        }
      }
      hasNavigated = true;
      if (!alertShown)
        // Once health score is found, navigate to the dashboard
        navigate(`/dashboard/${mobile}/${farmName}`);
    } catch (error) {
      // Timeout or other errors during fetching data
      setShowSuccessAlert(false);
      alert("Something went wrong! Please try again");
      console.warn("Error fetching crop monitor data:", error);
    }
  };

  const navigateFarm = (farmName) => {
    navigate(`/dashboard/${mobile}/${farmName}`);
  };

  const saveCrop = () => {
    alert(`Crop Submitted successfully. Please wait 24 to see the changes.`);
    setOpen((prevState) => !prevState);
  };

  const addNewCropCycle = () => {
    // alert(`Are you sure you want to add a new crop cycle for existing farm`);
    if (!newSeason) {
      setNewSeason((prevState) => !prevState);
      // console.log("seasonedFarm : ", `${farm_name}_S${rows.length}`);
      setSeasonedFarmname(`${farm_name}_S${rows.length}`);
      setRows((prevRows) => [
        ...prevRows,
        {
          mobile: mobile,
          farm_name: `${farm_name}_S${rows.length}`,
          crop: crop,
          sowing_date: "",
          crop_season: "-",
          new: true,
        },
      ]);
    }
    // setOpen((prevState) => !prevState)
  };

  const discardNewCropCycle = () => {
    // alert(`Are you sure you want to add a new crop cycle for existing farm`);
    if (newSeason) {
      setNewSeason((prevState) => !prevState);
      // console.log("seasonedFarm : ", `${farm_name}_S${rows.length}`);
      setSowingDate("");
      setCrop("");
      setSeasonedFarmname("");
      if (rows.length > 0 && rows[rows.length - 1].new) {
        // Remove the last element if `new` is true
        setRows(rows.slice(0, rows.length - 1));
      }
    }
  };

  const handleChange = (event) => {
    const userConfirmed = window.confirm(
      `Are you sure you want to set the crop as ${event.target.value}?`
    );

    if (userConfirmed) {
      setCrop(event.target.value || "");
      // console.log("Crop selected : ", event.target.value);
    } else {
      // setSelectCrop((prevState) => !prevState);
      // console.log("User cancelled");
    }
  };

  const SelectDropdown = ({ value, onChange }) => {
    const crops = [
      "Rice",
      "Wheat",
      "Maize",
      "Cotton",
      "Mustard",
      "White_Onion",
      "Soyabean",
      "Potato",
      "Gram",
    ];

    return (
      <Select
        native
        value={value}
        onChange={onChange}
        input={<OutlinedInput label="crop" id="demo-dialog-native" />}
        sx={{
          height: "30px",
          color: "#224831",
        }}
      >
        <option
          key={"null"}
          style={{ color: "#224831" }}
          value={""}
          disabled
          hidden
        >
          Select Crop (Optional)
        </option>
        {crops.map((crop) => (
          <option key={crop} style={{ color: "#224831" }} value={crop}>
            {crop}
          </option>
        ))}
      </Select>
    );
  };

  return (
    <>
      {!showSuccessAlert && (
        <Box>
          <Button
            variant="outlined"
            sx={{
              height: "50px",
              color: "#224831",
              borderColor: "#224831",
              borderRadius: "10px",
              "&:hover": { background: "#fcaf03" },
            }}
            onClick={() => setOpen((prevState) => !prevState)}
          >
            <AddToPhotosIcon />
          </Button>
          <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={() => setOpen((prevState) => !prevState)}
            sx={{
              color: "#fcaf03",
              borderRadius: "50px",
              position: "fixed",
              left: "0%",
              top: "0%",
            }}
            PaperProps={{
              sx: {
                border: "1px solid #fcaf03",
                borderRadius: "20px",
                overflow: "hidden",

                maxHeight: 550,
                minHeight: 550,
                minWidth: 1000,
                maxWidth: 1000,
              },
            }}
          >
            <TableContainer
              sx={{
                maxHeight: 500,
                minHeight: 500,
                minWidth: 1000,
                maxWidth: 1000,
                "&::-webkit-scrollbar": { width: "8px" },
                "&::-webkit-scrollbar-thumb": {
                  background: "rgba(252, 175, 3, 0.5)",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "rgba(252, 175, 3, 0.8)",
                },
              }}
            >
              <Table stickyHeader aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    <TableHeader align="left">Sr No</TableHeader>
                    <TableHeader align="left">Mobile</TableHeader>
                    <TableHeader align="left">Farm Name</TableHeader>
                    <TableHeader align="left">Crop</TableHeader>
                    <TableHeader align="left">Sowing Date</TableHeader>
                    <TableHeader align="left">Crop Season</TableHeader>
                    <TableHeader align="left">Load</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow hover key={1}>
                        <TableCell sx={{ color: "#224831" }}>
                          {1}
                        </TableCell>
                        <TableCell sx={{ color: "#224831" }}>
                          {mobile}
                        </TableCell>
                        <TableCell sx={{ color: "#224831" }}>
                          {farm_name}
                           {newSeason ? (`${row.farm_name}`) : (`${row.farm_name+`_S${index}` }`) } 
                        </TableCell>
                        <TableCell sx={{ color: "#224831" }}>
                          {geojsonData?.crop}
                        </TableCell>
                        <TableCell sx={{ color: "#224831", padding: "0px" }}>
                          {convertToDateFormat(geojsonData?.sowing_date, t)}
                        </TableCell>
                        <TableCell sx={{ color: "#224831" }}>
                          {geojsonData?.crop_season}
                        </TableCell>
                        <TableCell sx={{ color: "#224831" }}>
                          {
                            <SaveIcon
                              onClick={() => navigateFarm(farm_name)}
                              sx={{
                                color: "#fcaf03",
                                "&:hover": {
                                  color: "#224831", // Change to a different color on hover
                                  cursor: "pointer", // Change cursor to pointer on hover
                                },
                              }}
                            />
                          }
                        </TableCell>
                      </TableRow> */}
                  {rows && rows.length > 0 ? (
                    rows?.map((row, index) => (
                      <TableRow hover key={index}>
                        <TableCell sx={{ color: "#224831" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ color: "#224831" }}>
                          {row.mobile}
                        </TableCell>
                        <TableCell sx={{ color: "#224831" }}>
                          {row.farm_name}
                        </TableCell>
                        <TableCell sx={{ color: "#224831", minWidth:"230px" }}>
                          {row.crop ? (
                            row.crop
                          ) : (
                            <SelectDropdown
                              value={crop}
                              onChange={(e) => setCrop(e.target.value)}
                            />
                          )}
                        </TableCell>
                        <TableCell sx={{ color: "#224831", padding: "0px", minWidth:"120px" }}>
                          {row.sowing_date ? (
                            convertToDateFormat(row.sowing_date, t)
                          ) : (
                            // <DateInput />
                            // <TextField type="date" value={sowingDate} onChange={(e)=>setSowingDate(e.target.value)}/>
                            <TextField
                              // label="Sowing Date"
                              value={sowingDate}
                              onChange={(e) => setSowingDate(e.target.value)}
                              fullWidth
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                inputProps: {
                                  min: "2020-01-01", // Optional: set minimum date
                                  max: "2025-02-28", // Optional: set maximum date
                                },
                              }}
                              variant="outlined"
                              margin="normal" // Reduced margin
                              size="small"
                              color="success"
                              sx={{
                                backgroundColor: "#ffffff",
                                "& .MuiInputLabel-root.Mui-focused": {
                                  color: "#fcaf03", // Label color when typing
                                },
                                borderRadius: "5px",
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell sx={{ color: "#224831" }}>
                          {row.crop_season}
                        </TableCell>
                        <TableCell sx={{ color: "#224831" }}>
                          {!row.new ? (
                            <SaveIcon
                              onClick={() => navigateFarm(row.farm_name)}
                              sx={{
                                color: "#fcaf03",
                                "&:hover": {
                                  color: "#224831", // Change to a different color on hover
                                  cursor: "pointer", // Change cursor to pointer on hover
                                },
                              }}
                            />
                          ) : (
                            <SaveIcon sx={{ color: "grey" }} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack
              spacing={2}
              direction="row"
              sx={{
                alignContent: "space-around",
                left: "90%",
                top: "90%",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: "140px",
                  height: "30px",
                  color: "#224831",
                  borderColor: "#224831",
                  borderRadius: "10px",
                  "&:hover": { background: "#fcaf03" },
                }}
                disabled={newSeason}
                onClick={addNewCropCycle}
              >
                Add New Cycle
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: "140px",
                  height: "30px",
                  color: "#224831",
                  borderColor: "#224831",
                  borderRadius: "10px",
                  "&:hover": { background: "#fcaf03" },
                }}
                disabled={!sowingDate}
                onClick={validateInput}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: "140px",
                  height: "30px",
                  color: "#224831",
                  borderColor: "#224831",
                  borderRadius: "10px",
                  "&:hover": { background: "#fcaf03" },
                }}
                disabled={!newSeason}
                onClick={discardNewCropCycle}
              >
                Discard
              </Button>
            </Stack>
            {/* </Paper> */}
          </Dialog>
        </Box>
      )}
      {showSuccessAlert && (
        <video
          src={waitingVid}
          alt="Elai Agritech"
          style={{
            height: "100%",
            width: "100vw",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 99999,
            objectFit: "fill",
          }}
          autoPlay
          // controls
          loop
          muted
        ></video>
      )}
    </>
  );
}
