import { Box, Button, styled } from "@mui/material";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { Line } from "react-chartjs-2";
import { FarmContext } from "../../contextAPI/FarmContext";
import axios from "axios";
import '../../i18n'
import { useTranslation } from 'react-i18next';

export const WeatherButton = styled(Button)(({ theme, selected }) => ({
  backgroundColor: selected ? "#fcaf03" : "transparent",
  color: selected ? "#224831" : "#224831",
  borderColor: selected ? "#1976d2" : "#224831",
}));

const WeatherChart = () => {
  const {t} = useTranslation();
  const [weatherData, setWeatherData] = useState({
    temperature: [],
    precipitation: [],
    humidity: [],
    time: [],
  });
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDataset, setSelectedDataset] = useState("Temperature");
  const weatherURL = process.env.REACT_APP_WEATHER_API;
  const key = process.env.REACT_APP_WEATHER_API_KEY;
  const AWS_Lambda_URL = process.env.REACT_APP_AWS_LAMBDA_URL;
  const present_date = new Date().toISOString().split("T")[0]; // today's date in yyyy-mm-dd format
  const presentDate = new Date(present_date);
  // const [forecastFetched, setForecastFetched] = useState(false);
  // const [pastDataFetched, setPastDataFetched] = useState(false);
  const [cachedPastData, setCachedPastData] = useState(null);
  const [cachedForecastData, setCachedForecastData] = useState(null);
  const [location, setLocation] = useState({});
  // const [selected, setSelected] = useState(false); // Default to false on page load

  const { farmCoordinates, geojsonData } = useContext(FarmContext);
const convertToDateFormat = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const addDaysToDate = (dateString, days) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    date.setDate(date.getDate() + days); // Adds 'days' to the current date
    return convertToDateFormat(date);
  };

  useEffect(() => {
    // // debugger;
    setCachedPastData(null);
    setCachedForecastData(null);
    if (
      farmCoordinates &&
      geojsonData?.sowing_date
      // geojsonData.harvest_date
    ) {
      const { latitude, longitude } = farmCoordinates;
      // // debugger;
      if (!geojsonData?.harvest_date) {
        // // debugger;
        getWeatherData(
          latitude,
          longitude,
          convertToDateFormat(geojsonData.sowing_date),
          addDaysToDate(geojsonData.sowing_date, 90)
        );
      } else {
        // debugger;
        getWeatherData(
          latitude,
          longitude,
          convertToDateFormat(geojsonData.sowing_date),
          geojsonData?.harvest_date
        );
      }
    }
    // eslint-disable-next-line no-use-before-define
  }, [farmCoordinates, geojsonData?.sowing_date, geojsonData?.harvest_date]);

  const handleDatasetClick = (dataset) => {
    setSelectedDataset(dataset);
  };

  const getPastData = useCallback(
    async (latitude, longitude, start_dt, end_dt) => {
      // debugger;
      if (cachedPastData) return cachedPastData; // Return cached past data if already fetched
      // debugger;
      const fetchData = async (start, end) => {
        // debugger
        const pastDataResponse = await fetch(
          `${weatherURL}history.json?key=${key}&q=${latitude},${longitude}&dt=${start}&end_dt=${end}&hour=12`
        );
        // debugger;
        // console.log("past data fetched");
        if (!pastDataResponse.ok) throw new Error("Error fetching past data");
        // debugger;
        const past_data = await pastDataResponse.json();
        // debugger;
        setLocation(past_data?.location);
        return past_data;
      };
      // debugger
      let startDate = new Date(start_dt);
      let endDate = new Date(end_dt);
      let allData = [];
      let result = {};
      let locationData = {};
      // debugger
      while (endDate - startDate > 30 * 24 * 60 * 60 * 1000) {
        // debugger
        let chunkEndDate = new Date(startDate);
        chunkEndDate.setDate(startDate.getDate() + 30);
        if (chunkEndDate > endDate) chunkEndDate = endDate;
        // debugger
        const data = await fetchData(
          startDate.toISOString().split("T")[0],
          chunkEndDate.toISOString().split("T")[0]
        );
        // debugger
        locationData = data?.location;
        if (data) allData = [...allData, ...data?.forecast?.forecastday];
        // debugger
        startDate = new Date(chunkEndDate);
        startDate.setDate(startDate.getDate() + 1);
      }
      // debugger
      if (endDate > startDate) {
        // debugger
        const data = await fetchData(
          startDate.toISOString().split("T")[0],
          endDate.toISOString().split("T")[0]
        );
        // debugger
        locationData = data?.location;
        if (data) allData = [...allData, ...data?.forecast?.forecastday];
        // debugger
      }
      // debugger
      setCachedPastData(allData); // Cache the past data after it's fetched
      result = { allData, locationData };
      // debugger
      return result;
    },
    [cachedPastData]
  );

  const getForecastData = useCallback(
    async (latitude, longitude) => {
      // debugger
      if (cachedForecastData) return cachedForecastData; // Return cached forecast data if already fetched
      // debugger
      const forecastDataResponse = await fetch(
        `${weatherURL}forecast.json?key=${key}&q=${latitude},${longitude}&days=14&hour=12`
      );
      // console.log("forecast data fetched");
      if (!forecastDataResponse.ok)
        throw new Error("Error fetching forecast data");
      // debugger;
      const forecast_data = await forecastDataResponse.json();
      setCachedForecastData(forecast_data?.forecast?.forecastday); // Cache the forecast data
      // debugger;
      return forecast_data;
    },
    [cachedForecastData]
  );

  const getWeatherData = async (latitude, longitude, sowing_dt, harvest_dt) => {
    // debugger;
    const fetchedTempData = await fetchWeather(
      latitude,
      longitude,
      sowing_dt,
      harvest_dt
    );
    //console.log("fetchedTempData : ", fetchedTempData);
    // debugger;
    if (fetchedTempData?.response) {
      // debugger;
      setWeatherData({
        temperature: fetchedTempData.response.temperatureValues,
        precipitation: fetchedTempData.response.precipitationValues,
        humidity: fetchedTempData.response.humidityValues,
        time: fetchedTempData.response.startTime,
      });
    } else {
      // debugger
      const tempdata = {
        startTime: [],
        temperatureValues: [],
        precipitationValues: [],
        humidityValues: [],
      };

      // setLoading(true);
      setError(null);

      try {
        const endDate =
          (new Date(harvest_dt) > presentDate) ? present_date : harvest_dt;
          // console.log("(new Date(harvest_dt) > presentDate) : ", (new Date(harvest_dt) > presentDate))
          // console.log("endDate : ", endDate)
        // debugger;
        const fetchPastData = getPastData(
          latitude,
          longitude,
          sowing_dt,
          endDate
        );
        // debugger
        const fetchForecastData =
          (presentDate < new Date(harvest_dt))
            ? getForecastData(latitude, longitude)
            : Promise.resolve(null);
            // console.log("(presentDate < new Date(harvest_dt)) : ", (presentDate < new Date(harvest_dt)))
          // console.log("fetchForecastData : ", fetchForecastData)
        // debugger;
        const [pData, fData] = await Promise.all([
          fetchPastData,
          fetchForecastData,
        ]);
        // debugger;

        if (pData?.allData) {
          pData.allData.forEach((interval) => {
            if (interval?.date) tempdata.startTime.push(interval.date);
            if (interval?.day) {
              tempdata.temperatureValues.push(interval.day.avgtemp_c ?? null);
              tempdata.precipitationValues.push(
                interval.day.totalprecip_mm ?? null
              );
              tempdata.humidityValues.push(interval.day.avghumidity ?? null);
            }
          });
        }

        if (fData?.forecast?.forecastday) {
          fData.forecast.forecastday.forEach((interval) => {
            if (interval?.date) tempdata.startTime.push(interval.date);
            if (interval?.day) {
              tempdata.temperatureValues.push(interval.day.avgtemp_c ?? null);
              tempdata.precipitationValues.push(
                interval.day.totalprecip_mm ?? null
              );
              tempdata.humidityValues.push(interval.day.avghumidity ?? null);
            }
          });
        }
        // console.log("tempdata : ", tempdata);
        // debugger;
        setWeatherData({
          temperature: tempdata.temperatureValues,
          precipitation: tempdata.precipitationValues,
          humidity: tempdata.humidityValues,
          time: tempdata.startTime,
        });
        // console.log("pData.location :", pData.location)
        // debugger
        saveWeather(tempdata, pData.locationData, latitude, longitude);
      } catch (error) {
        // debugger
        console.warn(error);
        setError("Failed to fetch weather data. Please try again.");
      } finally {
        // setLoading(false);
      }
    }
  };

  const fetchWeather = async (latitude, longitude, sowing_dt, harvest_dt) => {
    // // // debugger;
    const response = await axios.post(
      `${AWS_Lambda_URL}fetchWeather`,
      {
        latitude: latitude,
        longitude: longitude,
        sowing_dt: sowing_dt,
        harvest_dt: harvest_dt,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return JSON.parse(response.data.body);
  };

  const saveWeather = async (tempdata, location, latitude, longitude) => {
    // // debugger;
    const response = await axios.post(
      `${AWS_Lambda_URL}saveWeather`,
      {
        tempdata: tempdata,
        location: location,
        latitude: latitude,
        longitude: longitude,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // // debugger;
    //  console.log("Weather Saved Successfully.");
  };

  const chartData = {
    labels: weatherData.time,
    datasets: [
      {
        label: "Temperature",
        data: weatherData.temperature,
        borderColor: "#FF6600",
        pointRadius: 1,
        pointHoverRadius: 3,
        borderWidth: 3,
        barPercentage: 1.6,
        yAxisID: "y",
      },
      {
        label: "Precipitation",
        data: weatherData.precipitation,
        borderColor: "#0D6E9A",
        pointRadius: 1,
        pointHoverRadius: 3,
        borderWidth: 3,
        barPercentage: 1.6,
        yAxisID: "y",
      },
      {
        label: "Humidity",
        data: weatherData.humidity,
        borderColor: "#336600",
        pointRadius: 1,
        pointHoverRadius: 3,
        borderWidth: 3,
        barPercentage: 1.6,
        yAxisID: "y",
      },
    ],
  };

  const filteredData = {
    // labels: weatherData.time.map((date) => new Date(date).toISOString().split('T')[0]),
    labels: weatherData.time,
    datasets: chartData.datasets.filter(
      (dataset) => dataset.label === selectedDataset
    ),
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        mode: "nearest",
        callbacks: {
          title: function (tooltipItems) {
            const dataIndex = tooltipItems[0].dataIndex;
            return weatherData.time[dataIndex];
          },
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            return `Value: ${value}`;
          },
        },
      },
      legend: {
        display: false,
        labels: {
          font: {
            weight: "bold", // Make the dataset labels bold
            size: 16, // Make the dataset labels larger and more visible
          },
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: selectedDataset,
          font: {
            weight: "bold",
            size: 16,
          },
        },
        ticks: {
          font: {
            weight: "bold", // Make the title bold
            size: 14, // Set the font size to make it more visible
          },
        },
      },
      x: {
        title: {
          display: true,
          text: "Date",
          font: {
            weight: "bold",
            size: 16,
          },
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          // callback: function (value, index) {
          //   if (index % 2 === 0) {
          //     return weatherData.time[index];
          //   }
          //   return '';
          // },
          font: {
            weight: "bold", // Make the title bold
            size: 14, // Set the font size to make it more visible
          },
        },
      },
    },
  };

  return (
    <Box >
      {error && <p style={{ color: "red" }}>{error}</p>}
      <Box
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          justifyItems: "center",
          marginBottom: "5px",
          marginTop: "0px",
        }}
      >
        <WeatherButton
          variant="outlined"
          onClick={() => handleDatasetClick("Temperature")}
          selected={selectedDataset === "Temperature"}
        >
          {t("Temperature")}
        </WeatherButton>
        <WeatherButton
          variant="outlined"
          onClick={() => handleDatasetClick("Precipitation")}
          selected={selectedDataset === "Precipitation"}
        >
          {t("Precipitation")}
        </WeatherButton>
        <WeatherButton
          variant="outlined"
          onClick={() => handleDatasetClick("Humidity")}
          selected={selectedDataset === "Humidity"}
        >
          {t("Humidity")}
        </WeatherButton>
      </Box>
      <Line style={{ minHeight: "200px" }}
            height={100} data={filteredData} options={chartOptions}/>
    </Box>
  );
};

export default WeatherChart;
