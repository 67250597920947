import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Tooltip,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Label,
} from "recharts";
import {
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useStyles, {
  MyFarmBox,
  TableHeader,
  TitleBox,
  TitleName,
} from "./Style";
import Map from "./CropMapHover";
import { ReactComponent as CropSummaryLogo } from "../assets/img/CropSummary.svg";
import { FarmContext } from "../contextAPI/FarmContext";
import { useAuth } from "../login/authService/AuthContextProvider";
import "../i18n";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SwapVertIcon from '@mui/icons-material/SwapVert';

const CropSummary = () => {
  const [totalYield, setTotalYield] = useState(0);
  const classes = useStyles();
  const { t } = useTranslation();
  const [stressLevelData, setStressLevelData] = useState([]);
  const [stressHistogram, setStressHistogram] = useState(Array(10).fill(0));
  const [farmsData, setFarmsData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "farm_name",
    direction: "ascending",
  });

  const { bulkUploadData } = useAuth();
  const { optedCrop } = useContext(FarmContext);
  const { crop_monitor_data: data = [] } = bulkUploadData || {};

  const filteredDataMemoized = useMemo(() => {
    if (optedCrop) {
      return data.filter((farm) => farm.crop === optedCrop);
    }
    return data;
  }, [optedCrop, data]);

  const handleFarmsList = (range) => {
    // console.log("Handle Farms List : ", range);

    const farmsInRange = [];

    const [lowerBound, upperBound] = range.split("-").map(Number);

    // Loop through filteredDataMemoized and add farms with stress_level within the range
    filteredDataMemoized.forEach((farm) => {
      if (
        !isNaN(farm.stress_level) &&
        farm.stress_level >= lowerBound &&
        farm.stress_level < upperBound + 1
      ) {
        // Add the farm to the list
        farmsInRange.push({
          farm_name: farm.farm_name,
          stress_level: farm.stress_level,
        });
      }
      setFarmsData(farmsInRange);
      sortData("farm_name")
    });
  };

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedArray = [...farmsData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setSortedData(sortedArray);
    setSortConfig({ key, direction });
  };

  const stressLevelCounts = () => {
    const histogram = Array(10).fill(0); // Initialize histogram for 10 ranges (0-10, 11-20, ..., 91-100)

    filteredDataMemoized.forEach((farm) => {
      if (!isNaN(farm?.stress_level)) {
        const stressLevel = Number(farm.stress_level);
        const rangeIndex = Math.floor(stressLevel / 10); // Get the index for the range (0-9)
        if (rangeIndex >= 0 && rangeIndex < 10) {
          histogram[rangeIndex] += 1; // Increment the count for the corresponding range
        }
      }
    });
    setStressHistogram(histogram);
  };

  useEffect(() => {
    const formattedData = stressHistogram.map((count, index) => ({
      range: `${index * 10}-${(index + 1) * 10 - 1}`,
      count,
    }));
    setStressLevelData(formattedData);
  }, [stressHistogram]);

  const UICard2 = ({ title, farms, yieldTotal }) => {
    return (
      <StyledWrapper>
        <div className="card">
          <div className="card-inner">
            <div className="card-front">
              <Typography
                variant="h5"
                color="#fcaf03"
                // sx={{ fontWeight: "bold"}}>{`Crop: ${title}`}</Typography>
                sx={{ fontWeight: "bold" }}
              >
                {title}
              </Typography>
            </div>
            <div className="card-back">
              <Stack direction={"column"}>
                <Typography variant="h7">{`${t("Total Farms")}: `}</Typography>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                >{`${farms} ${t("farms")}`}</Typography>{" "}
                <br />
                <Typography variant="h7">{`${t("Total Yield")}: `}</Typography>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                >{`${yieldTotal.toFixed(2)} ${t("Tonnes")}`}</Typography>
              </Stack>
            </div>
          </div>
        </div>
      </StyledWrapper>
    );
  };

  const StyledWrapper = styled.div`
    .card {
      width: 100%;
      height: 36vh;
      perspective: 1000px;
    }

    .card-inner {
      width: 100%;
      height: 100%;
      position: relative;
      transform-style: preserve-3d;
      transition: transform 0.999s;
    }

    .card:hover .card-inner {
      transform: rotateY(180deg);
    }

    .card-front,
    .card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
    }

    .card-front {
      background-color: #224831;
      color: #fcaf03;
      display: flex;
      align-items: center;
      border: 10px solid #224831;
      border-radius: 10px;
      justify-content: center;
      font-size: 24px;
      transform: rotateY(0deg);
    }

    .card-back {
      background-color: #224831;
      color: #fcaf03;
      display: flex;
      align-items: center;
      border: 10px solid #224831;
      border-radius: 10px;
      justify-content: center;
      font-size: 20px;
      transform: rotateY(180deg);
    }
  `;

  useEffect(() => {
    stressLevelCounts();
    if (filteredDataMemoized && filteredDataMemoized.length > 0) {
      // debugger;
      const calculatedTotalYield = filteredDataMemoized.reduce(
        (sum, farm) => sum + (Number(farm.predicted_yield) || 0),
        0
      );
      // debugger;
      setTotalYield(calculatedTotalYield);
    } else {
      setTotalYield(0);
    }
  }, [filteredDataMemoized]);

  return (
    <MyFarmBox>
      <TitleBox sx={{ ml: "20px", mb: "10px" }}>
        <CropSummaryLogo className={classes.logo} />
        <TitleName variant="h3">{t("Crop Summary")}</TitleName>
      </TitleBox>

      <Grid
        container
        // md={12}
        // sm={12}
        // xs={12}
        // spacing={5}
        sx={{
          minHeight: "80vh",
          ml: "15px",
          mr: "15px",
          //   height: "100%",
          // marginTop: "15px",
          //   // display: "flex",
          //   // flexDirection: "row",
          //   // border: "2px solid red",
          //   // flexWrap: "nowrap",
          //   // height: "90vh",
        }}
      >
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          sx={{
            display: "flex",
            // padding:'0px'
            // minHeight: "80vh"
            // flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: "10px",
            paddingBottom: "0px",
          }}
        >
          <Stack
            spacing={2}
            direction={"column"}
            sx={{ width: "100%", maxHeight: "40vh", margin: "0px 0px 0px 0px" }}
          >
            <Card
              sx={{
                backgroundColor: "#224831",
                minHeight: "40vh",
                maxHeight: "40vh",
                minWidth: "100%",
                padding: "15px",
                borderRadius: "10px",
              }}
            >
              <Typography
                variant="h5"
                color="#fcaf03"
                sx={{ fontWeight: "bold" }}
              >
                {t("Crop Health")}
              </Typography>
              <ResponsiveContainer
                width="100%"
                height={"90%"}
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "10px",
                }}
              >
                <BarChart
                  data={stressLevelData}
                  margin={{ top: 20, right: 30, left: 0, bottom: 20 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="range">
                    <Label
                      value={t("Stress Level Range")}
                      position="bottom"
                      offset={0}
                      style={{
                        fontSize: 16,
                        fill: "#224831",
                        fontWeight: "bold",
                      }}
                    />
                  </XAxis>
                  <YAxis>
                    <Label
                      value={t("Total Farms")}
                      angle={270}
                      position="center"
                      dx={-10}
                      style={{
                        fontSize: 16,
                        fill: "#224831",
                        fontWeight: "bold",
                      }}
                    />
                  </YAxis>
                  <Tooltip onClick={(e) => handleFarmsList(e.range)}/>
                  <Bar
                    dataKey="count"
                    fill="#224831"
                    onClick={(e) => handleFarmsList(e.range)}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Card>

            <Stack
              spacing={2}
              direction={"row"}
              sx={{
                width: "100%",
                maxHeight: "36vh",
                margin: "0px 0px 0px 0px",
              }}
            >
              <Card
                sx={{
                  maxHeight: "36vh",
                  minHeight: "36vh",
                  minWidth: "70%",
                  backgroundColor: "#224831",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h5"
                  color="#fcaf03"
                  sx={{ fontWeight: "bold", ml: "10px", mt: "10px" }}
                >
                  {t("Stress Level Table")}
                </Typography>
                <TableContainer
                  sx={{
                    maxHeight: "31vh",
                    overflow: "auto",
                    backgroundColor: "#ffffff",
                    minHeight: "31vh",
                    // overflowX: "hidden",
                    "&::-webkit-scrollbar": { width: "8px" },
                    "&::-webkit-scrollbar-thumb": {
                      background: "rgba(252, 175, 3, 0.5)",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "rgba(252, 175, 3, 0.8)",
                    },
                  }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableHeader sx={{ boxShadow: 5 }}>{t("Sr No")}</TableHeader>
                        <TableHeader sx={{ boxShadow: 5 }} >
                          {t("Farm Name")}
                        </TableHeader>
                        <TableHeader sx={{ boxShadow: 5 }} >
                          {t("Stress Level")}
                        </TableHeader>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {farmsData ? (
                      farmsData.map((farm, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                            {index + 1}
                          </TableCell>
                          <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                            {farm.farm_name}
                          </TableCell>
                          <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                            {farm.stress_level.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      )) ) : ""}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>

              <Card
                sx={{
                  // backgroundColor: "#224831",
                  minHeight: "36vh",
                  maxHeight: "36vh",
                  minWidth: "28%",
                  borderRadius: "10px",
                }}
              >
                <UICard2
                  title={t(optedCrop)}
                  farms={filteredDataMemoized.length}
                  yieldTotal={totalYield}
                />
              </Card>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          sx={{
            display: "flex",
            // padding:'0px'
            maxHeight: "80vh",
            mb: "10px",
            // mr:"10px"
          }}
        >
          <Map FarmerData={filteredDataMemoized} />
        </Grid>
      </Grid>
    </MyFarmBox>
  );
};

export default CropSummary;
