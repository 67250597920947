import React from "react";
import Lottie from "react-lottie";
import animationData from "../assets/lotties/loading.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const LoadingAnimation = () => {
  return (
    <div style={{ marginTop: "150px", textAlign: "center" }}>
      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  );
};

export default LoadingAnimation;
