import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const Map = React.memo(
  ({ farmInfoUrl, geojsonData, currentLayer, onLayerChange }) => {
    const mapContainerRef = useRef();
    const mapRef = useRef();
    const popupRef = useRef(
      new mapboxgl.Popup({ closeButton: false, closeOnClick: false })
    );
    const [centerCoordinates, setCenterCoordinates] = useState([0, 0]);

    const loadGeoJsonData = async () => {
      if (farmInfoUrl) {
        try {
          const response = await fetch(farmInfoUrl);
          const data = await response.json();

          if (data.features && data.features.length > 0) {
            const firstCoordinates =
              data.features[0].geometry.coordinates[0][0];
            setCenterCoordinates(firstCoordinates);
          }
        } catch (error) {
          console.error("Error loading GeoJSON:", error);
        }
      }
    };

    useEffect(() => {
      loadGeoJsonData();
    }, [farmInfoUrl]);

    useEffect(() => {
      // Initialize the map only once
      if (
        !mapRef.current &&
        centerCoordinates[0] !== 0 &&
        centerCoordinates[1] !== 0
      ) {
        mapRef.current = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: "mapbox://styles/mapbox/satellite-v9",
          center: centerCoordinates, // Use initial center coordinates
          zoom: 13, // Set an initial zoom level
          attributionControl: false,
        });

        mapRef.current.on("load", () => {
          // Add the geojson layer
          if (farmInfoUrl) {
            fetch(farmInfoUrl)
              .then((response) => response.json())
              .then((data) => {
                mapRef.current.addSource("farm-data", {
                  type: "geojson",
                  data: data,
                });

                mapRef.current.addLayer({
                  id: "farm-fill",
                  type: "fill",
                  source: "farm-data",
                  layout: {},
                  paint: {
                    "fill-color": [
                      "interpolate",
                      ["linear"],
                      ["get", "value"],
                      0.0,
                      "#FF0000", // Red for minimum value
                      0.1,
                      "#FF1100",
                      0.2,
                      "#FF2200",
                      0.3,
                      "#FF3300",
                      0.4,
                      "#FF4500",
                      0.5,
                      "#FF6600",
                      0.6,
                      "#FF9900",
                      0.7,
                      "#FFCC00",
                      0.8,
                      "#006600", // Darker green as values increase
                      0.9,
                      "#009900",
                      1.0,
                      "#00CC00",
                      1.1,
                      "#00FF00", // Green
                      1.2,
                      "#33FF33",
                      1.3,
                      "#66FF66",
                      1.4,
                      "#80FF80",
                      1.5,
                      "#99FF99",
                      1.6,
                      "#B3FFB3",
                      1.7,
                      "#CCFFCC",
                      1.8,
                      "#E6FFE6",
                      1.9,
                      "#F2FFF2",
                      2.0,
                      "#FFFFFF", // White for maximum value
                    ],
                    "fill-opacity": 0.5,
                  },
                });

                mapRef.current.on("mousemove", "farm-fill", (e) => {
                  const features = mapRef.current.queryRenderedFeatures(
                    e.point,
                    {
                      layers: ["farm-fill"],
                    }
                  );

                  if (features.length > 0) {
                    const coordinates = e.lngLat;

                    // Extract values and timestamps
                    const details = features.map((feature) => {
                      const value = feature.properties.value;
                      const timestamp = feature.properties.timestamp;
                      return `<strong>Value:</strong> ${value}<br><strong>Timestamp:</strong> ${timestamp}`;
                    });

                    // Set popup content with values and timestamps
                    popupRef.current
                      .setLngLat(coordinates)
                      .setHTML(details.join("<br><br>")) // Join multiple entries
                      .addTo(mapRef.current);
                  }
                });

                mapRef.current.on("mouseleave", "farm-fill", () => {
                  popupRef.current.remove();
                });
              })
              .catch((error) => console.error("Error loading GeoJSON:", error));
          }

          // Once the map is loaded, perform the zoom flyTo animation
          mapRef.current.flyTo({
            center: centerCoordinates, // Center to your coordinates
            zoom: 17, // Set the zoom level for the flyTo animation
            speed: 1.2, // Adjust the speed (1.5 for smoother, slower animation)
            curve: 1.4, // Adjust the curve (higher = more dramatic zooming effect)
            easing: (t) => t, // Linear easing for smoother animation
          });
        });
      }

      return () => {
        if (mapRef.current) {
          mapRef.current.off("mousemove", "farm-fill");
          mapRef.current.off("mouseleave", "farm-fill");
          mapRef.current.remove();
          mapRef.current = null;
        }
      };
    }, [centerCoordinates, farmInfoUrl]);

    return (
      <div style={{ position: "relative" }}>
        <div
          id="map"
          ref={mapContainerRef}
          style={{ height: "500px", width: "100%" }}
        />
        <div
          className="layer-buttons"
          style={{ position: "absolute", top: 10, left: 10 }}
        >
          {Object.keys(geojsonData).map(
            (key) =>
              !["MSAVI", "SIPI", "NDRE", "GCI", "plant_height"].includes(
                key
              ) && ( // Exclude "plant_height" here
                <button
                  key={key}
                  onClick={() => onLayerChange(key)}
                  style={{
                    margin: "5px",
                    padding: "10px",
                    backgroundColor:
                      currentLayer === key ? "#fcaf03" : "#f8f9fa",
                    color: currentLayer === key ? "#224831" : "black",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  {key === "NDVI"
                    ? "Vegetation"
                    : key === "NDMI"
                    ? "Moisture"
                    : key === "PSRI"
                    ? "Stress"
                    : key}
                </button>
              )
          )}
        </div>
      </div>
    );
  }
);

export default Map;
