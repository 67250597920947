// Allroutes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import Signin from "./Signin";
import Signup from "./Signup";
import Map from "./Map";
import Dashboard from "./Dashboard";
import Banker from "./Banker";
import CropYeildPren from "./CropYeildPren";
import PrivateRoute from "../components/PrivateRoute";
import UnauthorizedPage from "./UnauthorizedPage";
import NotFoundPage from "./NotFoundPage";
import Report from "./Report";
import { AuthProvider } from "../context/AuthContextProvider";
import Layout from "../components/Layout"; // Import the Layout with Drawer
import Summary from "./Summary";
import NewUser from "./NewUser";

const Allroutes = () => {
  return (
    <AuthProvider>
      <Routes>
        {/* No Layout for Signin, Signup, Unauthorized */}
        <Route path="/" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/addFirstFarm" element={<NewUser />} />
        
        {/* Routes with Layout (contains Drawer) */}
        <Route element={<Layout />}>
          <Route element={<PrivateRoute allowedRoles={["farmer"]} />}>
            <Route path="/map" element={<Map />} />
          </Route>

          <Route element={<PrivateRoute allowedRoles={["farmer"]} />}>
            <Route path="/summary" element={<Summary/>} />
          </Route>
          <Route element={<PrivateRoute allowedRoles={["banker"]} />}>
            <Route path="/banker" element={<Banker />} />
          </Route>

          <Route element={<PrivateRoute allowedRoles={["banker","farmer"]} />}>
            <Route path="/report" element={<Report />} />
          </Route>

          <Route path="/dashboard/:mobile/:farm_name" element={<Dashboard />} />
          <Route
            path="/cropdetails/:State/:District/:Taluka/:Village/:Survey_No/:Farm_No/:Farm_Area/:from_date/:to_date"
            element={<CropYeildPren />}
          />
        </Route>

        {/* Fallback route */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AuthProvider>
  );
};

export default Allroutes;
