import React, { useContext, useEffect, useState } from "react";
import { FarmContext } from "../contextAPI/FarmContext";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Stack,
} from "@mui/material";
import '../i18n'
import { useTranslation } from 'react-i18next';
// import InfoCard from "./InfoCard";

const MandiCard = () => {
  const { fetchMandiData, mandiData, geojsonData } = useContext(FarmContext);
  const {t} = useTranslation();
   // debugger
    useEffect(()=>{
      fetchMandiData()
    },[])

  const [index, setIndex] = useState(0);
  const [activeButton, setActiveButton] = useState(0);
  const handlepricecard = (newIndex) => {
    // debugger;
    // console.log("index", index);
    setIndex(newIndex);
    // console.log("new index after click", index);
  };
  // console.log("mandi data:", mandiData);

  function formatNumberInIndianStyle(number) {
    return new Intl.NumberFormat('en-IN').format(number);
  }

  return mandiData &&
    mandiData?.nearestMandiList &&
    mandiData?.nearestMandiList.length > 0 ? (
    <Card sx={{ backgroundColor: "#ffffff", borderRadius: "10px" }}>
      <CardContent sx={{ paddingTop: "0px" }}>
        <Typography
          variant="h5"
          color="#224831"
          style={{ fontFamily: "open sans" }}
        >
          {t("Nearest 3 markets to the farm")}
        </Typography>

        <Grid container spacing={4}>
          <Grid item md={5}>
            {/* {mandiData.nearestMandiList &&
            mandiData.nearestMandiList.length > 0 ? ( */}
              {/* <> */}
                <Box sx={{ minHeight: "100%" }}>
                  <Stack
                    direction="column"
                    sx={{ justifyContent: "space-between" }}
                  >
                    {mandiData.nearestMandiList.map((mandi, index) => (
                      <Button
                        variant="outlined"
                        key={index}
                        sx={{
                          color: activeButton === index ? "#224831" : "#fcaf03",
                          backgroundColor:
                            activeButton === index ? "#fcaf03" : "#224831",
                          border:
                            activeButton === index
                              ? "3px solid #224831"
                              : "3px solid #fcaf03",
                          minHeight: "50px",
                          borderRadius: "50px",
                          marginBottom: "2%",
                        }}
                        onClick={() => {
                          handlepricecard(index);
                          setActiveButton(index);
                        }}
                      >
                        {`${index + 1}. ${mandi?.Market}, ${mandi?.District}, ${
                          mandi?.State
                        }`}
                      </Button>
                    ))}
                    <Typography variant="subtitle">
                      *{t("Select mandi to know details")}
                    </Typography>
                  </Stack>
                </Box>
              {/* </> */}
            {/* ) : (
              <Typography variant="h6" color="#ffffff">
                mandi data not found
              </Typography>
            )} */}
          </Grid>
          <Grid item md={7}>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                minHeight: "50%",
              }}
            >
              {/* <InfoCard
                    title="Minimum Price"
                    content={`${Number(mandiData?.nearestMandiList[index].MinPrice / 100).toFixed(2)} Rs/Kg`}
                  /> */}
              <Card
                style={{
                  // margin: "auto",
                  // width: "calc(50% - 10px)",
                  // minWidth: "250px",
                  // height: "100px",
                  width: "30%",
                  backgroundColor: "#224831",
                  backdropFilter: "blur(10px)",
                  height: "100px",
                  borderRadius: "10px",
                  fontFamily: "open sans",
                }}
              >
                <CardContent style={{ textAlign: "left" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#fcaf03",
                    }}
                    variant="h6"
                  >
                    {t("Minimum Price")}
                  </Typography>

                  <Typography
                    style={{
                      fontsize: "150px",
                      color: "#ffffff",
                      textAlign: "left",
                    }}
                    variant="h5"
                  >
                    {`${Number(
                      mandiData?.nearestMandiList[index].MinPrice / 100
                    ).toFixed(2)} ₹/${t("kg")}`}
                  </Typography>
                </CardContent>
              </Card>
              <Card
                style={{
                  // margin: "auto",
                  // width: "calc(50% - 10px)",
                  // minWidth: "250px",
                  // height: "100px",
                  // minWidth:'200px',
                  width: "30%",
                  backgroundColor: "#224831",
                  backdropFilter: "blur(10px)",
                  height: "100px",
                  borderRadius: "10px",
                  fontFamily: "open sans",
                }}
              >
                <CardContent style={{ textAlign: "left" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#fcaf03",
                    }}
                    variant="h6"
                  >
                    {t("Average Price")}
                  </Typography>

                  <Typography
                    style={{
                      fontsize: "150px",
                      color: "#ffffff",
                      textAlign: "left",
                    }}
                    variant="h5"
                  >
                    {`${Number(
                      mandiData?.nearestMandiList[index].AvgPrice / 100
                    ).toFixed(2)} ₹/${t("kg")}`}
                  </Typography>
                </CardContent>
              </Card>
              <Card
                style={{
                  // margin: "auto",
                  // width: "calc(50% - 10px)",
                  // minWidth: "250px",
                  // height: "100px",
                  width: "30%",
                  backgroundColor: "#224831",
                  backdropFilter: "blur(10px)",
                  height: "100px",
                  borderRadius: "10px",
                  fontFamily: "open sans",
                }}
              >
                <CardContent style={{ textAlign: "left" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#fcaf03",
                    }}
                    variant="h6"
                  >
                    {t("Maximum Price")}
                  </Typography>

                  <Typography
                    style={{
                      fontsize: "150px",
                      color: "#ffffff",
                      textAlign: "left",
                    }}
                    variant="h5"
                  >
                    {`${Number(
                      mandiData?.nearestMandiList[index].MaxPrice / 100
                    ).toFixed(2)} ₹/${t("kg")}`}
                  </Typography>
                </CardContent>
              </Card>
              {/* <InfoCard
                    title="Average Price"
                    content={`${Number(mandiData?.nearestMandiList[index].AvgPrice / 100).toFixed(2)} Rs/Kg`}
                  />
                  <InfoCard
                    title="Maximum Price"
                    content={`${Number(mandiData?.nearestMandiList[index].MaxPrice / 100).toFixed(2)} Rs/Kg`}
                  /> */}
            </Stack>
            <Stack
              direction="row"
              sx={{ justifyContent: "flex-start", minHeight: "50% " }}
            >
              {/* <Summarycards
              titleone='Expected Income'
              contentone={`Rs ${(Number(mandiData?.nearestMandiList[index].MinPrice / 100)*(lstmData.total_yield * 1000)).toFixed(2)} to 
                  ${(Number(mandiData?.nearestMandiList[index].MaxPrice / 100)*(lstmData.total_yield * 1000)).toFixed(2)}
                `}
              /> */}
              {/* <InfoCard
                title="Expected Income"
                content={`Rs ${(
                  Number(mandiData?.nearestMandiList[index].MinPrice / 100) *
                  (lstmData.total_yield * 1000)
                ).toFixed(2)} to 
                ${(
                  Number(mandiData?.nearestMandiList[index].MaxPrice / 100) *
                  (lstmData.total_yield * 1000)
                ).toFixed(2)}
              `}
              /> */}

              <Card
                style={{
                  marginTop: "1%",

                  // width: "calc(50% - 10px)",
                  // minWidth: "250px",
                  // height: "100px",
                  // maxWidth:'400px',
                  backgroundColor: "#224831",
                  backdropFilter: "blur(10px)",
                  height: "100px",
                  borderRadius: "10px",
                  width: "100%",
                  fontFamily: "open sans",
                }}
              >
                <CardContent style={{ textAlign: "left" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#fcaf03",
                    }}
                  >
                    {t("Expected Revenue")}
                  </Typography>

                  <Typography
                    style={{
                      fontsize: "15px",
                      color: "#ffffff",
                      textAlign: "left",
                    }}
                    variant="h5"
                  >
                    {geojsonData?.predicted_yield ?
                    `₹ ${formatNumberInIndianStyle(Number((
                      mandiData?.nearestMandiList[index].MinPrice / 100
                    ) *
                    (geojsonData.predicted_yield * 1000)).toFixed(0))} /-
                   to 
                ₹ ${formatNumberInIndianStyle(
                  Number((mandiData?.nearestMandiList[index].MaxPrice / 100) *
                  (geojsonData.predicted_yield * 1000)
                ).toFixed(0))} /-` : t("Too soon to predict since no yield predicted yet")}
                  </Typography>
                </CardContent>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ) : (
    <Typography variant="subtitle">
      {t("No Mandi found near your farm in our Database")}
    </Typography>
  );
};

export default MandiCard;
