import React, { useState } from "react";
import * as XLSX from "xlsx";
import { Card, Typography, Grid, Box, Paper, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../i18n";
import { useTranslation } from "react-i18next";

const REQUIRED_HEADERS = ["Mobile", "Farm name", "Sowing Date", "Coordinates"];

const AWS_URL = process.env.REACT_APP_AWS_LAMBDA_URL;
const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const navigate = useNavigate();
  const {t} = useTranslation();

  const handleNavgateTobulkfile = () => {
    // alert("We're working on it, Please try later!");
    navigate("/userbulkuploaddata");
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile?.name || "");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert("Please select a file.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    try {
      const jsonData = await convertFileToJson(formData);
      const isValid = validateHeaders(jsonData);
      if (!isValid) {
        alert("Invalid file headers. Please upload a valid file.");
        return;
      }
      await uploadJsonToMongo(jsonData);
      alert("File uploaded successfully!");
      // navigate("/userbulkuploaddata");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file. Please try again.");
    }
  };
  const convertFileToJson = async (formData) => {
    const file = formData.get("file");
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const jsonData = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]]
        );
        // Format the data
        const formattedData = jsonData.map((row) => {
          const formattedRow = { ...row };
          // Convert "Sowing Date" to yyyy-mm-dd format
          if (formattedRow["Sowing Date"]) {
            const sowingDate = XLSX.SSF.parse_date_code(
              formattedRow["Sowing Date"]
            );
            formattedRow["Sowing Date"] = sowingDate
              ? `${sowingDate.y}-${String(sowingDate.m).padStart(
                  2,
                  "0"
                )}-${String(sowingDate.d).padStart(2, "0")}`
              : formattedRow["Sowing Date"];
          }
          // Ensure "Mobile" is a string
          if (formattedRow["Mobile"]) {
            formattedRow["Mobile"] = String(formattedRow["Mobile"]);
          }
          return formattedRow;
        });
        resolve(formattedData);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsBinaryString(file);
    });
  };
  const validateHeaders = (jsonData) => {
    const headers = Object.keys(jsonData[0] || {});
    return REQUIRED_HEADERS.every((header) => headers.includes(header));
  };
  const uploadJsonToMongo = async (jsonData) => {
    try {
      const response = await fetch(
        `${AWS_URL}Excelupload`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(jsonData),
        }
      );
      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          `Failed to upload JSON data to MongoDB: ${errorResponse.message}`
        );
      }
      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error uploading JSON to MongoDB:", error);
      throw new Error("Error uploading JSON to MongoDB: " + error.message);
    }
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "center", minWidth: "100%", }}>
      <Card
        sx={{
          minWidth: "100%",
          justifyContent: "center",
          display: "flex",
          backgroundColor: "#224831",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" color="#FCAF03">
          {t("Upload Bulk Excel/CSV File")}
        </Typography>
        <Paper
          elevation={1}
          sx={{
            padding: 2,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <input
                  type="file"
                  accept=".csv,.xlsx,.xls"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="file-input"
                />
                <label htmlFor="file-input">
                  <Button
                    variant="contained"
                    component="span"
                    style={{
                      // padding: "8px 16px",
                      // margin: "0 8px",
                      width:"100%",
                      backgroundColor: "white",
                      color: "#224831",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Select File")}
                  </Button>
                </label>
                {fileName && <Typography>{fileName}</Typography>}
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  // {fileName && disabled}
                  style={{
                    // padding: "8px 16px",
                    // margin: "0 8px",
                    width:"100%",
                    backgroundColor: "#FCAF03",
                    color: "#224831",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    // minHeight: "65px"
                  }}
                >
                  {t("Upload")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  style={{
                    // padding: "8px 16px",
                    // margin: "0 8px",
                    // backgroundColor: "#FCAF03",
                    color: "#fcaf03",
                    borderColor: "#fcaf03",
                    width:"100%"
                    // borderRadius: "4px",
                    // minHeight: "65px"
                  }}
                  // disabled
                  onClick={handleNavgateTobulkfile}
                >
                  {t("Bulk Data")}
                </Button>
              </Grid>
              {/* <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ color: "#fcaf03", border: "1px solid #fcaf03" }}
                  disabled
                  onClick={handleNavgateTobulkfile}
                >
                  your bulk data
                </Button>
              </Grid> */}
            </Grid>
          </form>
        </Paper>
      </Card>
    </Box>
  );
};
export default FileUpload;

// import React, { useState } from "react";
// import * as XLSX from "xlsx";
// import {
//   Card,
//   CardContent,
//   Typography,
//   Grid,
//   Box,
//   Paper,
//   Button,
// } from "@mui/material";

// const FileUpload = () => {
//   const [file, setFile] = useState(null);

//   const handleFileChange = (e) => {
//     const selectedFile = e.target.files[0];
//     setFile(selectedFile);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!file) {
//       alert("Please select a file.");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       // Replace with actual implementation for file conversion
//       const jsonData = await convertFileToJson(formData);

//       // Replace with actual implementation for MongoDB upload
//       await uploadJsonToMongo(jsonData);

//       alert("File uploaded successfully!");
//       navigate("/userbulkuploaddata");
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       alert("Failed to upload file. Please try again.");
//     }
//   };

//   // Function to convert file to JSON (using 'xlsx' library for Excel)
//   const convertFileToJson = async (formData) => {
//     const file = formData.get("file");
//     const reader = new FileReader();

//     return new Promise((resolve, reject) => {
//       reader.onload = (event) => {
//         const data = event.target.result;
//         // Example using 'xlsx' library to parse Excel file to JSON
//         const workbook = XLSX.read(data, { type: "binary" });
//         const jsonData = XLSX.utils.sheet_to_json(
//           workbook.Sheets[workbook.SheetNames[0]]
//         );
//         resolve(jsonData);
//       };

//       reader.onerror = (error) => reject(error);

//       reader.readAsBinaryString(file);
//     });
//   };

//   // Function to upload JSON data to MongoDB
//   const uploadJsonToMongo = async (jsonData) => {
//     try {
//       const response = await fetch(
//         `${AWS_URL}Excelupload`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(jsonData), // Ensure jsonData is an array of objects
//         }
//       );

//       if (!response.ok) {
//         const errorResponse = await response.json();
//         throw new Error(
//           `Failed to upload JSON data to MongoDB: ${errorResponse.message}`
//         );
//       }

//       const result = await response.json();
//     } catch (error) {
//       console.error("Error uploading JSON to MongoDB:", error);
//       throw new Error("Error uploading JSON to MongoDB: " + error.message);
//     }
//   };

//   return (
//     <Box sx={{ display: "flex", justifyContent: "center", minWidth:'100%'}}>
//       <Card sx={{minWidth:'100%' , justifyContent:'center', display:'flex', backgroundColor:'#224831'}}>
//         <CardContent  sx={{textAlign:'center'}}>
//           <Typography variant="h6" color="#fcaf03" >
//             Upload bulk excel/csv File
//           </Typography>
//           <Paper
//             elevation={3}
//             sx={{ padding: 2, backgroundColor: "rgba(0, 0, 0, 0.1)" }}
//           >
//             <form onSubmit={handleSubmit}>
//               <Grid container spacing={2} >
//                 <Grid item xs={12}>
//                   <input
//                     type="file"
//                     accept=".csv,.xlsx,.xls"
//                     onChange={handleFileChange}
//                     style={{ display: "none" }}
//                     id="file-input"
//                   />
//                   <label htmlFor="file-input">
//                     <Button
//                       variant="contained"
//                       component="span"
//                       style={{
//                         padding: "8px 16px",
//                         margin: "0 8px",
//                         backgroundColor: "white",
//                         color: "#224831",
//                         border: "none",
//                         borderRadius: "4px",
//                         cursor: "pointer",
//                       }}
//                     >
//                       Select File
//                     </Button>
//                   </label>
//                   <Button
//                     type="submit"
//                     variant="contained"
//                     style={{
//                       padding: "8px 16px",
//                       margin: "0 8px",
//                       backgroundColor: "#fcaf03",
//                       color: "#224831",
//                       border: "none",
//                       borderRadius: "4px",
//                       cursor: "pointer",
//                     }}
//                   >
//                     Upload
//                   </Button>
//                 </Grid>
//                 {/* <Grid item xs={12}>

//                 </Grid> */}
//               </Grid>
//             </form>
//           </Paper>
//         </CardContent>
//       </Card>
//     </Box>
//   );
// };

// export default FileUpload;
