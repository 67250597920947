import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthService from "../service/auth-service";
import { useAuth } from "../context/AuthContextProvider";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import elailogo from "../assets/logo/ELAI LOGOS.png";
import Typography from "@mui/material/Typography";
import Elailogo from "../Assests/Elailogo.png"
import newDrishti from "../Assests/Drishti.png"


function Login() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [alignment, setAlignment] = React.useState("/");

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email is invalid")
      .required("Email field is required"),
    password: Yup.string().required("Password field is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);

      AuthService.login(values.email, values.password)
        .then((response) => {
          setSubmitting(false);
          if (response.success) {
            login(response);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrors({
            message: "Login failed. Please try again.",
          });
          console.error(error);
        });
    },
  });

  const theme = createTheme({
    palette: {
      background: {
        default: "#fafafb",
      },
    },
  });

  const handleChange = (event, newAlignment) => {
    navigate(event.target.value);
    setAlignment(newAlignment);
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "rgba(34, 72, 49, 0.9)",
        padding: "0px",
        margin: "0px",
        minHeight: "100vh",
        fontFamily: "open sans",
      }}
    >
      {/* <ThemeProvider theme={theme}> */}
      <Container
        component="main"
        disableGutters
        sx={{
          display: "flex",
          // backgroundColor: "rgba(34, 72, 49, 0.9)",
          marginTop: "40px",
          // paddingRight: "80px",
        }}
      >
        <CssBaseline />

        <Grid
          container
          spacing={10}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // height: "100vh",
            width: "100vw",
          }}
        >
          <Grid item xs={12} md={8}>
            <Box
              style={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "center",
                width: "100%",
                // height: "100%",
                margin: "20px",
                padding: "0px",
                flexDirection: "column",
                // background: 'linear-gradient(135deg, #224831, #3b8d44)',
                borderRadius: "15px",
                // boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)',
                // background: '#ffffff',
                marginTop: "80px",
              }}
            >
              <img
              src={Elailogo}
              style={{width:'150px', height:'250px',marginBottom:'40px'}}
              />
              <Typography
                variant="h1"
                sx={{
                  color: "#ffffff",
                  // textAlign: 'center',
                  fontSize: { xs: "1.7rem", md: "3.2rem" }, // Responsive font size
                  // maxWidth: "80%",
                  lineHeight: "1.5",
                  // fontWeight: 300,
                  fontFamily: "open sans",
                  
                  
                  // textShadow: '7px 2px 4px rgba(0, 0, 0, 0.9)',
                }}
              >
                Unlocking decision insights from space to positively impact
                agriculture
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                bgcolor: "white",
                borderRadius: 3,
                py: 3,
                px: 3,
                boxShadow: 2,
                height: "550px",
                marginTop: 6.5,
                marginBottom: "20px",
              }}
            >
              <img
                src={newDrishti}
                style={{ width: "90px", height: "90px", marginTop:'20px', marginBottom:'20px' }}
              />

              <ToggleButtonGroup
                color="success"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                sx={{ mb: 3 }}
              >
                <ToggleButton value="/">Signin</ToggleButton>
                <ToggleButton value="/signup">Signup</ToggleButton>
              </ToggleButtonGroup>

              {/* <Typography component="h1" variant="h5">
            Log in
          </Typography> */}
              <form onSubmit={formik.handleSubmit} noValidate>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  helperText={formik.errors.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  size="small"
                  color="success"
                  sx={{ mb: 3 }}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  helperText={formik.errors.password}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  size="small"
                  color="success"
                  sx={{ mb: 3 }}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 3,
                    bgcolor: "#fcaf03",
                    "&:hover": {
                      bgcolor: "#224831",
                      color: "#fcaf03",
                    },
                    color: "#224831",
                  }}
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Logging in..." : "Log In"}
                </Button>
                {formik.errors.message && (
                  <Alert severity="error">{formik.errors.message}</Alert>
                )}
                <Grid container>
                  <Grid item>
                    <Link
                      to="/signup"
                      variant="body2"
                      sx={{ color: "#336600", mb: 3 }}
                    >
                      Don't have an account? Sign Up
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* </ThemeProvider> */}
    </Box>
  );
}

export default Login;
