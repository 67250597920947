

import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import * as turf from "@turf/turf";
import {
  Button,
  FormControl,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Container,
  Alert,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import CircularProgress from "@mui/material/CircularProgress";
import FileUpload from "../components/FileUpload";

const Map = () => {
  const [crop, setCrop] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const [area, setArea] = useState(0);
  const [mobile, setMobile] = useState("");
  const [farmName, setFarmName] = useState("");
  const [sowingDate, setSowingDate] = useState("");
  const [areaInAcres, setAreaInAcres] = useState(0);
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const drawRef = useRef(null);
  const navigate = useNavigate();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleMobileChange = useCallback((event) => {
    setMobile(event.target.value);
  }, []);

  const handleFarmNameChange = useCallback((event) => {
    setFarmName(event.target.value);
  }, []);

  const handleSowingDateChange = useCallback((event) => {
    setSowingDate(event.target.value);
  }, []);

  const handleSubmit = async (event) => {
    setShowSuccessAlert(true);
    event.preventDefault();

    const farmData = {
      mobile,
      farm_name: farmName,
      date: sowingDate,
      coordinates: coordinates.map((coord) => ({
        latitude: coord[1],
        longitude: coord[0],
      })),
      area: areaInAcres,
    };

    try {
      const response = await fetch(
        "https://3mpqory1se.execute-api.ap-south-1.amazonaws.com/prod/createFarm_v2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(farmData),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.status === 200 && responseData.body === "no data") {
          console.log("no data");
        } else {
        
          setTimeout(() => {
            navigate(`/dashboard/${farmData.mobile}/${farmData.farm_name}`);
          }, 30000);
        }
      }
    } catch (error) {
      console.error("Error saving farm data:", error);
    }
  };

  const getUserLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userLocation = [longitude, latitude];

          mapRef.current.setCenter(userLocation);
          new mapboxgl.Marker().setLngLat(userLocation).addTo(mapRef.current);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const updateCoordinates = useCallback(() => {
    const drawData = drawRef.current.getAll();
    if (drawData.features.length) {
      const drawnPolygon = drawData.features[0].geometry.coordinates[0];
      setCoordinates(drawnPolygon);
      calculateArea(drawnPolygon);
    }
  }, []);

  const calculateArea = useCallback((polygon) => {
    if (polygon.length >= 3) {
      const areaSquareMeters = turf.area({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [polygon],
        },
      });

      const areaAcres = areaSquareMeters / 4046.86;
      setArea(areaSquareMeters);
      setAreaInAcres(areaAcres.toFixed(2));
    }
  }, []);

  const isFormValid = useMemo(() => {
    return (
      mobile.trim() !== "" &&
      farmName.trim() !== "" &&
      sowingDate.trim() !== "" &&
      areaInAcres > 0
    );
  }, [mobile, farmName, sowingDate, areaInAcres]);

  useEffect(() => {
    const coordinatesGeocoder = (query) => {
      const matches = query.match(/^(\-?\d+\.\d+)\s*,\s*(\-?\d+\.\d+)$/);
      if (!matches) {
        return null;
      }

      const lng = parseFloat(matches[2]);
      const lat = parseFloat(matches[1]);

      return [
        {
          center: [lng, lat],
          geometry: {
            type: "Point",
            coordinates: [lng, lat],
          },
          place_name: "Coordinates: " + lat + ", " + lng,
          place_type: ["coordinate"],
          properties: {},
          type: "Feature",
        },
      ];
    };

    getUserLocation();
    mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_API;

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [73.9329, 19.0595],
      zoom: 18,
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      flyTo: {
        bearing: 0,
        speed: 2,
        curve: 1,
        easing: (t) => t,
      },
      mapboxgl: mapboxgl,
      localGeocoder: coordinatesGeocoder,
    });

    mapRef.current.addControl(geocoder);

    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
    });

    mapRef.current.addControl(drawRef.current);

    mapRef.current.on("draw.create", updateCoordinates);
    mapRef.current.on("draw.update", updateCoordinates);

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, [getUserLocation, updateCoordinates]);

  return (
  //   <Box
  //   sx={{
  //     marginTop:"70px",
  //     width:"1300px",
  //     display: "flex",
  //     gap: "75px",
  //     padding: "50px",
  //     height: "750px",
  //     overflow: "hidden",
  //     flexDirection: "row",
  //     backgroundColor:'#224831',
  //     marginLeft:'50px' 
      
  //   }}
  // >
  //   <Grid
  //     container
  //     spacing={3}
  //     p={3}
  //     sx={{
  //       maxWidth: "450px",
  //       bgcolor: "white",
  //       borderRadius: "10px",
  //       boxShadow: 4,
  //       height: "calc(100vh - 80px)", // Adjusted height
  //       overflowY: "auto",
  //       backgroundColor: '#ffffff',
  //       "&::-webkit-scrollbar": {
  //     width: "8px",
  //   },
  //  '&::-webkit-scrollbar-thumb': {
  //             background: 'rgba(252, 175, 3, 0.5)',
  //             borderRadius: '4px',
  //           },
  //           '&::-webkit-scrollbar-thumb:hover': {
  //             background: 'rgba(252, 175, 3, 0.8)',
  //           },
  //     }}
  //   >
  //     <Grid item xs={12}>
  //     <Typography variant="h5" align="center" sx={{ color: "#224831" , padding:'0px', fontWeight:'bold'}}>
  //       Farm Onboarding Form
  //     </Typography>
  //   </Grid>
  //   <Grid item xs={12}>
  //     <TextField
  //       label="Mobile"
  //       value={mobile}
  //       onChange={handleMobileChange}
  //       fullWidth
  //       variant="outlined"
  //       margin="5px" // Reduced margin
  //       size="small"
  //       color="success"
  //       sx={{
  //         backgroundColor: '#ffffff',
  //       "& .MuiInputLabel-root.Mui-focused": {
  //     color: "#fcaf03", // Label color when typing
  //   }
  //       }}
  //     />
  //   </Grid>
  //   <Grid item xs={12}>
  //     <TextField
  //       label="Farm Name"
  //       value={farmName}
  //       onChange={handleFarmNameChange}
  //       fullWidth
  //       variant="outlined"
  //       margin="dense" // Reduced margin
  //       size="small"
  //       color="success"
  //       sx={{
  //         backgroundColor: '#ffffff',
  //         "& .MuiInputLabel-root.Mui-focused": {
  //           color: "#fcaf03", // Label color when typing
  //         }
  //       }}
  //     />
  //   </Grid>
  //   <Grid item xs={12}>
  //     <TextField
  //       label="Sowing Date"
  //       value={sowingDate}
  //       onChange={handleSowingDateChange}
  //       fullWidth
  //       type="date"
  //       InputLabelProps={{ shrink: true }}
  //       variant="outlined"
  //       margin="dense" // Reduced margin
  //       size="small"
  //       color="success"
  //       sx={{
  //         backgroundColor: '#ffffff',
  //         "& .MuiInputLabel-root.Mui-focused": {
  //           color: "#fcaf03", // Label color when typing
  //         }
  //       }}
  //     />
  //   </Grid>
  //   <Grid item xs={12}>
  //     <Typography variant="body2" sx={{ color: "#224831" }}>
  //       *DRAW FARM BOUNDARY
  //     </Typography>
  //     <TextField
  //       label="Area"
  //       value={`${areaInAcres} acres`}
  //       fullWidth
  //       InputProps={{ readOnly: true }}
  //       variant="outlined"
  //       margin="dense" // Reduced margin
  //       size="small"
  //       color="success"
  //       sx={{
  //         backgroundColor: '#ffffff',
  //         "& .MuiInputLabel-root.Mui-focused": {
  //           color: "#fcaf03", // Label color when typing
  //         }
  //       }}
  //     />
  //   </Grid>
  //   <Grid item xs={12}>
  //     <Button
  //       variant="contained"
  //       color="primary"
  //       onClick={handleSubmit}
  //       fullWidth
  //       sx={{
  //         bgcolor: "#fcaf03",
  //         color: "#224831",
  //         "&:hover": { bgcolor: "#224831", color:'#fcaf03' },
  //         borderRadius: "10px",
  //         padding: "8px 16px", // Reduced padding for compact look
  //         borderWidth: "2px",

  //       }}
  //       disabled={!isFormValid}
  //     >
  //       Submit
  //     </Button>
  //   </Grid>
  //   <Grid item xs={12}>
  //     <FileUpload />
  //   </Grid>
  //   </Grid>
  
  //   <Grid
  //     container
  //     spacing={3}
  //     p={3}
  //     sx={{
       
  //       bgcolor: "white",
  //       borderRadius: "10px",
  //       boxShadow: 4,
  //       height: "calc(100vh - 100px)", // Adjusted height
  //       overflowY: "auto",
  //       // backgroundColor:'#224831',
  //       display: "flex",
  //       alignItems: "center",
  //       justifyContent: "center",
  //     }}
  //   >
  //     <Box
  //       ref={mapContainer}
  //       sx={{
  //         flex: 1,
  //         width: "100%", // Full width to match container
  //         height: "100%", // Full height to match container
  //         borderRadius: "10px",
  //         overflow: "hidden",
  //         boxShadow: 4,
  //         // border: "3px solid #FFFFFF",
  //       }}
  //     />
  //   </Grid>
  
  //   {showSuccessAlert && (
  //     <Alert
  //       severity="success"
  //       onClose={() => setShowSuccessAlert(false)}
  //       sx={{
  //         position: "fixed",
  //         top: "50%",
  //         left: "50%",
  //         transform: "translate(-50%, -50%)",
  //         zIndex: 9999,
  //         maxWidth: "80%",
  //         backgroundColor: "whitesmoke",
  //         borderRadius: 4,
  //         padding: 2,
  //         boxShadow: 4,
  //       }}
  //     >
  //       🌱 Thank you for providing information! Please wait 🌱
  //       <br />
  //       <br />
  //       <CircularProgress color="success" />
  //     </Alert>
  //   )}
  // </Box>
  


  <Container
    sx={{
      marginTop:"70px",
      width:"1300px",
      display: "flex",
      gap: "50px",
      padding: "50px",
      // paddingBottom:'20px',
      height: "720px",
      overflow: "hidden",
      flexDirection: "row",
      backgroundColor:'#ffffff',
      marginLeft:'50px' 
      
    }}
  >
    <Grid
      container
      spacing={3}
      p={3}
      sx={{
        maxWidth: "450px",
        bgcolor: "white",
        borderRadius: "10px",
        boxShadow: 4,
        marginLeft:'5px',
        height: "calc(100vh - 102px)", // Adjusted height
        overflowY: "auto",
        backgroundColor: '#224831',
        "&::-webkit-scrollbar": {
      width: "8px",
      
    },
   '&::-webkit-scrollbar-thumb': {
              background: 'rgba(252, 175, 3, 0.5)',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: 'rgba(252, 175, 3, 0.8)',
            },
      }}
    >
      <Grid item xs={12}>
      <Typography variant="h5" sx={{ color: "#ffffff" , padding:'0px', fontWeight:'bold'}}>
        Farm Onboarding Form
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography color="#fcaf03"> Mobile</Typography>
      <TextField
        // label="Mobile"
        value={mobile}
        onChange={handleMobileChange}
        fullWidth
        variant="outlined"
        margin="dense" // Reduced margin
        size="small"
        color="success"
        sx={{
          backgroundColor: '#ffffff',
        "& .MuiInputLabel-root.Mui-focused": {
      color: "#fcaf03", // Label color when typing
    }
        }}
      />
    </Grid>
    <Grid item xs={12}>
    <Typography color="#fcaf03">Farm Name</Typography>
      <TextField
        // label="Farm Name"
        value={farmName}
        onChange={handleFarmNameChange}
        fullWidth
        variant="outlined"
        margin="dense" // Reduced margin
        size="small"
        color="success"
        sx={{
          backgroundColor: '#ffffff',
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#fcaf03", // Label color when typing
          }
        }}
      />
    </Grid>
    <Grid item xs={12}>
    <Typography color="#fcaf03">Sowing Date</Typography>
      <TextField
        // label="Sowing Date"
        value={sowingDate}
        onChange={handleSowingDateChange}
        fullWidth
        type="date"
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        margin="dense" // Reduced margin
        size="small"
        color="success"
        sx={{
          backgroundColor: '#ffffff',
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#fcaf03", // Label color when typing
          }
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2" sx={{ color: "#fcaf03" }}>
        *DRAW FARM BOUNDARY TO POPULATE
      </Typography>
     
      <TextField
        // label="Area"
        value={`${areaInAcres} acres`}
        fullWidth
        InputProps={{ readOnly: true }}
        variant="outlined"
        margin="dense" // Reduced margin
        size="small"
        color="success"
        sx={{
          backgroundColor: '#ffffff',
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#fcaf03", // Label color when typing
          }
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        fullWidth
        sx={{
          bgcolor: "#fcaf03",
          color: "#224831",
          "&:hover": { bgcolor: "#224831", color:'#fcaf03' },
          borderRadius: "10px",
          padding: "8px 16px", // Reduced padding for compact look
          borderWidth: "2px",

        }}
        disabled={!isFormValid}
      >
        Submit
      </Button>
    </Grid>
     <Grid item xs={12}>
      <FileUpload />
    </Grid> 
    </Grid>
  
    <Grid
      container
      spacing={3}
      p={3}
      sx={{
       
        bgcolor: "white",
        borderRadius: "10px",
        boxShadow: 4,
        height: "calc(100vh - 100px)", // Adjusted height
        overflowY: "auto",
        backgroundColor:'#224831',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        ref={mapContainer}
        sx={{
          flex: 1,
          width: "100%", // Full width to match container
          height: "100%", // Full height to match container
          borderRadius: "10px",
          overflow: "hidden",
          boxShadow: 4,
          // border: "3px solid #FFFFFF",
        }}
      />
    </Grid>
  
    {showSuccessAlert && (
      <Alert
        severity="success"
        onClose={() => setShowSuccessAlert(false)}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
          maxWidth: "80%",
          backgroundColor: "whitesmoke",
          borderRadius: 4,
          padding: 2,
          boxShadow: 4,
        }}
      >
        🌱 Thank you for providing information! Please wait 🌱
        <br />
        <br />
        <CircularProgress color="success" sx={{marginLeft:"140px"}}/>
      </Alert>
    )}
  </Container>

  );
};
export default Map;