
import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Card, CardContent, Grid, Typography, Button } from "@mui/material";
import EnhancedTable from "./Table";
import plant from "../Assests/logos/plant.svg";
import Pagetwo from "./Pagetwo";
import HarvestWindow from "./HarvestWindow";
import { useAuth } from "../context/AuthContextProvider";
import MuiAppBar from "@mui/material/AppBar";
import { ReactComponent as CropCalander } from "../Assests/CropCalander.svg";
import { ReactComponent as CropSummary } from "../Assests/CropSummary.svg";
import { ReactComponent as Myfarm } from "../Assests/Myfarm.svg";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  backgroundColor: "white",
  color: "black",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(4),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Summarypage() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [totalArea, setTotalArea] = useState(0);
  const [uniqueCrops, setUniqueCrops] = useState(new Set());
  const [selectedCrop, setSelectedCrop] = useState(null);
  const [cropMonitorData, setCropMonitorData] = useState([]);
  const { user } = useAuth();
  const navigate = useNavigate();

  const [healthCategoryCounts, setHealthCategoryCounts] = useState({
    Good: 0,
    Medium: 0,
    Poor: 0,
  });


  const toggleDrawer = useCallback(() => setOpen((prev) => !prev), []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  const handleCropClick = useCallback((crop) => {
    setSelectedCrop(crop);
    document.body.style.overflow = "unset";
    window.scrollTo(0, 750);
  }, []);

  

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.mobile?.mobile) return;
      try {
        const response = await axios.get(
          `https://3mpqory1se.execute-api.ap-south-1.amazonaws.com/prod/getReport_bulkUpload?mobile=${user.mobile.mobile}`
        );

        const { lstm_predictions, crop_monitor_data } = response.data.body;

        setData(lstm_predictions);
        setCropMonitorData(crop_monitor_data);

        let areaSum = 0;
        const cropsSet = new Set();
        lstm_predictions.forEach((farm) => {
          areaSum += farm.farm_area || 0;
          cropsSet.add(farm.predicted_crop);
        });
        setTotalArea(areaSum);
        setUniqueCrops(cropsSet);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [user]);

  const filteredData = useMemo(() => {
    return selectedCrop
      ? data.filter((farm) => farm.predicted_crop === selectedCrop)
      : data;
  }, [data, selectedCrop]);

  useEffect(() => {
    const calculateHealthCategories = () => {
      const updatedCounts = { Good: 0, Medium: 0, Poor: 0 };

      const relevantCropMonitorData = cropMonitorData.filter((entry) =>
        filteredData.some((farm) => farm.farm_name === entry.farm_name)
      );

      relevantCropMonitorData.forEach((entry) => {
        if (entry.Health_category === "Good") updatedCounts.Good++;
        else if (entry.Health_category === "Medium") updatedCounts.Medium++;
        else if (entry.Health_category === "Poor") updatedCounts.Poor++;
      });

      setHealthCategoryCounts(updatedCounts);
    };

    calculateHealthCategories();
  }, [filteredData, cropMonitorData]);

  return (
    <Box sx={{ display: "flex", color: "#000000", marginLeft: "150px" }}>
      <CssBaseline />
      <Main open={open}>
        <DrawerHeader />
        <Box
          sx={{
            backgroundColor: "#ffffff",
            padding: "10px 10px 90px 45px",
            marginLeft: "20px",
            borderRadius: "10px",
            marginTop: "5px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Myfarm
              style={{
                height: "75px",
                width: "50px",
                mt: 1,
                mb: 2,
                paddingBottom: "00px",
                marginLeft: "4px",
              }}
            />
            <Typography
              sx={{
                flex: 1,
                textAlign: "left",
                marginLeft: "10px",
                fontSize: "h4.fontSize",
                color: "#224831",
                mt: 1,
                mb: 2,
                fontWeight: "bold",
                textUnderlineOffset: "6px",
                paddingBottom: "0px",
                fontFamily: "open sans",
              }}
            >
              My Farm
            </Typography>
          </Box>
          <Grid container>
            <Grid item xs={12} md={8}>
              <EnhancedTable data={data} />
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: "flex", height: "300px" }}>
              <div sx={{ display: "flex", flexDirection: "column" }}>
                <Card
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "left",
                    paddingLeft:"20px",
                    flexDirection: "column",
                    width: "350px",
                    height: "275px",
                    marginLeft: "45px",
                    borderRadius: "10px",
                    backgroundColor: "#224831",
                    backdropFilter: "blur(2px)",
                    marginBottom: "15px",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="#fcaf03"
                    sx={{ fontWeight: "bold" }}
                  >
                    Area under management
                  </Typography>
                  <Typography
                    variant="h4"
                    color="white"
                    sx={{ marginBottom: "30px" }}
                  >
                    {totalArea.toFixed(2)} Acres
                  </Typography>
                  <Typography
                    variant="h5"
                    color="#fcaf03"
                    sx={{ fontWeight: "bold" }}
                  >
                    Farms under management
                  </Typography>
                  <Typography variant="h4" color="white">
                    {data.length} Farms
                  </Typography>
                </Card>

                <Card
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                    flexDirection: "column",
                    width: "350px",
                    height: "275px",
                    marginLeft: "45px",
                    borderRadius: "10px",
                    backgroundColor: "#224831",
                    // marginTop: "30px",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="#FCAF03"
                    sx={{ fontWeight: "bold" , marginLeft:'20px'}}
                  >
                    Crops under management
                  </Typography>
                  <Typography variant="h7" color="#FFFFFF" sx={{marginLeft:'20px'}}>
                    (select crop to view details)
                  </Typography>
                  <CardContent sx={{marginLeft:'40px'}} >
                    {Array.from(uniqueCrops).map((crop, index) => (
                      <Button
                        key={index}
                        variant="contained"
                        startIcon={
                          <img
                            src={plant}
                            alt={crop}
                            style={{
                              height: "20px",
                              width: "20px",
                              marginLeft: "1px",
                            }}
                          />
                        }
                        sx={{
                          borderRadius: "10px",
                          padding: "4px 6px",
                          color: "#224831",
                          borderColor: "#FCAF03",
                          borderWidth: "2px",
                          borderStyle: "solid",
                          backgroundColor: "#FCAF03",
                          marginLeft: "1px",
                          marginRight: "10px",
                          marginTop: "5px",
                          width: "110px",
                        }}
                        onClick={(e) => handleCropClick(crop)} // Update selected crop on click
                      >
                        {crop}
                      </Button>
                    ))}
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Box>

        <Grid container>
          <Grid
            item
            md={12}
            sx={{
              backgroundColor: "#ffffff",
              padding: "10px 1px 25px 4px",
              borderRadius: "10px",
              marginTop: "25px",
              marginLeft: "20px",
              height: "720px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <CropSummary
                style={{
                  height: "75px",
                  width: "50px",
                  marginBottom: "-15px",
                  paddingBottom: "00px",
                  marginLeft: "35px",
                  paddingTop: "15px",
                }}
              />
              <Typography
                sx={{
                  flex: 1,
                  textAlign: "left",
                  fontSize: "h4.fontSize",
                  color: "#224831",
                  mt: 1,
                  mb: 2,
                  fontWeight: "bold",
                  textUnderlineOffset: "6px",
                  fontFamily: "open sans",
                  paddingLeft: "10px",
                  marginBottom: "-15px",
                  paddingTop: "15px",
                }}
                variant="h4"
                component="div"
              >
                Crop Summary
              </Typography>
            </Box>

            <Pagetwo
              piechartdata={[
                { name: "Healthy", value: healthCategoryCounts.Good },
                { name: "Average", value: healthCategoryCounts.Medium },
                { name: "Unhealthy", value: healthCategoryCounts.Poor },
              ]}
              selectedCrop={selectedCrop}
              filteroutData={filteredData}
            />
          </Grid>
          <Grid
            item
            md={12}
            sx={{
              backgroundColor: "#ffffff",
              padding: "10px 1px 10px 4px",
              borderRadius: "10px",
              marginTop: "25px",
              marginLeft: "20px",
              height: "675px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <CropCalander
                style={{
                  height: "50px",
                  width: "50px",
                  mt: 1,
                  marginBottom: "10px",
                  paddingBottom: "00px",
                  marginLeft: "35px",
                }}
              />
              <Typography
                sx={{
                  flex: 1,
                  textAlign: "left",
                  marginLeft: "10px",
                  fontSize: "h4.fontSize",
                  color: "#224831",
                  mt: 1,
                  mb: 2,
                  fontWeight: "bold",
                  textUnderlineOffset: "6px",
                  paddingBottom: "0px",
                  fontFamily: "open sans",
                }}
              >
                Harvest Calendar
              </Typography>
            </Box>
            <HarvestWindow filteroutData={filteredData} />
          </Grid>
        </Grid>
      </Main>
    </Box>
  );
}
