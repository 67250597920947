import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { ReactComponent as Sateliteicon } from "../assets/icon/satelitesvg.svg";
import { ReactComponent as Crop } from "../assets/icon/plant.svg";
import { ReactComponent as Dateshown } from "../assets/icon/calendar.svg";
import { ReactComponent as Locationicon } from "../assets/icon/location.svg";
import { ReactComponent as Yieldicon } from "../assets/icon/yield.svg";
import { ReactComponent as Weathericon } from "../assets/icon/weatherisvg.svg";
import { ReactComponent as Plantsicon } from "../assets/icon/growth.svg";
import { ReactComponent as Ruppeiconn } from "../assets/icon/rupee.svg";

import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Paper,
  Button,
} from "@mui/material";
import Map from "../maps/Map";
import WeatherChart from "../charts/Weather";
import { Line } from "react-chartjs-2";
import MiniDrawer from "../components/MiniDrawer";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import LoadingAnimation from "../components/LoadingAnimation";
import Mapcopy from "../mapscopy/Mapcopy";
import authService from "../service/auth-service";
import { useNavigate } from "react-router-dom";

const Farm = ({
  Farm_Area,
  cropYieldData,
  farmCoordinates,
  farmInfoUrl,
  meanValues,
  farmInfoLastUpdated,
  chartExample12state,
}) => {
  const navigate = useNavigate();
  const [selectedDataset, setSelectedDataset] = useState("Vegatation"); // Default selected dataset

  // Function to handle button click for toggling datasets
  const handleDatasetClick = (dataset) => {
    setSelectedDataset(dataset);
  };

  const filteredData = {
    // datalavels: weatherData.time,
    labels: chartExample12state.data.labels,
    datasets: chartExample12state.data.datasets.filter(
      (dataset) => dataset.label === selectedDataset
    ),
  };

  // Function to handle download button click
  const handleDownload = () => {
    const wb = XLSX.utils.book_new();
    const wsCropYieldData = XLSX.utils.json_to_sheet([cropYieldData]);
    const wsMeanValues = XLSX.utils.json_to_sheet([meanValues]);
    const wsCoordinates = XLSX.utils.json_to_sheet([farmCoordinates]);
    const wsFilteredData = XLSX.utils.json_to_sheet([filteredData]);

    XLSX.utils.book_append_sheet(wb, wsCropYieldData, "Crop Yield Data");
    XLSX.utils.book_append_sheet(wb, wsMeanValues, "Mean Values");
    XLSX.utils.book_append_sheet(wb, wsCoordinates, "Farm Coordinates");
    XLSX.utils.book_append_sheet(wb, wsFilteredData, "Filtered Data");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(excelBlob, "farm_data.xlsx");
  };

  const hanldeLogout = () => {
    authService.logout();
    navigate("/");
  };

  return (
    <>
      {cropYieldData.length !== 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "Row",
            padding: "10px",
            gap: "5px",
            backgroundColor: "#336600",
          }}
        >
          <Grid
            container
            rowSpacing={4}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={3}>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(50% - 10px)", // Adjust as needed for spacing
                  minWidth: "250px", // Set a minimum width for consistency
                  height: "100px", // Set a fixed height for all cards
                }}
              >
                <CardContent
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    variant="h6"
                  >
                    Farm No
                  </Typography>
                  <Typography style={{ fontSize: "15px" }} variant="h7">
                    {cropYieldData.farm_no}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(50% - 10px)", // Adjust as needed for spacing
                  minWidth: "250px", // Set a minimum width for consistency
                  height: "100px", // Set a fixed height for all cards
                }}
              >
                <CardContent
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    variant="h6"
                  >
                    Survey No
                  </Typography>
                  <Typography style={{ fontSize: "15px" }} variant="h7">
                    {cropYieldData.survey_no}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(50% - 10px)", // Adjust as needed for spacing
                  minWidth: "250px", // Set a minimum width for consistency
                  height: "100px", // Set a fixed height for all cards
                }}
              >
                <CardContent
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    variant="h6"
                  >
                    Farm Area
                  </Typography>
                  <Typography style={{ fontSize: "15px" }} variant="h7">
                    {(+Farm_Area).toFixed(2)} Acre
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={3}>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(50% - 10px)", // Adjust as needed for spacing
                  minWidth: "250px", // Set a minimum width for consistency
                  height: "100px", // Set a fixed height for all cards
                }}
              >
                <CardContent
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    variant="h6"
                  >
                    Village
                  </Typography>
                  <Typography style={{ fontSize: "15px" }} variant="h7">
                    {cropYieldData.village}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(50% - 10px)", // Adjust as needed for spacing
                  minWidth: "250px", // Set a minimum width for consistency
                  height: "100px", // Set a fixed height for all cards
                }}
              >
                <CardContent
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    variant="h6"
                  >
                    Taluka
                  </Typography>
                  <Typography style={{ fontSize: "15px" }} variant="h7">
                    {cropYieldData.taluka}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(50% - 10px)", // Adjust as needed for spacing
                  minWidth: "250px", // Set a minimum width for consistency
                  height: "100px", // Set a fixed height for all cards
                }}
              >
                <CardContent
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    variant="h6"
                  >
                    District
                  </Typography>
                  <Typography style={{ fontSize: "15px" }} variant="h7">
                    {cropYieldData.district}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(50% - 10px)", // Adjust as needed for spacing
                  minWidth: "250px", // Set a minimum width for consistency
                  height: "100px", // Set a fixed height for all cards
                }}
              >
                <CardContent
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    variant="h6"
                  >
                    State
                  </Typography>
                  <Typography style={{ fontSize: "15px" }} variant="h7">
                    {cropYieldData.state}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(50% - 10px)", // Adjust as needed for spacing
                  minWidth: "250px", // Set a minimum width for consistency
                  height: "100px", // Set a fixed height for all cards
                }}
              >
                <CardContent
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    variant="h6"
                  >
                    Crop
                  </Typography>
                  <Typography style={{ fontSize: "15px" }} variant="h7">
                    {cropYieldData.predicted_crop}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(50% - 10px)", // Adjust as needed for spacing
                  minWidth: "250px", // Set a minimum width for consistency
                  height: "100px", // Set a fixed height for all cards
                }}
              >
                <CardContent
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    variant="h6"
                  >
                    Crop Season
                  </Typography>
                  <Typography style={{ fontSize: "15px" }} variant="h7">
                    {cropYieldData.crop_season}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages.Sowing && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Sowing Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages.Sowing
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages.Germination && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Germination Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages.Germination
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages.Tillering && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Tillering Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages.Tillering
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages["Panicle Innitiation"] && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Panicle Initiation Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages["Panicle Innitiation"]
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages.Flowering && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Flowering Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages.Flowering
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages["Pod-Filling"] && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Pod Filling Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages["Pod-Filling"]
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages["Boll Formation"] && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Boll Formation Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages["Boll Formation"]
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages.Heading && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Heading Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages.Heading
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages.Tasseling && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Tasseling Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages.Tasseling
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages.Silking && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Silking Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages.Silking
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages.Vegetative && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Vegetative Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages.Vegetative
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages.Maturity && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Maturity Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages.Maturity
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages.transplating && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Transplating Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages.transplating
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages["Tuber Initiation"] && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Tuber Initiation Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages["Tuber Initiation"]
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages["Tuber-Bulking"] && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Tuber Bulking Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages["Tuber-Bulking"]
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages["Vegetative Growth"] && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Vegetative Growth Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages["Vegetative Growth"]
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages.Fruiting && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Fruiting Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages.Fruiting
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages["First-Picking"] && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        First Picking Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages["First-Picking"]
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages["Second-Maturity"] && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Second Maturity Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages["Second-Maturity"]
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages["Second-Picking"] && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Second Picking Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages["Second-Picking"]
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {cropYieldData.growth_stages &&
              cropYieldData.growth_stages.Harvest && (
                <Grid item xs={3}>
                  <Card
                    style={{
                      margin: "auto",
                      width: "calc(50% - 10px)", // Adjust as needed for spacing
                      minWidth: "250px", // Set a minimum width for consistency
                      height: "100px", // Set a fixed height for all cards
                    }}
                  >
                    <CardContent
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                        variant="h6"
                      >
                        Harvest Date
                      </Typography>
                      <Typography style={{ fontSize: "15px" }} variant="h7">
                        {new Date(
                          cropYieldData.growth_stages.Harvest
                        ).toLocaleDateString("en-GB")}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

            {/* grough date ends */}
            <Grid item xs={3}>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(50% - 10px)", // Adjust as needed for spacing
                  minWidth: "250px", // Set a minimum width for consistency
                  height: "100px", // Set a fixed height for all cards
                }}
              >
                <CardContent
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    variant="h6"
                  >
                    Crop Yield
                  </Typography>
                  <Typography style={{ fontSize: "15px" }} variant="h7">
                    {cropYieldData.yield_crop} MT/acre
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={3}>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(50% - 10px)", // Adjust as needed for spacing
                  minWidth: "250px", // Set a minimum width for consistency
                  height: "100px", // Set a fixed height for all cards
                }}
              >
                <CardContent
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    variant="h6"
                  >
                    Total Crop Yield
                  </Typography>
                  <Typography style={{ fontSize: "15px" }} variant="h7">
                    {cropYieldData.farm_yield} MT
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={3}>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(50% - 10px)", // Adjust as needed for spacing
                  minWidth: "250px", // Set a minimum width for consistency
                  height: "100px", // Set a fixed height for all cards
                }}
              >
                <CardContent
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    variant="h6"
                  >
                    Location
                  </Typography>
                  <Typography style={{ fontSize: "15px" }} variant="h7">
                    {farmCoordinates[1]}° N, {farmCoordinates[0]}° E
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={3}>
              <Card
                style={{
                  margin: "auto",
                  width: "calc(50% - 10px)", // Adjust as needed for spacing
                  minWidth: "250px", // Set a minimum width for consistency
                  height: "100px", // Set a fixed height for all cards
                }}
              >
                <CardContent
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                    variant="h6"
                  >
                    Farm Area
                  </Typography>
                  <Typography style={{ fontSize: "15px" }} variant="h7">
                    {cropYieldData.farm_area} acre
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={6} lg={6}>
              <Card>
                <CardContent>
                  <Typography
                    sx={{ marginLeft: "215px", marginBottom: "5px" }}
                    variant="h6"
                  >
                    {" "}
                    <strong>Monitoring Data</strong>
                  </Typography>
                  <Map
                    url={farmInfoUrl}
                    bounds={farmCoordinates}
                    meanValues={meanValues}
                    farmInfoLastUpdated={farmInfoLastUpdated}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={6} lg={6}>
              <Card>
                <CardContent>
                  <Typography
                    sx={{ marginLeft: "215px", marginBottom: "10px" }}
                    variant="h6"
                  >
                    {" "}
                    <strong>Soil Data</strong>
                  </Typography>
                  <Mapcopy
                    url={farmInfoUrl}
                    bounds={farmCoordinates}
                    meanValues={meanValues}
                    farmInfoLastUpdated={farmInfoLastUpdated}
                    firstThreevalues={false}
                    // sowingData={cropYieldData.growth_stages.Sowing||"8"}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={6} lg={6}>
              <Card>
                <CardContent>
                  <Sateliteicon
                    style={{ marginLeft: "10px", marginTop: "10px" }}
                    height={35}
                    width={35}
                  />
                  <Typography sx={{ marginLeft: "215px" }} variant="h6">
                    {" "}
                    <strong>Satellite Data</strong>
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {/* Buttons to toggle datasets */}
                    <button
                      onClick={() => handleDatasetClick("Vegatation")}
                      style={{
                        backgroundColor: "#336600",
                        padding: "8px",
                        color: "white",
                        borderRadius: "5px",
                      }}
                    >
                      Vegatation
                    </button>
                    <button
                      onClick={() => handleDatasetClick("Soil Moisture")}
                      style={{
                        backgroundColor: "#336600",
                        padding: "8px",
                        color: "white",
                        borderRadius: "5px",
                      }}
                    >
                      Soil Moisture
                    </button>
                    <button
                      onClick={() => handleDatasetClick("Plant Height")}
                      style={{
                        backgroundColor: "#336600",
                        padding: "8px",
                        color: "white",
                        borderRadius: "5px",
                      }}
                    >
                      Plant Height
                    </button>
                  </div>

                  <Line
                    data={filteredData}
                    options={chartExample12state.options}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={6} lg={6}>
              <Card>
                <CardContent>
                  <Weathericon
                    style={{ marginLeft: "10px", marginTop: "10px" }}
                    height={35}
                    width={35}
                  />
                  <Typography sx={{ marginLeft: "230px" }} variant="h6">
                    Weather Data
                  </Typography>
                  <WeatherChart
                    latitude={farmCoordinates[1]}
                    longitude={farmCoordinates[0]}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" mt={3}>
                <Button
                  onClick={handleDownload}
                  variant="contained"
                  color="success"
                >
                  Download Excel
                </Button>
                <div style={{ marginLeft: "10px" }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={hanldeLogout}
                  >
                    Logout
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <LoadingAnimation />
      )}
    </>
  );
};

export default Farm;
