import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { Grid, Card, CardContent, Typography, Box, Paper } from "@mui/material";
import { chartExample12 } from "../components/charts";
import Farm from "./Farm";
import AWS from "aws-sdk";

const CropYeildPren = () => {
  const {
    State,
    District,
    Taluka,
    Village,
    Survey_No,
    Farm_No,
    from_date,
    to_date,
    Farm_Area,
  } = useParams();
  const [cropYieldData, setCropYieldData] = useState([]);

  const [farmInfoUrl, setFarmInfoUrl] = useState("");
  const [farmCoordinates, setFarmCoordinates] = useState([71, 22, 71, 22]);
  const [chartExample12state, setchartExample12] = useState(chartExample12);
  const [meanValues, setMeanValues] = useState({});
  const [farmInfoLastUpdated, setFarmInfoLastUpdated] = useState("");
  const [farmArea, setFarmArea] = useState("");

  useEffect(() => {
    onFarmSelected(
      State,
      District,
      Taluka,
      Village,
      Survey_No,
      Farm_No,
      from_date,
      to_date
    );
  }, [
    State,
    District,
    Taluka,
    Village,
    Survey_No,
    Farm_No,
    from_date,
    to_date,
  ]);

  useEffect(() => {
    getMeanFarmInfo(Survey_No, Farm_No);
  }, [Survey_No, Farm_No]);

  const onFarmSelected = (
    State,
    District,
    Taluka,
    Village,
    Survey_No,
    Farm_No,
    from_date,
    to_date
  ) => {
    var farmUrl = {
      method: "get",
      url: encodeURI(
        `https://3mpqory1se.execute-api.ap-south-1.amazonaws.com/prod/getSnFarminfo?State=${State}&District=${District}&Taluka=${Taluka}&Village=${Village}&Survey_No=${Survey_No}&Farm_No=${Farm_No}&from_date=${from_date}&to_date=${to_date}`
      ),
      headers: {},
    };

    axios(farmUrl)
      .then((response) => {
        var dataTable = response.data;
        if (dataTable.length == 0) {
          console.log("farm info not loaded");
        } else {
          const inputArray = dataTable[0]["coordinates"];
          // const outputArray = inputArray.map((number) =>
          //   parseFloat(number.toFixed(3))
          // );

          setFarmCoordinates(inputArray);
          setFarmInfoLastUpdated(
            response.data[0]["timestamp_satellite"].split("_")[1]
          );

          createBucket(response.data[0]["data"]["mergedInfo"]);
          setWeather(inputArray);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const setWeather = async (inputArray) => {
    try {
      const response = await fetch(
        "https://3mpqory1se.execute-api.ap-south-1.amazonaws.com/prod/setsnDatedWeather",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            lat: inputArray[1],
            lng: inputArray[0],
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getMinMax = (array) => {
    let min = array[0],
      max = array[0];

    for (let i = 1; i < array.length; i++) {
      let value = array[i];
      min = value < min ? value : min;
      max = value > max ? value : max;
    }

    return [min, max];
  };

  const getMeanFarmInfo = (Survey_No, Farm_No) => {
    //**this function/api generate farm map and satelite chart data both//
    var farmUrl = {
      method: "get",
      url: encodeURI(
        `https://3mpqory1se.execute-api.ap-south-1.amazonaws.com/prod/getSnFarmInfoMean?Survey_No=${Survey_No}&Farm_No=${Farm_No}`
      ),
      headers: {},
    };
    axios(farmUrl)
      .then((response) => {
        var dataTable = response.data;

        setchartExample12((prevChartExample) => {
          const newChartExample = { ...prevChartExample };
          newChartExample.data.labels = dataTable.timestamp_satellite;
          newChartExample.data.datasets[0].data = dataTable.ndvi;
          newChartExample.data.datasets[1].data = dataTable.smi_nirs.map(
            (x) => (x + 0.5) * 80
          );
          newChartExample.data.datasets[2].data = dataTable.plant_height; //adding plant height in satelite chart
          const datalen = newChartExample.data.datasets[0].data.length;
          const meanValues = {};

          meanValues.ndvi = newChartExample.data.datasets[0].data[datalen - 1];
          meanValues.smi_nirs =
            newChartExample.data.datasets[1].data[datalen - 1];

          let minmax = getMinMax(dataTable.nitrogen);
          meanValues.nitrogen = dataTable.nitrogen.map(
            (x) => ((x - minmax[0]) * 0.6 * 250) / (minmax[1] - minmax[0])
          )[datalen - 1];

          minmax = getMinMax(dataTable.phosphorus);
          meanValues.phosphorus = dataTable.phosphorus.map(
            (x) => ((x - minmax[0]) * 39 * 0.9) / (minmax[1] - minmax[0])
          )[datalen - 1];

          minmax = getMinMax(dataTable.potassium);
          meanValues.potassium = dataTable.potassium.map(
            (x) => ((x - minmax[0]) * 450 * 1.75) / (minmax[1] - minmax[0])
          )[datalen - 1];

          meanValues.oc = dataTable.oc[datalen - 1];
          meanValues.plant_height = dataTable.plant_height[datalen - 1];
          meanValues.soil_ph = dataTable.soil_ph[datalen - 1];

          if (meanValues.ndvi !== undefined && meanValues.ndvi !== null) {
            meanValues.ndvi = meanValues.ndvi.toFixed(2);
            meanValues.smi_nirs = (1 * meanValues.smi_nirs).toFixed(2);
            meanValues.nitrogen = (1 * meanValues.nitrogen).toFixed(2);
            meanValues.phosphorus = (1 * meanValues.phosphorus).toFixed(2);
            meanValues.potassium = (1 * meanValues.potassium).toFixed(2);
            meanValues.oc = meanValues.oc.toFixed(2);
            meanValues.soil_ph = (meanValues.soil_ph - 1).toFixed(2);
            meanValues.plant_height = meanValues.plant_height.toFixed(2);
          }

          setMeanValues(meanValues);
          return { ...newChartExample };
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const createBucket = async (farmInfoKey) => {
    try {
      const AWS_S3_BUCKETNAME =
        process.env.REACT_APP_AWS_S3_BUCKETNAME_SURVEYNO;
      const AWS_S3_REGION = process.env.REACT_APP_AWS_S3_REGION_SURVEYNO;
      const AWS_S3_SECRET = process.env.REACT_APP_AWS_S3_SECRET_SURVEYNO;
      const AWS_S3_KEY = process.env.REACT_APP_AWS_S3_KEY_SURVEYNO;

      AWS.config.update({
        accessKeyId: AWS_S3_KEY,
        secretAccessKey: AWS_S3_SECRET,
        region: AWS_S3_REGION,
      });

      const s3 = new AWS.S3();

      // Replace farm_info_key with the actual key
      const myBucket = AWS_S3_BUCKETNAME;
      const myKey = farmInfoKey;
      const signedUrlExpireSeconds = 60 * 15;

      const url = await s3.getSignedUrlPromise("getObject", {
        Bucket: myBucket,
        Key: myKey,
        Expires: signedUrlExpireSeconds,
      });
      setFarmInfoUrl(url);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loadBalancerUrl = `http://survey-no-models-lb-1188372353.ap-south-1.elb.amazonaws.com/?state=${State}&district=${District}&taluka=${Taluka}&village=${Village}&survey_no=${Survey_No}&farm_no=${Farm_No}&from_date=${from_date}&to_date=${to_date}`;
        const response = await axios.get(loadBalancerUrl);

        console.log("Response from load balancer:", response);

        if (response && response.data && response.data.status === "success") {
          console.log("Load balancer triggered successfully.");
        } else {
          console.error(
            "Failed to trigger load balancer:",
            response ? response.data : "No response received"
          );
        }
      } catch (error) {
        console.error("Error triggering load balancer:", error);
      }

      // Call the second API regardless of the success or failure of the first call
      try {
        const cropYieldResponse = await axios.get(
          `https://3mpqory1se.execute-api.ap-south-1.amazonaws.com/prod/getCropYieldDataSurveyNo?state=${State}&district=${District}&taluka=${Taluka}&village=${Village}&survey_no=${Survey_No}&farm_no=${Farm_No}`
        );

        setCropYieldData(cropYieldResponse.data.body);
      } catch (cropYieldError) {
        console.error("Error fetching crop yield data:", cropYieldError);
      }
    };

    fetchData();
  }, [
    State,
    District,
    Taluka,
    Village,
    Survey_No,
    Farm_No,
    from_date,
    to_date,
  ]);

  return (
    <Farm
      Farm_Area={Farm_Area}
      cropYieldData={cropYieldData}
      farmCoordinates={farmCoordinates}
      farmInfoUrl={farmInfoUrl}
      meanValues={meanValues}
      farmInfoLastUpdated={farmInfoLastUpdated}
      chartExample12state={chartExample12state}
    />
  );
};

export default CropYeildPren;
