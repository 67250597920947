import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import AWS from "aws-sdk";
import NewFarm from "./NewFarm";

const Dashboard = () => {
  const { mobile, farm_name } = useParams();
  const mobileNumber = +mobile; // Convert to number

  const [farmInfoUrl, setFarmInfoUrl] = useState("");
  const [geojsonData, setGeojsonData] = useState({});
  const [currentLayer, setCurrentLayer] = useState("NDVI");
  const [farmCoordinates, setFarmCoordinates] = useState({}); // Ensure valid coordinates array
  const [healthScore, setHealthScore] = useState(0.9); // Initial state as null to check later
  const [healthCategory, setHealthCategory] = useState("Good"); // Same here
  const [lstmData, setLstmData] = useState({}); // Initial state null
  const [geojsonLoading, setGeojsonLoading] = useState(true);
  const [lstmLoading, setLstmLoading] = useState(true);
  const [soilLoading, setSoilLoading] = useState(true);
  const [soilData, setSoilData] = useState({});
const [multi_index_stress_results,setmulti_index_stress_results] = useState([])
  // Function to generate S3 signed URL using AWS SDK
  const createBucket = async (farmInfoKey) => {
    try {
      const {
        REACT_APP_AWS_S3_BUCKETNAME,
        REACT_APP_AWS_S3_REGION,
        REACT_APP_AWS_S3_SECRET,
        REACT_APP_AWS_S3_KEY,
      } = process.env;

      AWS.config.update({
        accessKeyId: REACT_APP_AWS_S3_KEY,
        secretAccessKey: REACT_APP_AWS_S3_SECRET,
        region: REACT_APP_AWS_S3_REGION,
      });

      const s3 = new AWS.S3();
      const signedUrlExpireSeconds = 60 * 15;

      const url = await s3.getSignedUrlPromise("getObject", {
        Bucket: REACT_APP_AWS_S3_BUCKETNAME,
        Key: farmInfoKey,
        Expires: signedUrlExpireSeconds,
      });

      setFarmInfoUrl(url); // Set the signed URL
    } catch (error) {
      console.error("Error creating signed URL:", error);
    }
  };

  // Function to fetch GeoJSON data
  const fetchGeojsonData = async () => {
    try {
      setGeojsonLoading(true);
      const response = await axios.get(
        `https://3mpqory1se.execute-api.ap-south-1.amazonaws.com/prod/getCropMonitorData?mobile=${mobileNumber}&farm_name=${farm_name}`
      );

      const geojson = response.data.result;
      setGeojsonData(geojson.data);
      setHealthScore(geojson.Health_score);
      setHealthCategory(geojson.Health_category);
      setmulti_index_stress_results(geojson.multi_index_stress_results);
      

      // Create S3 bucket URL for the current layer
      if (geojson.data[currentLayer]) {
        await createBucket(geojson.data[currentLayer]);
      }
    } catch (error) {
      console.error("Error fetching GeoJSON data:", error);
    } finally {
      setGeojsonLoading(false); // End GeoJSON loading
    }
  };

  // Function to fetch LSTM data
  const fetchLstmData = async () => {
    try {
      setLstmLoading(true);
      const response = await axios.get(
        `https://3mpqory1se.execute-api.ap-south-1.amazonaws.com/prod/getLstmData?mobile=${mobileNumber}&farm_name=${farm_name}`
      );

      setLstmData(response.data.body[0]);
      const firstCoordinate = response.data.body[0]["co-ordinates"][0];
      setFarmCoordinates(firstCoordinate);
    } catch (error) {
      console.error("Error fetching LSTM data:", error);
    } finally {
      setLstmLoading(false); // End LSTM loading
    }
  };

  const fetchSoildata = async () => {
    try {
      setSoilLoading(true);
      const response = await axios.get(
        `https://3mpqory1se.execute-api.ap-south-1.amazonaws.com/prod/getNpk?mobile=${mobileNumber}&farm_name=${farm_name}`
      );

      setSoilData(response.data.body);
    } catch (error) {
      console.error("Error fetching soil data:", error);
    } finally {
      setSoilLoading(false); // End LSTM loading
    }
  };

  // Fetch GeoJSON data separately
  useEffect(() => {
    fetchGeojsonData();
  }, [mobile, farm_name, currentLayer]);

  // Fetch LSTM data separately
  useEffect(() => {
    fetchLstmData();
  
  }, [mobile, farm_name]);

  useEffect(() => {
    fetchSoildata();
  }, [mobile, farm_name, currentLayer]);

  // Handle changing the map layer and update bucket URL
  const handleLayerChange = async (layerKey) => {
    setCurrentLayer(layerKey);
    if (geojsonData[layerKey]) {
      await createBucket(geojsonData[layerKey]);
    }
  };

  return (
    <NewFarm
      mobile={mobile}
      farm_name={farm_name}
      farmInfoUrl={farmInfoUrl}
      geojsonData={geojsonData}
      currentLayer={currentLayer}
      onLayerChange={handleLayerChange}
      mobileNumber={mobileNumber}
      farmCoordinates={farmCoordinates}
      healthScore={healthScore}
      healthCategory={healthCategory}
      lstmData={lstmData}
      geojsonLoading={geojsonLoading}
      lstmLoading={lstmLoading}
      soilLoading={soilLoading}
      soilData={soilData}
      multi_index_stress_results={multi_index_stress_results}
    />
  );
};

export default Dashboard;