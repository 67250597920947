import React from "react";

const Legend = (props) => {
  var scale = 1;
  var add = 0;
  if (props.active.property == "nitrogen") {
    scale = 390;
  }
  if (props.active.property == "potassium") {
    scale = 450;
  }
  if (props.active.property == "phosphorus") {
    scale = 20;
  }
  if (props.active.property == "smi_nirs") {
    scale = 40;
    add = 1;
  }
  if (props.active.property == "soil_ph") {
    scale = 1;
    add = 1;
  }
  if (props.active.property == "crop_health") {
    scale = 1;
    add = 1;
  }

  const renderLegendKeys = (stop, i) => {
    return (
      <div
        key={i}
        className="txt-s"
        style={{
          flexDirection: "column",
          textAlign: "left",
          marginLeft: "40px",
        }}
      >
        <span
          className="mr6 round-full w12 h12 inline-block align-middle"
          style={{ backgroundColor: stop[1] }}
        />
        <span>{`${scale * (stop[0] + add)}`}</span>
      </div>
    );
  };
  function formatDate(inputDate) {
    const parts = inputDate.split("-");
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return formattedDate;
  }


  return (
    <>
      <div
        className="bg-white absolute bottom right mr10 mb1 py10 px11 shadow-darken10 round z1 wmax180"
        style={{ width: `20%`, height: "75%" }}
      >
        <div>
          <h2 className="txt-bold txt-s" style={{ textAlign: "center" }}>
            {props.active.description}
            <br />
            {props.meanValues[props.active.property + ""]}
          </h2>
          <div
          // style={{
          //   marginTop: "10px",
          //   marginLeft: "35px",
          // }}
          >
            {props.stops.map(renderLegendKeys)}
          </div>
          <br />
          <p
            className="txt-bold txt-s color-dark m-auto"
            style={{ textAlign: "center" }}
          >
            Updated<br></br>
            {props.lastUpdated ? formatDate(props.lastUpdated) : ""}
          </p>
          {/* <p className="txt-s color-gray">{props.active.description}</p> */}
         
        </div>

        

        <div
          className="txt-bold txt-s color-dark m-auto"
          style={{ textAlign: "center" }}
        >
          {props.active.description === "Crop Health" ? (
            <div
              className="txt-bold txt-s color-dark m-auto"
              style={{ textAlign: "left",marginLeft:"5px" }}
            >
              <p>1 = No Crop</p>
              <p>2 = Bad Health</p>
              <p>3 = Moderate Health</p>
              <p>4 = Health Crop</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Legend;