import * as React from 'react';
import { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';


export default function HarvestTable({ filteroutData = [] }) {
  
  const [selected, setSelected] = React.useState([]);
  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [harvestWindow, setHarvestWindow] = useState("");

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteroutData.map((farm) => farm._id.$oid);
      setSelected(newSelected);
      setIsAllSelected(true);
      return;
    }
    setSelected([]);
    setIsAllSelected(false);
  };
  useEffect(()=>{
    if (filteroutData && filteroutData.length > 0) {
      const harvestDates = filteroutData
        .map((farm) => (farm.harvest_date ? new Date(farm.harvest_date) : null))
        .filter((date) => date && !isNaN(date))
        .sort((a, b) => a - b);
        
      if (harvestDates.length > 0) {
        const startDate = harvestDates[0].toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        const endDate = harvestDates[harvestDates.length - 1].toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        setHarvestWindow(`${startDate} - ${endDate}`);
      } else {
        setHarvestWindow("N/A");
      }
    }else {
      setHarvestWindow("N/A");
    }
  },[filteroutData])
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    setIsAllSelected(newSelected.length === filteroutData.length);
  };

  return (
    <Box sx={{ width: '100%' ,paddingLeft:'35px'}}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor:'#224831',
          borderRadius: '10px',
          
          
        }}
      >
        <Toolbar>
          <Typography variant='h6' sx={{marginLeft:'-10px',color:'#ffffff'}}>Harvest Slot: {harvestWindow || ''}</Typography>
        </Toolbar>
        <TableContainer
          sx={{
            maxHeight: 500,
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(252, 175, 3, 0.5)',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: 'rgba(252, 175, 3, 0.8)',
            },
          }}
        >
          <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                
                <TableCell align="left" sx={{ color: '#fcaf03', fontSize: '1.2rem',backgroundColor:'#224831' }}>
                  Farm Name
                </TableCell>
                <TableCell align="left" sx={{ color: '#fcaf03', fontSize: '1.2rem',backgroundColor:'#224831' }}>
                  Harvest Window
                </TableCell>
                <TableCell align="left" sx={{ color: '#fcaf03', fontSize: '1.2rem',backgroundColor:'#224831' }}>
                  Quantity
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteroutData.map((farm) => (
                <TableRow hover key={farm._id.$oid}>
                 
                  <TableCell sx={{ color: 'white' }}>{farm.farm_name || ''}</TableCell>
                  <TableCell sx={{ color: 'white' }}>{farm.harvest_interval || ''}</TableCell>
                  <TableCell sx={{ color: 'white' }}>{Number(farm.crop_yield || 0).toFixed(2)} Tonnes</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
