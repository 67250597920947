import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { Card, CardContent, CardHeader, Grid, Typography, Box } from '@mui/material';
import useStyles from './Style';
import Map from '../components/MapOverview';

const COLORS = ['#00C49F', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Pagetwo = ({ filteroutData , selectedCrop,piechartdata }) => {
  
  const classes = useStyles();
  const [totalYield, setTotalYield] = useState(0);
  const [harvestWindow, setHarvestWindow] = useState("");

  useEffect(() => {
    if (filteroutData && filteroutData.length > 0) {
      const calculatedTotalYield = filteroutData.reduce(
        (sum, farm) => sum + (Number(farm.total_yield) || 0),
        0
      );
      setTotalYield(calculatedTotalYield);

      const harvestDates = filteroutData
        .map((farm) => (farm.harvest_date ? new Date(farm.harvest_date) : null))
        .filter((date) => date && !isNaN(date))
        .sort((a, b) => a - b);
        
      if (harvestDates.length > 0) {
        const startDate = harvestDates[0].toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        const endDate = harvestDates[harvestDates.length - 1].toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        setHarvestWindow(`${startDate} - ${endDate}`);
      } else {
        setHarvestWindow("N/A");
      }
    } else {
      setTotalYield(0);
      setHarvestWindow("N/A");
    }
  }, [filteroutData]);

  return (
    <Box className={classes.container} md={12} sx={{ display: 'flex', color: '#000000' }}>
      <Grid container md={6} spacing={2} sx={{ marginTop: '15px' }}>
        
        {/* Yield Prediction */}
        <Grid item sx={{paddingTop:'0px'}}>
          <Card sx={{ display: 'flex',   flexDirection: 'column', width: '250px', height: '250px', marginLeft: '35px', borderRadius: "10px",
                  backgroundColor: "#224831"
                  }}>
            <CardHeader title={<Typography variant='h5' sx={{ color: '#fcaf03' }}>Yield Prediction</Typography>} />
            <CardContent>
              <Typography variant='h4' color={'#ffffff'} sx={{ marginTop:'25px'}}>{totalYield.toFixed(2) || ''} Tonnes</Typography>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Harvest Window */}
        <Grid item>
          <Card sx={{ display: 'flex', flexDirection: 'column', width: '250px', height: '250px', marginLeft: '15px',borderRadius: "10px",
                  backgroundColor: "#224831",
                 }}>
            <CardHeader title={<Typography variant='h5' sx={{ color: '#fcaf03' }}>Number of Farms</Typography>} />
            <CardContent>
              <Typography variant='h4' color={'#ffffff'} sx={{ marginTop:'25px'}}>{filteroutData.length}</Typography>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Crop Health */}
        <Grid item>
          <Card sx={{ display: 'flex', justifyContent: 'center',  flexDirection: 'column', width: '250px', height: '250px', marginLeft: '35px',borderRadius: "10px",
                  backgroundColor: "#224831",
                  marginTop: '75px',  }}>
            <CardHeader title={<Typography variant='h5' sx={{ color: '#fcaf03' }}>Crop Health</Typography>} />
            <CardContent sx={{marginLeft:'30px'}}>
              <PieChart width={150} height={150}>
                <Tooltip />
                <Pie
                  data={piechartdata}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={70}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {piechartdata.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Income Estimation */}
        <Grid item>
          <Card sx={{ display: 'flex', flexDirection: 'column', width: '250px', height: '250px', marginLeft: '15px',borderRadius: "10px",
                  backgroundColor: "#224831",
                  marginTop: '75px' }}>
            <CardHeader title={<Typography variant='h5' sx={{ color: '#fcaf03' ,}}>Crop</Typography>} />
            <CardContent>
              <Typography variant='h4' color={'#ffffff'} sx={{ marginTop:'25px'}}>{selectedCrop}</Typography>
            </CardContent>
          </Card>
        </Grid>
        
      </Grid>

      <Grid
     container md={6} spacing={2} sx={{ marginTop:'25px' }}
    >
      
        <Map FarmerData={filteroutData}/>
       
      </Grid>
      
    </Box>
  );
};

export default Pagetwo;
