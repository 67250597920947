import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import Legend from "../components/Legend";
import Optionsfield from "../components/Optionsfield";
import "./Map.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_API;

const Mapcopy = (props) => {
  const stop_points = [
    [100, "#f8d5cc"],
    [130, "#f4bfb6"],
    [160, "#f1a8a5"],
    [180, "#ee8f9a"],
    [220, "#ec739b"],
    [250, "#dd5ca8"],
    [280, "#c44cc0"],
    [310, "#9f43d7"],
    [350, "#6e40e6"],
  ];
  const options = [
    {
      name: "Nitrogen (kg/ha)",
      description: "Nitrogen (kg/ha)",
      property: "nitrogen",
      index: 0,
      stops: [
        [0, "#f8d5cc"],
        [0.3, "#f4bfb6"],
        [0.6, "#f1a8a5"],
        [0.8, "#ee8f9a"],
        [1.2, "#ec739b"],
        [1.5, "#dd5ca8"],
        [1.8, "#c44cc0"],
        [2.1, "#9f43d7"],
        [2.5, "#6e40e6"],
      ],
    },
    {
      name: "Potassium (kg/ha)",
      description: "Potassium (kg/ha)",
      property: "potassium",
      index: 1,
      stops: [
        [0, "#f8d5cc"],
        [0.3, "#f4bfb6"],
        [0.6, "#f1a8a5"],
        [0.8, "#ee8f9a"],
        [1.2, "#ec739b"],
        [1.5, "#dd5ca8"],
        [1.8, "#c44cc0"],
        [2.1, "#9f43d7"],
        [2.5, "#6e40e6"],
      ],
    },
    {
      name: "Phosphorus (kg/ha)",
      description: "Phosphorus (kg/ha)",
      property: "phosphorus",
      index: 2,
      stops: [
        [0, "#f8d5cc"],
        [0.3, "#f4bfb6"],
        [0.6, "#f1a8a5"],
        [0.8, "#ee8f9a"],
        [1.2, "#ec739b"],
        [1.5, "#dd5ca8"],
        [1.8, "#c44cc0"],
        [2.1, "#9f43d7"],
        [2.5, "#6e40e6"],
      ],
    },
    {
      name: "Soil pH",
      description: "Soil pH",
      index: 3,
      property: "soil_ph",
      stops: [
        [0, "#f8d5cc"],
        [5, "#f4bfb6"],
        [6, "#f1a8a5"],
        [7, "#ee8f9a"],
        [8, "#ec739b"],
        [8.5, "#dd5ca8"],
        [9, "#c44cc0"],
        [9.5, "#9f43d7"],
        [10, "#6e40e6"],
      ],
    },
    {
      name: "Organic Content %",
      description: "Organic Content %",
      index: 4,
      property: "oc",
      stops: [
        [0, "#f8d5cc"],
        [0.3, "#f4bfb6"],
        [0.6, "#f1a8a5"],
        [0.8, "#ee8f9a"],
        [1.2, "#ec739b"],
        [1.5, "#dd5ca8"],
        [1.8, "#c44cc0"],
        [2.1, "#9f43d7"],
        [2.5, "#6e40e6"],
      ],
    },
    {
      name: "Crop Health",
      description: "Crop Health",
      index: 5,
      property: "crop_health",
      stops: [
        [0, "#f8d5cc"],
        [0.3, "#f4bfb6"],
        [0.6, "#f1a8a5"],
        [0.8, "#ee8f9a"],
        [1, "#ec739b"],
        [1.5, "#dd5ca8"],
        [2, "#c44cc0"],
        [2.5, "#9f43d7"],
        [3, "#6e40e6"],
      ],
    },
  ];
  const mapContainerRef = useRef(null);
  const [active, setActive] = useState(options[0]);
  const [map, setMap] = useState(null);
  const [url, setUrl] = useState(props.url);
  const [bounds, setBounds] = useState(props.bounds);
  const [farm_info_last_updated, setLastUpdated] = useState(
    props.farmInfoLastUpdated
  );
  const [meanValues, setMeanValues] = useState(props.meanValues);

  const initalCord = [(bounds[0] + bounds[2]) / 2, (bounds[1] + bounds[3]) / 2];

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/satellite-v9",
      center: initalCord,
      zoom: 8, // Change the zoom level to your desired value, e.g., 10
      attributionControl: false,
      // maxZoom: 16
    });

    map.on("load", () => {
      map.addSource("countries", {
        type: "geojson",
        data: props.url,
      });

      map.addLayer({
        id: "countries",
        type: "fill",
        source: "countries",
      });

      map.fitBounds([
        [props.bounds[0] - 0.0003, props.bounds[1] - 0.0002],
        [props.bounds[2] + 0.0008, props.bounds[3] + 0.0008],
      ]);

      setMap(map);

      setActive(options[0]);
      map.setPaintProperty("countries", "fill-color", {
        property: active.property,
        stops: active.stops,
      });
      changestops();
    });

    return () => map.remove();
  }, [url]);

  useEffect(() => {
    if (map) {
      map.setPaintProperty("countries", "fill-color", {
        property: active.property,
        stops: active.stops,
      });
    }
  }, [active, map]);

  useEffect(() => {
    setUrl(props.url);
  }, [props.url]);

  useEffect(() => {
    setLastUpdated(props.farmInfoLastUpdated);
  }, [props.farmInfoLastUpdated]);

  useEffect(() => {
    setMeanValues(props.meanValues);
  }, [props.meanValues]);

  useEffect(() => {
    setBounds(props.bounds);
  }, [props.bounds]);

  let stop_p = {};

  const changeState = (i) => {
    setActive(options[i]);
    changestops();
  };

  const changestops = () => {
    if (active.property === "nitrogen") {
      stop_p = stop_points;
    } else {
      stop_p = active.stops;
    }
  };

  return (
    <div
      ref={mapContainerRef}
      className="map-container"
      style={{ width: "100%", height: "456px", borderRadius: "10px" }}
    >
      <Legend
        active={active}
        stops={active.stops}
        lastUpdated={props.sowingData}
        meanValues={meanValues}
      />
      <Optionsfield
        options={options}
        property={active.property}
        changeState={changeState}
      />
    </div>
  );
};

export default Mapcopy;
