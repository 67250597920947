import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Card,
  Grid,
} from "@mui/material";

const getRecommendation = (stressType, stressPercentage) => {
  const recommendations = {
    "Disease/Pest Stress": [
      {
        max: 20,
        stress: "Disease/Pest Infestation",
        reason: "Low-level infestation.",
        solution: "No immediate action needed; monitor periodically.",
      },
      {
        max: 30,
        stress: "Disease/Pest Infestation",
        reason: "Mild infestation detected.",
        solution: "Apply a light pesticide or preventive nutrient supplements.",
      },
      {
        max: 40,
        stress: "Disease/Pest Infestation",
        reason: "Moderate infestation identified.",
        solution:
          "Increase pesticide dose; consider Integrated Pest Management (IPM) techniques.",
      },
      {
        max: Infinity,
        stress: "Disease/Pest Infestation",
        reason: "High infestation level.",
        solution:
          "Perform extensive pesticide spray; consult a pest management specialist.",
      },
    ],
    "Water Stress": [
      {
        max: 20,
        stress: "Water Deficit",
        reason: "Slight moisture deficiency.",
        solution: "Ensure irrigation schedules are consistent.",
      },
      {
        max: 30,
        stress: "Water Deficit",
        reason: "Mild water stress.",
        solution:
          "Increase irrigation frequency and consider soil conditioners.",
      },
      {
        max: 40,
        stress: "Water Deficit",
        reason: "Moderate water stress.",
        solution: "Increase water supply and consider mulching.",
      },
      {
        max: Infinity,
        stress: "Water Deficit",
        reason: "Severe water stress.",
        solution:
          "Immediate irrigation needed; inspect water distribution systems.",
      },
    ],
    "Over-Irrigation": [
      {
        max: 20,
        stress: "Over-Irrigation",
        reason: "Slight over-watering.",
        solution: "Monitor for early signs of fungal growth.",
      },
      {
        max: 30,
        stress: "Over-Irrigation",
        reason: "Mild over-watering detected.",
        solution: "Reduce watering frequency and consider drainage solutions.",
      },
      {
        max: 40,
        stress: "Over-Irrigation",
        reason: "Moderate over-watering.",
        solution: "Reduce watering, monitor for root rot, and aerate soil.",
      },
      {
        max: Infinity,
        stress: "Over-Irrigation",
        reason: "Severe over-watering.",
        solution:
          "Improve drainage, stop irrigation temporarily, and monitor carefully.",
      },
    ],
    "Nutrient Deficiency": [
      {
        max: 20,
        stress: "Nutrient Deficiency",
        reason: "Low nutrient deficiency level.",
        solution: "Perform periodic soil testing.",
      },
      {
        max: 30,
        stress: "Nutrient Deficiency",
        reason: "Mild deficiency detected.",
        solution:
          "Apply balanced nutrient supplements focused on macronutrients.",
      },
      {
        max: 40,
        stress: "Nutrient Deficiency",
        reason: "Moderate deficiency present.",
        solution: "Use targeted fertilizers; consider foliar application.",
      },
      {
        max: Infinity,
        stress: "Nutrient Deficiency",
        reason: "Severe deficiency.",
        solution:
          "Apply high doses of essential nutrients; conduct soil and tissue testing.",
      },
    ],
    "General Vegetation Stress": [
      {
        max: 20,
        stress: "General Vegetation Stress",
        reason:
          "Low overall stress, possibly minor environmental fluctuations.",
        solution: "Continue monitoring; no action needed.",
      },
      {
        max: 30,
        stress: "General Vegetation Stress",
        reason:
          "Mild vegetation stress, likely due to minor weather changes or early signs of abiotic factors.",
        solution:
          "Apply general-purpose foliar sprays to strengthen plants against possible stressors.",
      },
      {
        max: 40,
        stress: "General Vegetation Stress",
        reason:
          "Moderate vegetation stress, potentially due to recent weather impacts, nutrient imbalance, or abiotic stress like soil compaction.",
        solution:
          "Assess for multiple potential causes such as weather patterns, soil conditions, and nutrient levels; apply targeted interventions as needed.",
      },
      {
        max: Infinity,
        stress: "General Vegetation Stress",
        reason:
          "High vegetation stress, possibly a combination of severe weather, nutrient deficiencies, or biotic factors like pests and disease.",
        solution:
          "Comprehensive intervention required; consider pest control, adjusting irrigation, applying nutrients, and monitoring weather-related conditions closely.",
      },
    ],
  };

  const defaultRecommendation = {
    stress: "Healthy Condition",
    reason: "No stress indicators.",
    solution: "Maintain regular monitoring.",
  };

  const stressData = recommendations[stressType];
  if (stressData) {
    const recommendation = stressData.find(
      (entry) => stressPercentage < entry.max
    );
    if (recommendation) {
      return {
        stress: recommendation.stress,
        reason: recommendation.reason,
        solution: recommendation.solution,
      };
    }
  }

  return defaultRecommendation;
};

const StressTable = ({ multi_index_stress_results }) => {
  // Default to an empty array if multi_index_stress_results is not provided
  const multiIndexStressResults = multi_index_stress_results || [];

  // Extract the latest stress data if available
  const latestStress = multiIndexStressResults[0];

  // Generate a general recommendation for the latest stress
  const recommendation = latestStress
    ? getRecommendation(latestStress.stress_type, latestStress.stress_percentage)
    : "No stress data available. Continue regular monitoring.";

  return (
    <Box sx={{ width: "100%", fontFamily: "open sans" }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ marginRight: "10px" }}>
          <Paper
            sx={{
              width: "100%",
              mb: 2,
              backdropFilter: "blur(5px)",
              borderRadius: "10px",
              backgroundColor: "#224831",
            }}
          >
            <Toolbar>
              <Typography
                sx={{ color: "#fcaf03", fontWeight: "bold" }}
                variant="h5"
                component="div"
              >
                Crop Stress (Water/Nutrient/Disease and Pest Stress)
              </Typography>
            </Toolbar>
            <TableContainer
              sx={{
                maxHeight: 500,
                overflowX: "hidden",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#fcaf03",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#fcaf03",
                },
              }}
            >
              <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <TableHead
                  sx={{
                    border: "0px solid #000000",
                    
                    top: 0,
                    backgroundColor: "#224831",
                  }}
                >
                  <TableRow
                    sx={{
                      border: "0px solid #000000",
                      
                      top: 0,
                      backgroundColor: "#224831",
                    }}
                  >
                    <TableCell
                      // align="center"
                      sx={{
                        color: "#fcaf03",
                        fontSize: "1.2rem",
                        border: "1px solid #ffffff",
                        backgroundColor:'#224831'
                      }}
                    >
                      Date Range
                    </TableCell>
                    <TableCell
                      // align="center"
                      sx={{
                        color: "#fcaf03",
                        fontSize: "1.2rem",
                        border: "1px solid #ffffff",
                        backgroundColor:'#224831'
                      }}
                    >
                      Stress Type
                    </TableCell>
                    <TableCell
                      // align="center"
                      sx={{
                        color: "#fcaf03",
                        fontSize: "1.2rem",
                        border: "1px solid #ffffff",
                        backgroundColor:'#224831'
                      }}
                    >
                      Stress Percentage
                    </TableCell>
                    <TableCell
                      // align="center"
                      sx={{
                        color: "#fcaf03",
                        fontSize: "1.2rem",
                        border: "1px solid #ffffff",
                        backgroundColor:'#224831'
                      }}
                    >
                      Recommendation
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {multiIndexStressResults.length > 0 ? (
                    multiIndexStressResults.map((row, index) => {
                      // Generate the recommendation for the specific row
                      const rowRecommendation = getRecommendation(
                        row.stress_type,
                        row.stress_percentage
                      );

                      return (
                        <TableRow
                          hover
                          key={index}
                          sx={{ border: "1px solid #ffffff" }}
                        >
                          <TableCell
                            // align="center"
                            sx={{ color: "white", border: "1px solid #ffffff" }}
                          >
                            {row.timestamp}
                          </TableCell>
                          <TableCell
                            // align="center"
                            sx={{ color: "white", border: "1px solid #ffffff" }}
                          >
                            {row.stress_type}
                          </TableCell>
                          <TableCell
                            // align="center"
                            sx={{ color: "white", border: "1px solid #ffffff" }}
                          >
                            {row.stress_percentage.toFixed(2)}%
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ color: "white", border: "1px solid #ffffff" }}
                          >
                            {rowRecommendation.stress}: {rowRecommendation.reason} <br />
                            {rowRecommendation.solution}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        align="center"
                        sx={{ color: "white" }}
                      >
                        No stress data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "10px",
              backgroundColor: "#224831",
              width: "100%",
              textAlign: "left",
              p: 2,
            }}
          >
            <Typography
              variant="h6"
              color="#fcaf03"
              sx={{ padding: "10px", paddingBottom: "0px" }}
            >
              Latest Recommendation:
            </Typography>
            <Typography
              variant="body1"
              style={{
                color: "#ffffff",
                padding: "10px",
                margin: 0,
                paddingTop: "0px",
              }}
            >
              Stress: {recommendation.stress} <br />
              Reason: {recommendation.reason}
              <br />
              Solution: {recommendation.solution}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};


export default StressTable;
