import React from "react";
import { Box, Typography,Button } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";


const NewUser=()=>{
  const navigate = useNavigate()
    return(
        <Box sx={{height:'100vh',width:'100vw' , display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', alignContent:'center', justifyItems:'center', backgroundColor: "rgba(34, 72, 49, 0.9)",
          padding: "0px",
          margin: "0px",}}>
          <Typography variant="h3" color="#fcaf03" sx={{textAlign:'center'}}>
          Welcome to ELAI’s Dashboard!
          </Typography>
            <Typography variant="h5" color="#ffffff" sx={{textAlign:'center'}}>
          
            Unlock real-time insights into crop health, yield predictions, and harvest trends to optimize your exports. <br/>Start making data-driven decisions for smarter sourcing and better profitability. <br/>
            Let’s get your farming operations on track for success
            </Typography>
            <Button
             
              variant="contained"
              startIcon={<AddOutlinedIcon sx={{ color: "#ffffff" }} />}
              sx={{
                marginTop:'25px',
                borderRadius: "10px",
                padding: "12px 14px",
                bgcolor: "#fcaf03",
                "&:hover": {
                  bgcolor: "#224831",
                  color:'#fcaf03'
                },
                color:'#224831'
              }}
              onClick={()=>navigate("/map")}
            >
              Add your first Farm
            </Button>
        </Box>
    )
}

export default NewUser;