import React, { lazy, Suspense, useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// import { createTheme, ThemeProvider } from "@mui/material
import elailogo from "../assets/logo/ELAI LOGOS.png";
import Typography from "@mui/material/Typography";
import Elailogo from "../assets/img/Elailogo.png";
import Signin from "./Signin";
// import Signup from "./Signup";
import useStyles from "./Style";
import "../i18n";
import { useTranslation } from "react-i18next";
const Signup = lazy(() => import("./Signup"));

function Login() {
  const classes = useStyles();
  const [signupFlag, setSignupFlag] = useState(false);

  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Container disableGutters>
        <CssBaseline />
        <Grid container spacing={6}>
          <Grid item xs={4} sm={6} md={8} sx={{display:'flex', justifyContent:'center', alignItems:'center'}} >
            <Box className={classes.box}>
              <img className={classes.logo} src={Elailogo} alt="Elai"/>
              <Typography
                className={classes.typo}
                sx={{ fontSize: { xs: "none", md: "2.7rem", sm: "1.7rem" } }}
              >
                {t("signup Message")}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={8} sm={6} md={4}>
            <Suspense fallback={<div>Loading...</div>}>
              {signupFlag ? (
                <Signup setSignupFlag={setSignupFlag} />
              ) : (
                <Signin setSignupFlag={setSignupFlag} />
              )}
            </Suspense>
          </Grid>
        </Grid>

        {/* </ThemeProvider> */}
      </Container>
    </Box>
  );
}

export default Login;
