import React, { useContext, useEffect, lazy, Suspense, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FarmContext } from "../contextAPI/FarmContext";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Alert,
  Select,
  OutlinedInput,
  Stack,
  Grid2,
} from "@mui/material";
import { ReactComponent as FarmInfo } from "../assets/img/FarmInfo.svg";
import { ReactComponent as Cropdata } from "../assets/img/Cropdata.svg";
import { ReactComponent as Crophealth } from "../assets/img/Crophealth.svg";
import { ReactComponent as Soilhealth } from "../assets/img/Soilhealth.svg";
import { ReactComponent as Income } from "../assets/img/Income.svg";

import weather from "../assets/img/weather.svg";
import LoadingSmallAnimation from "../utility/Loadingsmallanimation";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

// import ChartComponentForgeojson from "./charts/GeoJsonChart";
import ChartComponentForgeojson2 from "./charts/GeoJsonChart2";
import MandiCard from "./MandiCard";
import EditCropModel from "./EditCropModel";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
// import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import axios from "axios";
import waitingVid from "../assets/img/waiting.mp4";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { convertToDateFormat } from "../utility/Utility";
import "../i18n";
import { useTranslation } from "react-i18next";

const HeatMap = lazy(() => import("./maps/HeatMap"));
const StressTable = lazy(() => import("./StressTable"));
const SoilHealthCard = lazy(() => import("./SoilHealthCard"));
const WeatherChart = lazy(() => import("./charts/Weather"));
const InfoCard = lazy(() => import("./InfoCard"));

const AWS_URL = process.env.REACT_APP_AWS_LAMBDA_URL;
const AWS_URL3 = process.env.REACT_APP_AWS_LAMBDA_URL3;
const Dashboard = () => {
  const { mobile, farm_name } = useParams();
  const [selectCrop, setSelectCrop] = useState(false);
  const [crop, setCrop] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [seasonedFarms, setSeasonedFarms] = useState([]);
  const { t } = useTranslation();
  const crops = [
    "Rice",
    "Wheat",
    "Maize",
    "Cotton",
    "Mustard",
    "White_Onion",
    "Soyabean",
    "Potato",
    "Gram",
  ];

  // const [ editCropModel, setEditCropModel ] = useState(false);
  // const { fetchBulkUploadData } = useAuth();

  useEffect(() => {
    fetchGeojsonData(mobile, farm_name);
    // fetchLstmData(mobile, farm_name);
    fetchSoilData(mobile, farm_name);
    // fetchBulkUploadData(mobile);
    // fetchMandiData();
    fetchSeasonedFarms(mobile, farm_name);
  }, [mobile, farm_name]);

  const fetchSeasonedFarms = async (mobile) => {
    try {
      const response = await axios.post(
        `${AWS_URL}fetchSeasonedFarms`,
        {
          mobile: mobile,
          farm_name: farm_name,
        },
        {
          headers: {
            "Content-Type": "application/json", // Ensure the correct content type is set
          },
        }
      );
      // debugger
      if (response.data.body?.crop_monitor_data?.length > 0) {
        setSeasonedFarms(response.data.body?.crop_monitor_data);
      }
    } catch (error) {
      console.warn("Error fetching seasoned data:", error);
    }
  };

  const downloadPDF = () => {
    const dashboardElement = document.getElementById("dashboard-content");

    // Use html2canvas to capture the dashboard content
    html2canvas(dashboardElement, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const imgWidth = pdfWidth;
      const imgHeight = (canvasHeight * imgWidth) / canvasWidth;

      let heightLeft = imgHeight;
      let position = 0;

      // Add the image to the PDF and handle page breaks
      while (heightLeft > 0) {
        pdf.addImage(
          imgData,
          "PNG",
          0,
          position,
          imgWidth,
          Math.min(imgHeight, pdfHeight) // Add only the portion that fits the page
        );
        heightLeft -= pdfHeight; // Reduce the remaining height
        position -= pdfHeight; // Adjust the position for the next page
        if (heightLeft > 0) {
          pdf.addPage(); // Add a new page if there's more content
        }
      }

      pdf.save(`dashboard_${mobile}_${farm_name}.pdf`);
    });
  };

  const {
    geojsonData,
    farmCoordinates,
    // geojsonData.health_category,
    geojsonLoading,
    soilData,
    multiIndexStressResults,
    fetchGeojsonData,
    fetchSoilData,
  } = useContext(FarmContext);

  // const convertToDateFormat = (dateString) => {
  //   if (!dateString) return "N/A";
  //   const date = new Date(dateString);
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const year = date.getFullYear();
  //   return `${day}/${month}/${year}`;
  // };

  const handleChange = (event) => {
    const userConfirmed = window.confirm(
      `Are you sure you want to edit the crop as ${event.target.value}?`
    );

    if (userConfirmed) {
      setCrop(event.target.value || "");
      setSelectCrop((prevState) => !prevState);
      setEditCrop(event.target.value);
      navigate(`/dashboard/${mobile}/${farm_name}`);
    } else {
      setSelectCrop((prevState) => !prevState);
    }
  };

  const setEditCrop = async (crop) => {
    setShowSuccessAlert(true);
    try {
      const response = await axios.post(
        `${AWS_URL3}setEditCrop_Prod`,
        {
          mobile: mobile,
          farm_name: farm_name,
          crop: crop,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // const data = JSON.parse(response.data.response);
      setShowSuccessAlert(false);
      alert(
        "Crop Updated Successfully, Please reload the page to get the changes"
      );
      navigate(`/dashboard/${mobile}/${farm_name}`);
    } catch (error) {
      if (error.response.status === 504) {
        setShowSuccessAlert(false);
        alert(
          "Crop Updated Successfully, Please reload the page to get the changes"
        );
        navigate(`/dashboard/${mobile}/${farm_name}`);
      } else console.log("Error saving crop edit");
    }
  };

  const openEdit = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    document.body.style.overflow = "unset";
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: "10px",
        // gap: "5px",
        minheight: "100vh",
        // background: "#224831",
      }}
    >
      {!showSuccessAlert && (
        <Box id="dashboard-content">
          {geojsonLoading ? (
            //  {lstmLoading ? (
            <Typography
              variant="h5"
              color="#ff6600"
              sx={{ textAlign: "center" }}
            >
              Please wait, data is loading...
              <LoadingSmallAnimation />
            </Typography>
          ) : (
            <Box>
              <Box
                sx={{
                  background: "#ffffff",
                  padding: "10px 20px 20px 20px",
                  marginTop: "90px",
                  borderRadius: "10px",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <FarmInfo
                    style={{
                      height: "75px",
                      width: "50px",
                      mt: "5px",
                      // mb: 2,
                      paddingBottom: "10px",
                    }}
                  />
                  <Typography
                    sx={{
                      flex: 1,
                      textAlign: "left",
                      marginLeft: "10px",
                      fontSize: "h4.fontSize",
                      color: "#224831",
                      mt: "5px",
                      // mb: 2,
                      fontWeight: "bold",
                      textUnderlineOffset: "6px",
                      paddingBottom: "10px",
                      fontFamily: "open sans",
                    }}
                  >
                    {t("Farm Info")}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={downloadPDF}
                    sx={{
                      height: "50px",
                      color: "#224831",
                      borderColor: "#224831",
                      borderRadius: "10px",
                      "&:hover": { background: "#fcaf03" },
                    }}
                  >
                    {/* Download PDF */}
                    <GetAppOutlinedIcon />
                  </Button>
                </Box>

                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Suspense fallback={<div>loading...</div>}>
                    <Grid item xs={3}>
                      <InfoCard
                        title={t("Mobile")}
                        content={mobile || t("N/A")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InfoCard
                        title={t("Farm Name")}
                        content={farm_name || t("N/A")}
                      />
                    </Grid>
                    {/* <Grid item xs={2.4}>
                      <InfoCard
                        title="Health Score"
                        content={
                          geojsonData.healthScore ? Number(geojsonData.healthScore).toFixed(2) : "N/A"
                        }
                      />
                    </Grid> */}
                    <Grid item xs={3}>
                      <InfoCard
                        title={t("Health Category")}
                        content={
                          geojsonData?.health_category
                            ? t(geojsonData.health_category)
                            : t("N/A")
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InfoCard
                        title={t("Farm Area")}
                        content={`${
                          geojsonData?.area
                            ? Number(geojsonData.area * 0.404686).toFixed(2)
                            : t("N/A")
                        } ${t("ha")}`}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {/* <Box> */}
                      <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={3}>
                          <Card
                            style={{
                              // margin: "auto",
                              // minWidth: "250px",
                              backgroundColor: "#224831",
                              backdropFilter: "blur(10px)",
                              height: "100px",
                              borderRadius: "10px",
                              width: "100%",
                              fontFamily: "open sans",
                            }}
                          >
                            <CardContent style={{ textAlign: "left" }}>
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  color: "#fcaf03",
                                }}
                                variant="h6"
                              >
                                {t("Location")}
                              </Typography>

                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: "13px",
                                    sm: "12px",
                                    md: "14px",
                                  },
                                  color: "#ffffff",
                                  textAlign: "left",
                                }}
                                // variant="body1"
                              >
                                {farmCoordinates?.latitude
                                  ? `${farmCoordinates?.latitude}° N, ${farmCoordinates?.longitude}° E`
                                  : t("N/A")}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={9}>
                          <Card
                            sx={{
                              // textAlign: "center",
                              backgroundColor: "#224831",
                              backdropFilter: "blur(10px)",
                              height: "100px",
                              borderRadius: "10px",
                              padding: "16px",
                            }}
                          >
                            {/* Additional information about farm status */}
                            <Typography
                              variant="h6"
                              sx={{
                                color: "#fcaf03",
                                marginTop: 0,
                                textAlign: "left",
                                fontFamily: "open sans",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              {t("Farm Status")}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "13px",
                                  sm: "12px",
                                  md: "14px",
                                },
                                color: "#ffffff",
                                fontFamily: "open sans",
                              }}
                            >
                              {geojsonData?.health_category
                                ? geojsonData.health_category === "Good"
                                  ? t("crop_good_performance")
                                  : geojsonData.health_category === "Average"
                                  ? t("crop_average_performance")
                                  : t("crop_poor_performance")
                                : t("N/A")}
                            </Typography>
                          </Card>
                        </Grid>
                      </Grid>
                      {/* </Box> */}
                    </Grid>
                  </Suspense>
                </Grid>
              </Box>
              <Box
                sx={{
                  background: "#ffffff",
                  padding: "10px 20px 20px 20px",
                  marginTop: "20px",

                  borderRadius: "10px",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Cropdata
                    style={{
                      height: "75px",
                      width: "50px",
                      mt: "5px",
                      // mb: 2,
                      paddingBottom: "10px",
                    }}
                  />
                  <Typography
                    sx={{
                      flex: 1,
                      textAlign: "left",
                      marginLeft: "10px",
                      fontSize: "h4.fontSize",
                      color: "#224831",
                      mt: "10px",
                      // mb: 2,
                      fontWeight: "bold",
                      textUnderlineOffset: "6px",
                      // paddingBottom: "10px",
                      fontFamily: "open sans",
                    }}
                  >
                    {t("Crop Data")}
                  </Typography>
                  {/* <EditCropModel mobile={mobile} farm_name={farm_name} seasonedFarms={seasonedFarms} /> */}
                </Box>

                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Suspense fallback={<div>loading...</div>}>
                    <Grid item xs={3}>
                      <InfoCard
                        title={t("Crop")}
                        content={
                          geojsonData?.crop
                            ? t(`${geojsonData?.crop}`)
                            : t("N/A")
                        }
                        editButton="true"
                        onEditClick={() =>
                          setSelectCrop((prevState) => !prevState)
                        }
                      />
                      {selectCrop && (
                        <Select
                          native
                          value={crop}
                          onChange={handleChange}
                          input={
                            <OutlinedInput
                              label="crop"
                              id="demo-dialog-native"
                            />
                          }
                          sx={{
                            height: "30px",
                            color: "#224831",
                          }}
                        >
                          <option
                            key={"null"}
                            style={{ color: "#224831" }}
                            value={""}
                            disabled
                            hidden
                          >
                            {t("Select Crop")}
                          </option>
                          {crops.map((crop) => (
                            <option
                              key={crop}
                              style={{ color: "#224831" }}
                              value={crop}
                            >
                              {t(`${crop}`)}
                            </option>
                          ))}
                        </Select>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <InfoCard
                        title={t("Crop Season")}
                        content={
                          geojsonData?.crop_season
                            ? geojsonData?.crop_season
                            : t("N/A")
                        }
                      />
                    </Grid>
                    {/* {geojsonData?.sowing_date && ( */}
                    <Grid item xs={3}>
                      <InfoCard
                        title={t("Sowing Date")}
                        content={
                          geojsonData?.sowing_date
                            ? convertToDateFormat(geojsonData.sowing_date, t)
                            : t("Too soon to predict")
                        }
                      />
                    </Grid>
                    {/* )} */}
                    {/* {geojsonData?.harvest_date && ( */}
                    <Grid item xs={3}>
                      <InfoCard
                        title={t("Harvest Date")}
                        content={
                          geojsonData?.harvest_date
                            ? convertToDateFormat(geojsonData.harvest_date, t)
                            : t("Too soon to predict")
                        }
                      />
                    </Grid>
                    {/* )} */}
                    {/* {geojsonData?.crop_yield && ( */}
                    <Grid item xs={3}>
                      <InfoCard
                        title={t("Productivity")}
                        content={
                          Number(geojsonData?.crop_yield)
                            ? `${(
                                Number(geojsonData.crop_yield) * 1000
                              ).toFixed(0)} ${t("kg/ha")}`
                            : t("Too soon to predict")
                        }
                      />
                    </Grid>
                    {/* )} */}
                    {/* {geojsonData?.predicted_yield && ( */}
                    <Grid item xs={3}>
                      <InfoCard
                        title={t("Total Yield")}
                        content={
                          geojsonData?.predicted_yield
                            ? `${(
                                Number(geojsonData.predicted_yield) * 1000
                              ).toFixed(0)} ${t("kg")}`
                            : t("Too soon to predict")
                        }
                      />
                    </Grid>
                    {/* )} */}
                  </Suspense>
                  {/* <Grid item md={12}></Grid> */}
                </Grid>
              </Box>
              <Box
                sx={{
                  background: "#ffffff",
                  padding: "20px",
                  marginTop: "20px",
                  borderRadius: "10px",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Income
                    style={{
                      height: "75px",
                      width: "50px",
                      mt: 1,
                      color: "#224831",
                    }}
                  />
                  <Typography
                    sx={{
                      flex: 1,
                      textAlign: "left",
                      marginLeft: "10px",
                      fontSize: "h4.fontSize",
                      color: "#224831",
                      mt: 1,
                      // mb: 2,
                      fontWeight: "bold",
                      textUnderlineOffset: "6px",
                      paddingBottom: "10px",
                      fontFamily: "open sans",
                    }}
                  >
                    {t("Revenue Estimation")}
                  </Typography>
                </Box>
                <MandiCard></MandiCard>
              </Box>

              <Box
                sx={{
                  background: "#ffffff",
                  padding: "20px",
                  marginTop: "20px",

                  borderRadius: "10px",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Soilhealth
                    style={{
                      height: "75px",
                      width: "50px",
                      mt: 1,
                      mb: 2,
                      paddingBottom: "00px",
                      marginLeft: "0px",
                    }}
                  />
                  <Typography
                    sx={{
                      flex: 1,
                      textAlign: "left",
                      marginLeft: "10px",
                      fontSize: "h4.fontSize",
                      color: "#224831",
                      mt: 1,
                      mb: 2,
                      fontWeight: "bold",
                      textUnderlineOffset: "6px",
                      paddingBottom: "0px",
                      fontFamily: "open sans",
                    }}
                  >
                    {t("Soil Health")}
                  </Typography>
                </Box>
                <Suspense fallback={<div> loading...</div>}>
                  <SoilHealthCard
                    crop={
                      geojsonData?.crop ? t(`${geojsonData.crop}`) : t("N/A")
                    }
                    soilData={soilData}
                  />
                </Suspense>
              </Box>
            </Box>
          )}

          {/* Monitoring Data (Map and GeoJSON) */}
          <Box
            sx={{
              background: "#ffffff",
              padding: "20px",
              marginTop: "20px",

              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Crophealth
                style={{
                  height: "75px",
                  width: "50px",
                  mt: 1,
                  mb: 2,
                  paddingBottom: "10px",
                }}
              />
              <Typography
                sx={{
                  flex: 1,
                  textAlign: "left",
                  marginLeft: "10px",
                  fontSize: "h4.fontSize",
                  color: "#224831",
                  mt: 1,
                  // mb: 2,
                  fontWeight: "bold",
                  textUnderlineOffset: "6px",
                  paddingBottom: "10px",
                  fontFamily: "open sans",
                }}
              >
                {t("Crop Health Data")}
              </Typography>
            </Box>
            <Suspense fallback={<div>loading...</div>}>
              <StressTable
                xs={12}
                multi_index_stress_results={multiIndexStressResults}
              />
            </Suspense>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "10px",
                background: "#224831",
                padding: "10px",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#fcaf03",
                    marginLeft: "0px",
                    fontWeight: "bold",
                    fontFamily: "open sans",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("Realtime Monitoring")} {" :"}
                </Typography>
                {!isOpen && (
                  <ArrowDropDownIcon
                    onClick={openEdit}
                    sx={{
                      align: "left",
                      color: "#fcaf03",
                      minHeight: "40px",
                      minWidth: "40px",
                    }}
                    />
                )}
                {isOpen && (
                  <ArrowLeftIcon
                    onClick={openEdit}
                    sx={{
                      color: "#fcaf03",
                      minHeight: "40px",
                      minWidth: "40px",
                    }}
                  />
                )}
              </Box>
              {isOpen && (
              <Box sx={{ backgroundColor: "#224831", padding: "10px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                  <Suspense fallback={<div> loading...</div>}>
                    <HeatMap isOpen={isOpen} />
                  </Suspense>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                  <ChartComponentForgeojson2 isOpen={isOpen} />
                  </Grid>
                </Grid>
              </Box>
              )}
              
            </Box>
          </Box>
          <Box
            sx={{
              background: "#ffffff",
              padding: "20px",
              // marginTop: "20px",
              marginTop: "20px",
              maxWidth: "100%", // Ensure content doesn't overflow
              // overflowX: "hidden", // Prevent horizontal scroll
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                // marginBottom: "16px",
              }}
            >
              <img
                src={weather}
                alt="Weather"
                style={{
                  height: "50px",
                  width: "50px",
                  marginBottom: "5px",
                }}
              />
              <Typography
                sx={{
                  flex: 1,
                  textAlign: "left",
                  marginLeft: "10px",
                  fontSize: "h4.fontSize",
                  color: "#224831",
                  mt: 1,
                  mb: 1,
                  fontWeight: "bold",
                  // textUnderlineOffset: "6px",
                  // paddingBottom: "10px",
                  fontFamily: "open sans",
                }}
              >
                {t("Weather Forecasting Data")}
              </Typography>
            </Box>
            <Card
              sx={{
                background: "#ffffff",
                padding: "00px",
                // marginTop: "20px",
                border: "40px solid #224831",
                borderRadius: "10px",
                // maxHeight: "80vh",
                maxWidth: "100%", // Ensure content doesn't overflow
                // overflowX: "hidden", // Prevent horizontal scroll
              }}
            >
              <CardContent>
                <Box sx={{ overflowX: "hidden", borderRadius: "10px" }}>
                  <Suspense fallback={<div>loading...</div>}>
                    <WeatherChart />
                  </Suspense>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
      {showSuccessAlert && (
        <video
          src={waitingVid}
          alt="Elai Agritech"
          style={{
            height: "100%",
            width: "100vw",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 99999,
            objectFit: "fill",
          }}
          autoPlay
          // controls
          loop
          muted
        />
      )}
    </Box>
  );
};

export default Dashboard;
