import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import {
  Button,
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Grid,
  // Container,
  Stack,
  Checkbox,
  Modal,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/authService/AuthContextProvider";
import * as turf from "@turf/turf";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import DrawRoundedIcon from "@mui/icons-material/DrawRounded";
import axios from "axios";
import { MyFarmBox } from "../summary/Style";
import waitingVid from "../assets/img/waiting.mp4";
import { convertToDateFormat } from "../utility/Utility";
import "../i18n";
import { useTranslation } from "react-i18next";

const MAPBOX_TOKEN = process.env.REACT_APP_MAP_BOX_API;
const AWS_URL = process.env.REACT_APP_AWS_LAMBDA_URL;

const UserBulkUploadData = () => {
  const { user } = useAuth();
  const mobile = user.mobile.mobile;
  const [data, setData] = useState([]);
  const [boundary, setBoundary] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [area, setArea] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editRow, setEditRow] = useState(null); // For editing modal
  const [editData, setEditData] = useState({}); // Data for editing
  const [modalOpen, setModalOpen] = useState(false); // Modal state
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const {t} = useTranslation();

  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const drawRef = useRef(null);
  // const navigate = useNavigate();

  // const { fetchBulkUploadData } = useAuth();

  const handleSelectRowCheckbox = (rowMobile) => {
    setSelectedRows((prev) => {
      // If already selected, deselect it
      if (prev.includes(rowMobile)) {
        return prev.filter((mobile) => mobile !== rowMobile);
      }
      // Otherwise, add it to the selected rows
      return [...prev, rowMobile];
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Select all rows
      setSelectedRows(data.map((row) => row.Mobile));
    } else {
      // Deselect all rows
      setSelectedRows([]);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${AWS_URL}getUserBulkData?mobile=${mobile}`
        );
        if (response.ok) {
          const json = await response.json();
          const formattedData = json.result.map((item) => ({
            ...item,
            "Sowing Date": item["Sowing Date"], // Ensure consistent format
            Mobile: String(item.Mobile), // Keep mobile as string
          }));
          setData(formattedData);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

    mapboxgl.accessToken = MAPBOX_TOKEN;
    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [73.9329, 19.0595],
      zoom: 18,
    });

    mapRef.current.on("load", () => {
      mapRef.current.addSource("google-satellite", {
        type: "raster",
        tiles: [
          "https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", // Google Maps Satellite URL
        ],
        tileSize: 256,
      });

      mapRef.current.addLayer({
        id: "google-satellite-layer",
        type: "raster",
        source: "google-satellite",
        paint: {
          "raster-opacity": 1, // Control opacity
        },
      });
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
    });
    mapRef.current.addControl(geocoder);

    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: { polygon: true, trash: true },
    });
    mapRef.current.addControl(drawRef.current);

    const updateCoordinates = () => {
      const data = drawRef.current.getAll();
      if (data.features.length > 0) {
        setBoundary(data.features[0]);
      } else {
        setBoundary(null);
      }
    };

    mapRef.current.on("draw.create", updateCoordinates);
    mapRef.current.on("draw.update", updateCoordinates);
    mapRef.current.on("draw.delete", updateCoordinates);

    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, [mobile]);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteData = async (row) => {
    try {
      const response = await axios.delete(
        `${AWS_URL}deleteDataFromUserBulk?mobile=${row.Mobile}&farm_name=${row["Farm name"]}` // Replace with your actual delete API endpoint
      );
      if (response.data.statusCode === 200) {
        alert("Record removed successfully!");
        setData((prevData) =>
          prevData.filter(
            (item) =>
              item.Mobile !== row.Mobile ||
              item["Farm name"] !== row["Farm name"]
          )
        );
      } else {
        const error = await response.json();
        alert(`Failed to delete record: ${error.message}`);
      }
    } catch (error) {
      console.error("Error deleting record:", error);
      alert("Error deleting data. Please try again.");
    }
  };

  const handleSelectRow = (row) => {
    setSelectedRow(row);
    const [longitude, latitude] = row.Coordinates.split(",").map(parseFloat);
    mapRef.current.flyTo({
      center: [longitude, latitude],
      zoom: 18,
      speed: 2,
      curve: 1,
    });
    drawRef.current.deleteAll();
    setBoundary(null);

    const marker = new mapboxgl.Marker()
      .setLngLat([longitude, latitude]) // Set the longitude and latitude for the marker
      .addTo(mapRef.current); // Add the marker to the map

    // Optionally, if you want to add a custom popup to the marker
    const popup = new mapboxgl.Popup({ offset: 25 })
      .setText(`${row["Farm name"]}`)
      .setLngLat([longitude, latitude])
      .addTo(mapRef.current);
  };

  const openEditModal = (row) => {
    setEditRow(row);
    setEditData(row);
    setModalOpen(true);
  };

  const handleEditChange = (key, value) => {
    setEditData((prev) => ({ ...prev, [key]: value }));
  };

  const submitEditData = async () => {
    const patchPayload = {
      mobile: editRow.Mobile,
      farm_name: editRow["Farm name"],
      updateObj: {
        "Sowing Date": editData["Sowing Date"],
        Coordinates: editData.Coordinates,
      },
    };
    try {
      const response = await axios.patch(
        `${AWS_URL}UpdateBulkData`,
        patchPayload
      );
      if (response.data.statusCode === 200) {
        alert("Data updated successfully!");
        setData((prevData) =>
          prevData.map((item) =>
            item.Mobile === editRow.Mobile &&
            item["Farm name"] === editRow["Farm name"]
              ? { ...item, ...patchPayload.updateObj }
              : item
          )
        );
        setModalOpen(false);
      } else {
        alert("Failed to update data. Please try again.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      alert("Error updating data. Please try again.");
    }
  };
  const updateCoordinates = () => {
    const data = drawRef.current.getAll();
    if (data.features.length > 0) {
      const feature = data.features[0];
      setBoundary(feature);
    } else {
      setBoundary(null);
    }
  };

  const handleSaveBoundary = async () => {
    // debugger;
    if (!selectedRow || !boundary) {
      // debugger;
      alert("No data or boundary selected");
      return;
    }
    setShowSuccessAlert(true);
    const areaInSquareMeters = turf.area(boundary);
    const areaInAcres = (areaInSquareMeters / 4046.86).toFixed(2);
    const farmData = {
      mobile: selectedRow.Mobile,
      farm_name: selectedRow["Farm name"],
      date: selectedRow["Sowing Date"],
      coordinates: boundary.geometry.coordinates[0].map(([lng, lat]) => ({
        longitude: lng,
        latitude: lat,
      })),
      area: areaInAcres,
      crop : ""
    };
    try {
      // const response = await fetch(
      //   `${AWS_URL}createFarm_v3`,
      //   {
      //     method: "POST",
      //     headers: { "Content-Type": "application/json" },
      //     body: JSON.stringify(farmData),
      //   }
      // );
      const response = await axios.post(`${AWS_URL}createFarm_v3`, farmData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = response.data;
      // if (response.ok) {
      //   alert("Boundary saved successfully!");
        // deleteData(selectedRow);
        // fetchBulkUploadData(selectedRow.Mobile);
        // navigate(`/dashboard/${farmData.mobile}/${farmData.farm_name}`);

        if (responseData.statusCode === 200) {
          if (responseData.body === "Database operation failed") {
            setShowSuccessAlert(false);
            alert("Something went wrong! Please try again");
          } else if (responseData.body === "API queries successful") {
            setShowSuccessAlert(false);
            alert("Boundary saved successfully!");
            // navigate(`/dashboard/${mobile}/${farmName}`);
          } else if (responseData.body === "Farm created successfully") {
            setShowSuccessAlert(false);
            alert("Boundary saved successfully!");
            // navigate(`/dashboard/${mobile}/${farmName}`);
          } else if (responseData.body === "no data") {
            setShowSuccessAlert(false);
            alert("Something went wrong! Please try again");
          }
        } else if (responseData.statusCode === 504) {
          // Handle timeout scenario: Retry fetching data until health score is available
          fetchDataUntilHealthScore(mobile, farmData.farm_name);
        }
      } 
    catch (error) {
      if (error.response?.status === 504) {
        fetchDataUntilHealthScore(mobile, farmData.farm_name);
      } else {
        setShowSuccessAlert(false);
        alert("Something went wrong! Please try again");
        console.warn("Error saving farm data:", error);
      }
    }
  };

  const fetchDataUntilHealthScore = async (mobile, farmName) => {
    const abortController = new AbortController();
    const timeoutSignal = abortController.signal;

    let hasNavigated = false;
    let alertShown = false;

    const timeout = new Promise((_, reject) => {
      const timer = setTimeout(() => {
        if (!timeoutSignal.aborted && !hasNavigated) {
          alertShown = true;
          setShowSuccessAlert(false);
          alert("Server taking too much time... Please try again!");
          // reject(new Error("Navigation triggered"));
        }
      }, 90000);

      timeoutSignal.addEventListener("abort", () => {
        clearTimeout(timer);
      });
    });

    try {
      let result = await axios.post(
        `${AWS_URL}getCropMonitorData_v2`,
        {
          mobile: mobile,
          farm_name: farmName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          signal: timeoutSignal,
        }
      );

      // Check if health_score is available; if not, continue fetching
      while (!result?.data?.result?.health_score) {
        result = await Promise.race([
          new Promise((resolve) => setTimeout(resolve, 3000)), // Delay before next request
          timeout,
        ]);

        if (result !== timeout) {
          result = await axios.post(
            `${AWS_URL}getCropMonitorData_v2`,
            {
              mobile: mobile,
              farm_name: farmName,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              signal: timeoutSignal,
            }
          );
        }
      }
      hasNavigated = true;
      if (!alertShown) {
      setShowSuccessAlert(false);
      alert("Boundary saved successfully!");}
    } catch (error) {
      // Timeout or other errors during fetching data
      setShowSuccessAlert(false);
      alert("Something went wrong! Please try again");
      console.warn("Error fetching crop monitor data:", error);
    }
  };

  const paginatedData = data.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
    {!showSuccessAlert && (<>
    <MyFarmBox
      sx={{ backgroundColor: "white", marginTop: "100px" }}
    >
      <Grid container spacing={1} md={12} xs={12} lg={12}>
        {/* Table Section */}
        <Grid item md={7} lg={7}>
          <Box sx={{ padding: 2 }}>
            <Paper
              sx={{ backgroundColor: "#224831", color: "white", padding: 2, borderRadius:"10px" }}
            >
              <Typography
                variant="h5"
                sx={{ marginBottom: 2, color: "#fcaf03" }}
              >
                {t("User Data")}
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="#fcaf03"
                          indeterminate={
                            selectedRows.length > 0 &&
                            selectedRows.length < data.length
                          }
                          checked={selectedRows.length === data.length}
                          onChange={handleSelectAll}
                          sx={{ color: "#fcaf03" }}
                        />
                      </TableCell> */}
                      <TableCell style={{ color: "#fcaf03" }}>{t("Mobile")}</TableCell>
                      <TableCell style={{ color: "#fcaf03" }}>
                        {t("Farm Name")}
                      </TableCell>
                      <TableCell style={{ color: "#fcaf03" }}>
                        {t("Sowing Date")}
                      </TableCell>
                      <TableCell style={{ color: "#fcaf03" }}>
                        {t("Coordinates")}
                      </TableCell>
                      <TableCell style={{ color: "#fcaf03" }}>{t("Action")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.map((row, index) => (
                      <TableRow key={index}>
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            color="#fcaf03"
                            checked={selectedRows.includes(row.Mobile)}
                            onChange={() => handleSelectRowCheckbox(row.Mobile)}
                            sx={{ color: "#fcaf03" }}
                          />
                        </TableCell> */}
                        <TableCell sx={{ color: "#ffffff" }}>
                          {row.Mobile}{" "}
                        </TableCell>
                        <TableCell sx={{ color: "#ffffff" }}>
                          {row["Farm name"]}
                        </TableCell>
                        <TableCell sx={{ color: "#ffffff" }}>
                        {convertToDateFormat(row["Sowing Date"])}
                        </TableCell>
                        <TableCell sx={{ color: "#ffffff" }}>
                          {row.Coordinates}
                        </TableCell>
                        <TableCell>
                          {/* <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSelectRow(row)}
                          sx={{backgroundColor:'#fcaf03', color:'#224831',paddingRight:'0px', paddingLeft:'0px'}}
                        >
                          <DrawRoundedIcon></DrawRoundedIcon>
                          
                        </Button> */}
                          <Stack
                            direction="row"
                            spacing={2}
                            // sx={{ justifyContent: "space-between" }}
                          >
                            <DrawRoundedIcon
                              onClick={() => handleSelectRow(row)}
                              sx={{
                                color: "#fcaf03",
                                "&:hover": { cursor: "pointer" },
                              }}
                            ></DrawRoundedIcon>
                            <EditCalendarRoundedIcon
                              onClick={() => openEditModal(row)}
                              sx={{
                                color: "#fcaf03",
                                "&:hover": { cursor: "pointer" },
                              }}
                            ></EditCalendarRoundedIcon>
                            <DeleteForeverRoundedIcon
                              onClick={() => deleteData(row)}
                              sx={{
                                color: "#fcaf03",
                                "&:hover": { cursor: "pointer" },
                              }}
                            ></DeleteForeverRoundedIcon>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ color: "#ffffff" }}
              />
            </Paper>
          </Box>
        </Grid>

        {/* Map Section */}
        <Grid item md={5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backgroundColor: "#224831",
              marginTop: "15px",
              borderRadius: "10px",
              padding: "2%",
              maxHeight:"500px"
            }}
          >
            <Typography variant="h5" sx={{ color: "#fcaf03", marginBottom: 2 }}>
              {t("Map")}
            </Typography>
            <div
              ref={mapContainer}
              style={{
                width: "100%",
                height: "455px",
                border: "10px solid #224831",
                borderRadius:"10px"
              }}
            />
            <Button
              variant="contained"
              color="success"
              sx={{
                marginTop: 2,
                alignSelf: "center",
                backgroundColor: "#fcaf03",
                color: "#224831",
              }}
              onClick={handleSaveBoundary}
            >
              Save Boundary
            </Button>
            {area > 0 && (
              <Typography sx={{ marginTop: 2 }}>Area: {area} acres</Typography>
            )}
          </Box>
          <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            <Box
              sx={{
                backgroundColor: "white",
                padding: 4,
                margin: "auto",
                mt: 20,
                width: "50%",
              }}
            >
              <Typography variant="h6">Edit Row Data</Typography>
              <TextField
                label="Sowing Date"
                value={editData["Sowing Date"] || ""}
                onChange={(e) =>
                  handleEditChange("Sowing Date", e.target.value)
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Coordinates"
                value={editData.Coordinates || ""}
                onChange={(e) =>
                  handleEditChange("Coordinates", e.target.value)
                }
                fullWidth
                margin="normal"
              />
              <Button variant="contained" onClick={submitEditData}>
                Save
              </Button>
            </Box>
          </Modal>
        </Grid>
      </Grid>
      {/* <Typography sx={{ opacity: 1 }}>
        Consequat Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Rhoncus
        dolor purus non do eiusmod tempor incididunt ut labore et dolore magna
        aliqua.
      </Typography> */}
    </MyFarmBox>
    <Typography sx={{ opacity: 0 }}>
    Consequat Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
    do eiusmod tempor incididunt ut labore et dolore magna aliqua. Rhoncus
    dolor purus non do eiusmod tempor incididunt ut labore et dolore magna
    aliqua.
  </Typography>
  </>
  )
  }
  {showSuccessAlert && (
          <video
            src={waitingVid}
            alt="Elai Agritech"
            style={{
              height: "100%",
              width: "100vw",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 99999,
              objectFit: "fill",
            }}
            autoPlay
            // controls
            loop
            muted
          ></video>
        )}
    </>
  );
};

export default UserBulkUploadData;

// import React, { useState, useEffect, useRef } from "react";
// import { useTable } from "react-table";
// import mapboxgl from "mapbox-gl";
// import MapboxDraw from "@mapbox/mapbox-gl-draw";
// import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
// import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
// import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
// import { Button, Box, Typography, Grid } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../login/authService/AuthContextProvider";
// import * as turf from "@turf/turf";

// const MAPBOX_TOKEN = process.env.REACT_APP_MAP_BOX_API;

// const UserBulkUploadData = () => {
//   const user = useAuth();
//   const mobile = user?.mobile?.mobile;

//   const [data, setData] = useState([]);
//   const [boundary, setBoundary] = useState(null);
//   const [selectedRow, setSelectedRow] = useState(null);
//   const [area, setArea] = useState(0);

//   const mapContainer = useRef(null);
//   const mapRef = useRef(null);
//   const drawRef = useRef(null);
//   const navigate = useNavigate();

//   const columns = React.useMemo(
//     () => [
//       { Header: "Mobile", accessor: "Mobile" },
//       { Header: "Farm Name", accessor: "Farm name" },
//       { Header: "Sowing Date", accessor: "Sowing Date" },
//       {
//         Header: "Coordinates",
//         accessor: "Coordinates",
//       },
//       {
//         Header: "Action",
//         Cell: ({ row }) => (
//           <Button
//             variant="contained"
//             onClick={() => handleSelectRow(row.original)}
//           >
//             Select
//           </Button>
//         ),
//       },
//     ],
//     []
//   );

//   const fetchData = async () => {
//     try {
//       const response = await fetch(
//         `${AWS_URL}getUserBulkData?mobile=${mobile}`
//       );
//       if (response.ok) {
//         const json = await response.json();
//         // Ensure fetched data has the correct format
//         const formattedData = json.result.map((item) => ({
//           ...item,
//           "Sowing Date": item["Sowing Date"], // Already in YYYY-MM-DD format
//           Mobile: String(item.Mobile), // Ensure mobile stays a string
//         }));
//         setData(formattedData);
//       } else {
//         console.error("Failed to fetch data");
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const handleSelectRow = (row) => {
//     setSelectedRow(row);
//     const [longitude, latitude] = row.Coordinates.split(",").map(parseFloat);

//     // Fly to the selected coordinates
//     mapRef.current.flyTo({
//       center: [longitude, latitude],
//       zoom: 18,
//       speed: 2,
//       curve: 1,
//     });

//     // Clear any previous boundaries
//     drawRef.current.deleteAll();
//     setBoundary(null);
//   };

//   const updateCoordinates = () => {
//     const data = drawRef.current.getAll();
//     if (data.features.length > 0) {
//       const feature = data.features[0];
//       setBoundary(feature);
//     } else {
//       setBoundary(null); // Clear boundary if deleted
//     }
//   };

//   const handleSaveBoundary = async () => {
//     if (!selectedRow || !boundary) {
//       alert("No data or boundary selected");
//       return;
//     }

//     const areaInSquareMeters = turf.area(boundary);
//     const areaInAcres = (areaInSquareMeters / 4046.86).toFixed(2);

//     const farmData = {
//       mobile: selectedRow.Mobile,
//       farm_name: selectedRow["Farm name"],
//       date: selectedRow["Sowing Date"],
//       coordinates: boundary.geometry.coordinates[0].map(([lng, lat]) => ({
//         longitude: lng,
//         latitude: lat,
//       })),
//       area: areaInAcres,
//     };

//     try {
//       const response = await fetch(
//         `${AWS_URL}createFarm_v2`,
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify(farmData),
//         }
//       );

//       if (response.ok) {
//         alert("Boundary saved successfully!");
//         setTimeout(() => {
//           navigate(`/dashboard/${farmData.mobile}/${farmData.farm_name}`);
//         }, 30000);
//       } else {
//         alert("Failed to save boundary. Please try again.");
//       }
//     } catch (error) {
//       console.error("Error saving boundary:", error);
//       alert("Error saving boundary data. Please try again.");
//     }
//   };

//   useEffect(() => {
//     fetchData();

//     mapboxgl.accessToken = MAPBOX_TOKEN;

//     // Initialize the map
//     mapRef.current = new mapboxgl.Map({
//       container: mapContainer.current,
//       style: "mapbox://styles/mapbox/satellite-streets-v11",
//       center: [73.9329, 19.0595],
//       zoom: 18,
//     });

//     // Add Geocoder
//     const geocoder = new MapboxGeocoder({
//       accessToken: mapboxgl.accessToken,
//       flyTo: {
//         bearing: 0,
//         speed: 2,
//         curve: 1,
//         easing: (t) => t,
//       },
//       mapboxgl: mapboxgl,
//     });
//     mapRef.current.addControl(geocoder);

//     // Initialize MapboxDraw
//     drawRef.current = new MapboxDraw({
//       displayControlsDefault: false,
//       controls: { polygon: true, trash: true },
//     });
//     mapRef.current.addControl(drawRef.current);

//     // Add listeners for drawing
//     mapRef.current.on("draw.create", updateCoordinates);
//     mapRef.current.on("draw.update", updateCoordinates);
//     mapRef.current.on("draw.delete", updateCoordinates);

//     return () => {
//       if (mapRef.current) mapRef.current.remove();
//     };
//   }, []);

//   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
//     useTable({ columns, data });

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "row",
//         gap: 2,
//         mt: 15,
//         // backgroundColor: "white",
//       }}
//     >
//         <Grid container spacing={3}>
//             <Grid item md={6}>
//             <Box sx={{ flex: 1 , backgroundColor:'#ffffff'}}>
//         <Typography variant="h6">User Data</Typography>
//         <table
//           {...getTableProps()}
//           style={{ width: "100%", border: "1px solid #ffffff" , color:'#fcaf03' }}
//         >
//           <thead>
//             {headerGroups.map((headerGroup) => (
//               <tr {...headerGroup.getHeaderGroupProps()}>
//                 {headerGroup.headers.map((column) => (
//                   <th {...column.getHeaderProps()}>
//                     {column.render("Header")}
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </thead>
//           <tbody {...getTableBodyProps()}>
//             {rows.map((row) => {
//               prepareRow(row);
//               return (
//                 <tr {...row.getRowProps()}>
//                   {row.cells.map((cell) => (
//                     <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
//                   ))}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </Box>
//             </Grid>
//             <Grid item md={4}>
//             <Box sx={{ flex: 2, height: "500px", width: "800px" }}>
//         <Typography variant="h6">Map</Typography>
//         <div ref={mapContainer} style={{ width: "100%", height: "100%" }} />
//         <Button variant="contained" onClick={handleSaveBoundary}>
//           Save Boundary
//         </Button>
//         {area > 0 && <Typography>Area: {area} acres</Typography>}
//       </Box>
//             </Grid>

//       </Grid>
//     </Box>
//   );
// };

// export default UserBulkUploadData;
