import axios from "axios";

const API_URL =
  "https://sixdhrxo15.execute-api.ap-south-1.amazonaws.com/prod/";

class AuthService {
  login(email, password) {
    var data = JSON.stringify({ email: email, password: password });
 
    var config = {
      method: "post",
      url: API_URL + "loginFarmerUserDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios(config).then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(name, email,mobile, password,repassword,selectedRole ) {
    var data = JSON.stringify({
      name: name,
      email: email,
      mobile:mobile,
      password: password,
      password2: repassword,
      role:selectedRole
    });
 
    var config = {
      method: "post",
      url: API_URL + "registerFarmerUserDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios(config).then((response) => {
      return response.data;
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();