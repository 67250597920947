import React, { useContext, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import axios from "axios";
import { FarmContext } from "../../contextAPI/FarmContext";
import { useParams } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { convertToDateFormat_v2 } from "../../utility/Utility";

const AWS_Lambda_URL = process.env.REACT_APP_AWS_LAMBDA_URL;

const Map = React.memo((isOpen) => {
  const mapContainerRef = useRef();
  const mapRef = useRef();
  const popupRef = useRef(
    new mapboxgl.Popup({ closeButton: false, closeOnClick: false })
  );
  const { mobile, farm_name } = useParams();
  const [centerCoordinates, setCenterCoordinates] = useState([0, 0]);
  // const [NDVIData, setNDVIData] = useState({});
  // const [PSRIData, setPSRIData] = useState({});
  // const [NDMIData, setNDMIData] = useState({});
  // const [indexValue, setIndexValue] = useState("NDVI");
  const indices = ["NDVI", "PSRI", "NDMI"];
  const [timestampIndex, setTimestampIndex] = useState(0);
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);
  const [timeStampSeries, setTimeStampSeries] = useState([]);
  const [groupData, setGroupData] = useState({});
  // const [isOpen, setIsOpen] = useState(false);
  // const isRightDisabled = timestampIndex === Data.length - 1;
  // const [geoJsonData, setGeoJsonData] = useState({});
  const { indexValue, setIndexValue, geoJsonData, setGeoJsonData } =
    useContext(FarmContext);
  const { t } = useTranslation();

  // const indexPaint = {
  //   "fill-color": [
  //     "interpolate",
  //     ["linear"],
  //     ["get", "value"],
  //     0.0,
  //     "#FF0000", // Red for minimum value
  //     0.1,
  //     "#FF1100",
  //     0.2,
  //     "#FF2200",
  //     0.3,
  //     "#FF3300",
  //     0.4,
  //     "#FF4500",
  //     0.5,
  //     "#FF6600",
  //     0.6,
  //     "#FF9900",
  //     0.7,
  //     "#FFCC00",
  //     0.8,
  //     "#006600", // Darker green as values increase
  //     0.9,
  //     "#009900",
  //     1.0,
  //     "#00CC00",
  //   ],
  //   "fill-opacity": 1,
  // };

  const stressPaint = {
    "fill-color": [
      "interpolate",
      ["linear"],
      ["get", "value"],
      0.0,
      "#00441B", // Dark Green for values between 0.1 and 0.3
      0.2,
      "#41AB5D", // Green for values between 0.3 and 0.5
      0.5,
      "#ADDD8E", // Light Green for values between 0.5 and 0.7
      0.7,
      "#F7FCB9", // Yellow for values between 0.7 and 1.0
      1.0,
      "#FF9900", // Light Yellow for maximum values
    ],
    "fill-opacity": 1,
  };

  const indexPaint = {
    "fill-color": [
      "interpolate",
      ["linear"],
      ["get", "value"],
      0.0,
      "#FFEDA0", // Light Yellow to Red transition
      0.2,
      "#F7FCB9", // Yellow for values between 0.1 and 0.3
      0.5,
      "#ADDD8E", // Light Green for values between 0.3 and 0.5
      0.7,
      "#41AB5D", // Green for values between 0.5 and 0.7
      1.0,
      "#00441B", // Dark Green for values greater than or equal to 0.7
    ],
    "fill-opacity": 1,
  };

  // const stressPaint = {
  //   "fill-color": [
  //     "interpolate",
  //     ["linear"],
  //     ["get", "value"],
  //     0.0,
  //     "#00CC00",
  //     0.1,
  //     "#009900",
  //     0.2,
  //     "#006600", // Darker green as values increase
  //     0.3,
  //     "#FFCC00",
  //     0.4,
  //     "#FF9900",
  //     0.5,
  //     "#FF6600",
  //     0.6,
  //     "#FF4500",
  //     0.7,
  //     "#FF3300",
  //     0.8,
  //     "#FF2200",
  //     0.9,
  //     "#FF1100",
  //     1.0,
  //     "#FF0000", // Red for maximum value
  //   ],
  //   "fill-opacity": 1,
  // };

  const loadGeoJsonData = async (start_date, end_date, crop_season, area) => {
    try {
      const responses = await Promise.all(
        indices.map((index) =>
          axios.post(
            `${AWS_Lambda_URL}testPython`,
            {
              mobile: mobile,
              farm_name: farm_name,
              start_date: start_date,
              end_date: end_date,
              crop_season: crop_season,
              area: area,
              index: index,
              api_url_fetch_tiff: `${AWS_Lambda_URL}fetchTiffFile`,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
        )
      );
      const data = {};
      responses.forEach((response, idx) => {
        // debugger;
        const index = indices[idx];
        const parsedData = JSON.parse(response.data.body);
        if (parsedData && parsedData.length > 0) {
          const firstCoordinates = parsedData[Math.floor(parsedData.length / 2)].geometry.coordinates[0][0];
          setCenterCoordinates(firstCoordinates); // Assuming this is only set once
        }
        data[index] = { type: "FeatureCollection", features: parsedData };
      });

      const groupedData = {};
      Object.keys(data).forEach((key) => {
        groupedData[key] = groupTheFeatures(data[key].features);
      });
      setGeoJsonData(data);
      setGroupData(groupedData);
    } catch (error) {
      console.warn("Error loading GeoJSON:", error);
    }
  };

  const groupTheFeatures = (geoData) => {
    return geoData.reduce((acc, feature) => {
      const timestamp = feature.properties.timestamp;
      if (!acc[timestamp]) {
        acc[timestamp] = [];
      }
      acc[timestamp].push(feature);
      return acc;
    }, {});
  };

  useEffect(() => {
    // loadGeoJsonData();
    fetchGeojsonData(mobile, farm_name);
  }, [mobile, farm_name]);

  const fetchGeojsonData = async (mobile, farm_name) => {
    try {
      const response = await axios.post(
        `${AWS_Lambda_URL}getCropMonitorData_v2`,
        {
          mobile: mobile,
          farm_name: farm_name,
        },
        {
          headers: {
            "Content-Type": "application/json", // Ensure the correct content type is set
          },
        }
      );
      // console.log("Crop Monitor Data fetched New : ", response);
      // debugger;
      const data = response.data.result;
      loadGeoJsonData(
        data.start_date,
        data.end_date,
        data.crop_season,
        data.area
      );
    } catch (error) {
      console.warn("Error fetching GeoJSON data:", error);
    } finally {
      // setGeojsonLoading(false);
    }
  };

  // const colorStops = [
  //   "#00CC00", // Green
  //   "#009900", // Dark Green
  //   "#006600", // Darker Green
  //   "#FFCC00", // Yellow
  //   "#FF9900", // Orange
  //   "#FF6600", // Red-Orange
  //   "#FF4500", // Orange-Red
  //   "#FF3300", // Red
  //   "#FF2200", // Dark Red
  //   "#FF1100", // Very Dark Red
  //   "#FF0000", // Darkest Red
  // ];

  // const colorStops_v2 = [
  //   "#FF0000", // Darkest Red
  // "#FF1100", // Very Dark Red
  // "#FF2200", // Dark Red
  // "#FF3300", // Red
  // "#FF4500", // Orange-Red
  // "#FF6600", // Red-Orange
  // "#FF9900", // Orange
  // "#FFCC00", // Yellow
  // "#00CC00", // Green
  // "#009900", // Dark Green
  // "#006600"  // Darkest Green
  // ]

  //  const colorStops_v2 = [
  //   '#ffeda0', '#ffeea1', '#ffeea2', '#ffefa2', '#ffefa3', '#feefa3',
  // '#fef0a4', '#fef0a5', '#fef1a6', '#fcf3aa'
  // ]

  useEffect(() => {
    // Initialize the map only once
    if (
      !mapRef.current &&
      centerCoordinates[0] !== 0 &&
      centerCoordinates[1] !== 0
    ) {
      if (groupData && groupData[indexValue] && isOpen.isOpen) {
        mapRef.current = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: "mapbox://styles/mapbox/satellite-v9",
          center: centerCoordinates, // Use initial center coordinates
          zoom: 13, // Set an initial zoom level
          attributionControl: false,
        });

        mapRef.current.on("load", () => {
          // Add the geojson layer

          const groupedFeatures = groupData[indexValue];
          const timestampKeys = Object.keys(groupedFeatures);
          setTimeStampSeries(timestampKeys);
          const totalTimestamps = timestampKeys.length;
          const currentTimestampData =
            groupedFeatures[timestampKeys[timestampIndex]];
          // console.log("currentTimestampData : ", currentTimestampData);

          setIsRightDisabled(timestampIndex === 0);
          setIsLeftDisabled(timestampIndex === totalTimestamps - 1);

          // const values = currentTimestampData.map(
          //   (feature) => feature.properties.value
          // );
          // console.log("values : ", values)
          // const minValue = Math.min(...values);
          // const maxValue = Math.max(...values);
          // console.log("minValue : ", minValue)
          // console.log("maxValue : ", maxValue)

          // Step 3: Calculate the breakpoints and color stops
          // const numIntervals = 10;
          // const stepSize = (maxValue - minValue) / (numIntervals - 1);

          // const breakpoints = Array.from(
          //   { length: numIntervals },
          //   (_, i) => minValue + i * stepSize
          // );
          // console.log("breakpoints : ", breakpoints)

          // const fillColorStops = [
          //   "interpolate",
          //   ["linear"],
          //   ["get", "value"],
          //   ...breakpoints.flatMap((value, index) => [value, colorStops[index]]),
          // ];

          // const fillColorStops_v2 = [
          //   "interpolate",
          //   ["linear"],
          //   ["get", "value"],
          //   ...breakpoints.flatMap((value, index) => [value, colorStops_v2[index]]),
          // ];

          // const stressPaint_v2 = {
          //   "fill-color": fillColorStops,
          //   "fill-opacity": 1,
          // };

          // const indexPaint_v2 = {
          //   "fill-color": fillColorStops_v2,
          //   "fill-opacity": 1,
          // };

          // const Data = groupTheFeatures(geoJsonData[indexValue]?.features);
          // setIsRightDisabled(
          //   timestampIndex === Data[timestampIndex]?.length - 1
          // );
          // console.log("geoJsonData[indexValue] : ", geoJsonData[indexValue])
          // debugger;
          //   .then((response) => response.json())
          //   .then((data) => {
          // geoData
          //   .map((data) => {
          if (mapRef.current.getSource("farm-data")) {
            mapRef.current.getSource("farm-data").setData({
              type: "FeatureCollection",
              features: currentTimestampData,
            });
          } else {
            mapRef.current.addSource("farm-data", {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: currentTimestampData,
              },
            });
          }

          mapRef.current.addSource("google-satellite", {
            type: "raster",
            tiles: [
              "https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", // Google Maps Satellite URL
            ],
            tileSize: 256,
          });

          mapRef.current.addLayer({
            id: "google-satellite-layer",
            type: "raster",
            source: "google-satellite",
            paint: {
              "raster-opacity": 1, // Control opacity
            },
          });

          mapRef.current.addLayer({
            id: "farm-fill",
            type: "fill",
            source: "farm-data",
            layout: {},
            paint: indexValue === "PSRI" ? stressPaint : indexPaint,
          });

          mapRef.current.on("mousemove", "farm-fill", (e) => {
            const features = mapRef.current.queryRenderedFeatures(e.point, {
              layers: ["farm-fill"],
            });

            if (features.length > 0) {
              const coordinates = e.lngLat;

              // Extract values and timestamps
              const details = features.map((feature) => {
                const value = feature.properties.value;
                const timestamp = feature.properties.timestamp;
                return `<strong>Value:</strong> ${value}<br><strong>Timestamp:</strong> ${timestamp}`;
              });

              // Set popup content with values and timestamps
              popupRef.current
                .setLngLat(coordinates)
                .setHTML(details.join("<br><br>")) // Join multiple entries
                .addTo(mapRef.current);
            }
          });

          mapRef.current.on("mouseleave", "farm-fill", () => {
            popupRef.current.remove();
          });
          // }
          //   .catch((error) => console.error("Error loading GeoJSON:", error));
          // }
          // .catch((error) => console.error("Error loading GeoJSON:", error));

          // }

          // Once the map is loaded, perform the zoom flyTo animation
          mapRef.current.flyTo({
            center: centerCoordinates, // Center to your coordinates
            zoom: 16, // Set the zoom level for the flyTo animation
            speed: 2, // Adjust the speed (1.5 for smoother, slower animation)
            // curve: 10, // Adjust the curve (higher = more dramatic zooming effect)
            // easing: (t) => t, // Linear easing for smoother animation
          });
        });
      }
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.off("mousemove", "farm-fill");
        mapRef.current.off("mouseleave", "farm-fill");
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [
    centerCoordinates,
    indexValue,
    geoJsonData,
    timestampIndex,
    isOpen.isOpen,
    groupData,
  ]);

  const handleRightClick = () => {
    setTimestampIndex((prevState) => Math.max(0, prevState - 1));
  };

  const handleLeftClick = () => {
    setTimestampIndex((prevState) =>
      Math.min(Object.keys(groupData[indexValue]).length - 1, prevState + 1)
    );
  };

  const onIndexChange = (index) => {
    // debugger;
    setIndexValue(index);
  };

  return (
    <>
      {groupData && groupData[indexValue] && isOpen.isOpen && (
        <div style={{ position: "relative", minHeight: "450px", maxHeight: "450px" }}>
          <div
            id="map"
            ref={mapContainerRef}
            style={{ minHeight: "450px", maxHeight: "450px", width: "100%", padding: "20px", border: "10px solid #ffffff", borderRadius: "10px" }}
          />
          <div
            className="layer-buttons"
            style={{ position: "absolute", top: 10, left: 10 }}
          >
            {indices.map((index) => (
              <button
                key={index}
                onClick={() => onIndexChange(index)}
                style={{
                  margin: "5px",
                  padding: "10px",
                  minWidth: "100px",
                  backgroundColor: indexValue === index ? "#fcaf03" : "#f8f9fa",
                  color: indexValue === index ? "#224831" : "black",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  justifyContent: "center", // Horizontally center the text
                  alignItems: "center", // Vertically center the text
                  textAlign: "center",
                }}
              >
                {index === "NDVI"
                  ? t("Vegetation")
                  : index === "NDMI"
                  ? t("Moisture")
                  : index === "PSRI"
                  ? t("Stress")
                  : index}
              </button>
            ))}
          </div>
          <div
            className="layer-buttons"
            style={{ position: "absolute", bottom: 20, left: "25%" }}
          >
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={handleLeftClick}
                disabled={isLeftDisabled}
                sx={{
                  backgroundColor: "#224831",
                  color: "#fcaf03",
                  border: "solid #fcaf03 2px",
                  borderRadius: "20px",
                }}
              >
                <SkipPreviousIcon />
              </Button>
              <Typography
                // disabled
                sx={{
                  color: "#fcaf03",
                  backgroundColor: "#224831",
                  border: "solid #fcaf03 2px",
                  borderRadius: "20px",
                  padding: "5px",
                }}
              >
                {convertToDateFormat_v2(timeStampSeries[timestampIndex])}
                {/* {geoJsonData[indexValue]} */}
              </Typography>

              <Button
                variant="contained"
                onClick={handleRightClick}
                disabled={isRightDisabled}
                sx={{
                  backgroundColor: "#224831",
                  color: "#fcaf03",
                  border: "solid #fcaf03 2px",
                  borderRadius: "20px",
                }}
              >
                <SkipNextIcon />
              </Button>
            </Stack>
            {/* <DateSlider2 geoData={geoJsonData[indexValue]}/> */}
            {/* <DiscreteSliderMarks/> */}
          </div>
        </div>
      )}
    </>
  );
});

export default Map;
