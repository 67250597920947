import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import AWS from "aws-sdk";
import { useNavigate } from "react-router-dom";

export const FarmContext = createContext();

const AWS_URL = process.env.REACT_APP_AWS_LAMBDA_URL;
export const FarmProvider = ({ children }) => {
  const [farmInfoUrl, setFarmInfoUrl] = useState("");
  const [geojsonData, setGeojsonData] = useState({});
  const [currentLayer, setCurrentLayer] = useState("NDVI");
  const [farmCoordinates, setFarmCoordinates] = useState({});
  const [healthScore, setHealthScore] = useState(0);
  const [healthCategory, setHealthCategory] = useState("N/A");
  const [lstmData, setLstmData] = useState({});
  const [geojsonLoading, setGeojsonLoading] = useState(true);
  const [lstmLoading, setLstmLoading] = useState(true);
  const [soilLoading, setSoilLoading] = useState(true);
  const [soilData, setSoilData] = useState({});
  const [multiIndexStressResults, setMultiIndexStressResults] = useState([]);
  // const [bulkUploadData, setBulkUploadData] = useState(null);
  const [optedCrop, setOptedCrop] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueCrops, setUniqueCrops] = useState([]);
  const [mandiData, setMandiData] = useState({});
  const [fertilizerData, setfertilizerData] = useState({});
  const [indexValue, setIndexValue] = useState("NDVI");
  const [geoJsonData, setGeoJsonData] = useState({});
  const [centerCoordinates, setCenterCoordinates] = useState([0, 0]);
  const [geoData, setGeoData] = useState({})

  const navigate = useNavigate();

  const handleNewUser = () => {
    // navigate("/addFirstFarm");
  };

  useEffect(() => {
    fetchFertilizerData();
  }, [soilData, geojsonData]);

  const fetchGeojsonData = async (mobile, farm_name) => {
    try {
      setGeojsonLoading(true);
      // const response = await axios.get(
      //   `${AWS_URL}getCropMonitorData_v2?mobile=${mobile}&farm_name=${farm_name}`
      // );
      const response = await axios.post(
        `${AWS_URL}getCropMonitorData_v2`,
        {
          mobile: mobile,
          farm_name: farm_name,
        },
        {
          headers: {
            "Content-Type": "application/json", // Ensure the correct content type is set
          },
        }
      );
      // console.log("Crop Monitor Data fetched New : ", response)
      // debugger;
      const data = response.data.result;
      setGeojsonData(data);
      // setHealthScore(data?.health_score);
      // setHealthCategory(data?.health_category);
      setMultiIndexStressResults(data?.stress_results_data);
      const firstCoordinate = data?.coordinates[0];
      setFarmCoordinates(firstCoordinate);
      setGeojsonLoading(false);
      // loadGeoJsonData(mobile, farm_name, data.start_date, data.end_date, data.crop_season, data.area);
      // if (data[currentLayer]) {
      //   await createBucket(data[currentLayer]);
      // }
    } catch (error) {
      console.warn("Error fetching GeoJSON data:", error);
    } finally {
      setGeojsonLoading(false);
    }
  };

  const fetchLstmData = async (mobile, farm_name) => {
    try {
      // setLstmLoading(true);
      // const response = await axios.get(
      //   `${AWS_URL}getLstmData?mobile=${mobile}&farm_name=${farm_name}`
      // );
      // debugger;
      // setLstmData(response.data.body[0]);
      // debugger;
      // if (!lstmData || lstmData.length == 0) {
      // handleNewUser();
      // }
      // const firstCoordinate = response.data.body[0]["co-ordinates"][0];
      // setFarmCoordinates(firstCoordinate);
      // // debugger
    } catch (error) {
      // debugger
      console.warn("Error fetching LSTM data:", error);
    } finally {
      // debugger
      setLstmLoading(false);
    }
  };

  const fetchFertilizerData = async () => {
    try {
      const { crop } = geojsonData;
      if (crop && soilData && soilData?.N_Predicted) {
        const response = await axios.post(
          `${AWS_URL}getFertilizerData`,
          {
            Crop: crop,
            Available_Nitrogen: soilData?.N_Predicted,
            Available_Phosphorus: soilData?.P_Predicted,
            Available_Potassium: soilData?.K_Predicted,
            Soil_pH: soilData?.pH_Predicted,
            Electrical_Conductivity: soilData?.EC_Predicted,
            Organic_Carbon: soilData?.OC_Predicted,
          },
          {
            headers: {
              "Content-Type": "application/json", // Ensure the correct content type is set
            },
          }
        );
        const data = JSON.parse(response.data.body);
        setfertilizerData(data.result);
      }
    } catch (error) {
      // console.log("error", error)
    }
  };

  // const fetchBulkUploadData = async (mobile) => {
  //   // // debugger;
  //   try {
  //     const response = await axios.get(
  //       `${AWS_URL}getReport_bulkUpload?mobile=${mobile}`
  //     );
  //     // // debugger;
  //     setBulkUploadData(response.data.body);
  //     // debugger
  //     if (!bulkUploadData || bulkUploadData.length == 0) {
  //       // debugger
  //       handleNewUser();
  //     }
  //     // const data = response.data.body;
  //     // setUniqueCrops(Array.from(new Set(data.lstm_predictions.map((farm) => farm.predicted_crop))))
  //   } catch (error) {
  //     console.error("Error fetching bulk upload data:", error);
  //   }
  // };

  const fetchSoilData = async (mobile, farm_name) => {
    try {
      setSoilLoading(true);
      const response = await axios.post(
        // `${AWS_URL}getNpk_v2?mobile=${mobile}&farm_name=${farm_name}`
        `${AWS_URL}getNpk_v2`,
        {
          mobile: mobile,
          farm_name: farm_name,
        },
        {
          headers: {
            "Content-Type": "application/json", // Ensure the correct content type is set
          },
        }
      );
      // // debugger;
      setSoilData(response.data.body);
      // fetchFertilizerData(response.data.body);
    } catch (error) {
      console.warn("Error fetching soil data:", error);
    } finally {
      setSoilLoading(false);
    }
  };

  const fetchMandiData = async () => {
    try {
      const { latitude, longitude } = farmCoordinates;
      const { crop } = geojsonData;
      // // debugger;
      if (
        !farmCoordinates ||
        Object.keys(farmCoordinates).length === 0 ||
        latitude === undefined ||
        longitude === undefined
      ) {
        // debugger;
        console.warn(
          "farmCoordinates is empty or latitude/longitude is undefined. Exiting fetchMandiData."
        );
        return; // Exit the function early
      }

      const response = await axios.post(
        `${AWS_URL}fetchMandiData`, // Your Lambda endpoint
        {
          lat: latitude,
          lng: longitude,
          crop: crop,
        },
        {
          headers: {
            "Content-Type": "application/json", // Ensure the correct content type is set
          },
        }
      );
      // if(response.data.body) {
      const data = JSON.parse(response?.data?.body);
      // console.log("Mandi Data : ", data);
      // debugger
      setMandiData(data);
      // }
    } catch (error) {
      console.warn("Error fetching mandi data:", error);
    } finally {
      setSoilLoading(false); // End LSTM loading
    }
  };

  useEffect(() => {
    fetchMandiData();
  }, [farmCoordinates]);

  const createBucket = async (farmInfoKey) => {
    try {
      const {
        REACT_APP_AWS_S3_BUCKETNAME,
        REACT_APP_AWS_S3_REGION,
        REACT_APP_AWS_S3_SECRET,
        REACT_APP_AWS_S3_KEY,
      } = process.env;

      AWS.config.update({
        accessKeyId: REACT_APP_AWS_S3_KEY,
        secretAccessKey: REACT_APP_AWS_S3_SECRET,
        region: REACT_APP_AWS_S3_REGION,
      });

      const s3 = new AWS.S3();
      const signedUrlExpireSeconds = 60 * 15;

      const url = await s3.getSignedUrlPromise("getObject", {
        Bucket: REACT_APP_AWS_S3_BUCKETNAME,
        Key: farmInfoKey,
        Expires: signedUrlExpireSeconds,
      });

      setFarmInfoUrl(url);
    } catch (error) {
      console.warn("Error creating signed URL:", error);
    }
  };

  const handleLayerChange = async (layerKey) => {
    setCurrentLayer(layerKey);
    if (geojsonData[layerKey]) {
      await createBucket(geojsonData[layerKey]);
    }
  };

  return (
    <FarmContext.Provider
      value={{
        farmInfoUrl,
        geojsonData,
        currentLayer,
        farmCoordinates,
        healthScore,
        healthCategory,
        lstmData,
        geojsonLoading,
        lstmLoading,
        soilLoading,
        soilData,
        multiIndexStressResults,
        // bulkUploadData,
        mandiData,
        handleLayerChange,
        fetchGeojsonData,
        fetchLstmData,
        fetchSoilData,
        // fetchBulkUploadData,
        optedCrop,
        setOptedCrop,
        filteredData,
        setFilteredData,
        // uniqueCrops
        fetchMandiData,
        setGeojsonData,
        setHealthScore,
        setHealthCategory,
        setMultiIndexStressResults,
        setFarmInfoUrl,
        fertilizerData,
        indexValue,
        setIndexValue,
        geoJsonData,
        setGeoJsonData,
        geoData,
        setGeoData,
        setGeojsonLoading
      }}
    >
      {children}
    </FarmContext.Provider>
  );
};
