import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
// import logo from "../assets/logo/ELAI LOGOS.png";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import Avatar from "@mui/material/Avatar";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "@mui/material/Button";
import { useAuth } from "../login/authService/AuthContextProvider";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import BarChartIcon from "@mui/icons-material/BarChart";
import newDrishti from "../assets/img/DrishtiLogoLatest2.png";
import elailogo from "../assets/img/Elailogo.png";
import { Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import i18n from "../i18n";
import "../i18n";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      // props: ({ open }) => open,
      style: {
        width: "100%",
        marginLeft: `0px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

export default function PersistentDrawerLeft() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const settings = [
    "Summary",
    "Change Language",
    "Logout",
    // "Sugarcane Beta Testing",
    // "Sugarcane Beta Dashboard",
  ];
  const navItems = ['Summary', 'Add New Farm'];
  const [optedLanguageEnglish, setOptedLanguageEnglish] = React.useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!optedLanguageEnglish) {
      i18n.changeLanguage("en");
    }
  }, []);

  const handleNavigation = (item) => {
    switch (item) {
      case 'Summary':
        navigate('/summary');
        break;
      case 'Add New Farm':
        navigate("/map");
        break;
      // case 'Contact':
      //   console.log("Contact")
      //   navigate('/contact');
      //   break;
      default:
        // console.log("Default")
        // navigate('/');
    }
  };

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };
  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  // const handleListItemClickfield = (farm_name,index) => {
  //   setselectedItemfield(index);
  //   navigate(`/dashboard/${mobile}/${farm_name}`);
  // };

  const handleAddFarmDetails = () => {
    navigate("/map");
  };

  const handleLogout = () => {
    // debugger
    // authService.logout();
    logout();
    navigate("/");
  };

  const handleLanguage = () => {
    setOptedLanguageEnglish(true);
    const currentLanguage = i18n.language; // Get the current language
    const newLanguage = currentLanguage === "en" ? "ar" : "en"; // Toggle between 'en' and 'ar'
    i18n.changeLanguage(newLanguage); // Change language
  };

  // const handleNavigation = (index) => {
  //   switch (index) {
  //     case 0:
  //       navigate("/summary");
  //       break;
  //     case 1:
  //       navigate("/report");
  //       break;
  //     default:
  //       // debugger
  //       navigate("/");
  //   }
  // };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSetting = (setting) => {
    switch (setting) {
      case 0:
        navigate("/summary");
        break;
      case 1:
        handleLanguage();
        break;
      case 2:
        handleLogout();
        break;
      case 3:
        navigate("/drawfarm_beta");
        break;
      // case 4:
      //   navigate("/dashboard_beta/9676246407/sugar_test_3");
      //   break;
      default:
      // debugger
      // navigate("/");
    }
    setAnchorElUser(null);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";

    for (i = 0; i < 3; i += 1) {
      // Generate a color byte that is darker (by limiting the range to lower values)
      let value = (hash >> (i * 8)) & 0xff;
      value = Math.floor(value * 0.5); // This line darkens the color (reduce brightness)
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name) {
    if (name === "undefined") return null;

    const initials =
      name.split(" ").length === 1
        ? `${name.split(" ")[0][0]}`
        : `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
    // console.log("initials : ", initials.length);
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: initials.length === 1 ? "1.8rem" : "1.2rem", // Increase font size
        // fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 40, // Set a fixed height for the Avatar to make it round
        width: 40,
        borderRadius: "50%",
        // border: '2px solid #fcaf03', // Ensure the Avatar stays circular
        paddingBottom: initials.length === 1 ? "5px" : "0px",
      },
      children: initials,
      // name?.split(" ").length === 1
      //   ? `${name?.split(" ")[0][0]}` // If there's only one word, use the first letter
      //   : `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`, // If there are two words, take the initials
    };
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar sx={{ backgroundColor: "#224830" }}>
        <Toolbar>
          {/* <IconButton
            color="#000000"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                mr: 2,
                mb: 0,
              },
              open && { display: "none" },
            ]}
          >
            <MenuIcon />
          </IconButton> */}

          <img
            src={newDrishti}
            alt="Drishti"
            style={{ height: "70px", width: "100px", padding:"5px" }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "25px",
              marginLeft: "auto",
            }}
          >
          <img
            src={elailogo}
            alt="Elai"
            style={{ height: "60px", width: "40px" }}
          />
            {navItems.map((item) => (
              <Button key={item}
              sx={{
                color: '#fcaf03',
                textDecoration: 'none',  // Disable underline by default
                '&:hover': {
                  textDecoration: 'underline',
                  textShadow:"2" 
                  // fontSize:"1.1rem" // Underline text on hover
                },
                // fontStyle:"oblique"
                // fontSize:"1rem"
              }}
              onClick={() => handleNavigation(item)}  // Call handleNavigation function on click
            >
              {t(item)}
            </Button>
            ))}
            {/* <Button
              onClick={handleAddFarmDetails}
              variant="outlined"
              startIcon={<AddOutlinedIcon sx={{ color: "#224831" }} />}
              sx={{
                borderRadius: "10px",
                padding: "6px 6px",
                color: "#224831",
                // borderColor: "#fcaf03",
                // borderWidth: "2px",
                borderStyle: "solid",
                backgroundColor: "#fcaf03",
              }}
            >
              {t("Add new Farm")}
            </Button> */}
            {/* <Avatar sx={{ bgcolor: "#224831", color: "#fcaf03" }}>{user.mobile.name}</Avatar> */}
            <Tooltip title={user?.mobile?.name} arrow>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar {...stringAvatar(`${user?.mobile?.name}`)} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting, index) => (
                <MenuItem key={index} onClick={() => handleSetting(index)}>
                  {index === 0 ? (
                    <DescriptionOutlinedIcon sx={{ color: "#224831" }} />
                  ) : index === 1 ? (
                    <LanguageIcon sx={{ color: "#224831" }} />
                  ) : index === 2 ? (
                    <LogoutIcon sx={{ color: "#224831" }} />
                  ) : (
                    <BarChartIcon sx={{ color: "#224831" }} />
                  )}
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#224831",
                      ml: "10px",
                      mr: "5px",
                    }}
                  >
                    {t(`${setting}`)}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
            {/* <Typography sx={{color:"#fcaf03"}}>
            {user.mobile.name}
            </Typography> */}
            {/* <Avatar
              alt=""
              src={"../Assests/profile.jpg"}
              sx={{ marginRight: "10px" }}
            /> */}
          </Box>
        </Toolbar>
      </AppBar>
      {/* <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#ffffff",
            color: "#000000",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0 8px",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <IconButton onClick={handleDrawerClose} sx={{ color: "#fcaf03" }}>
            {theme.Direction === "ltr" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              flexGrow: 1,
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                display: "block",
                width: "80%",
                maxWidth: "70px",
                margin: "0px 50px 0px 20px",
              }}
            />
          </div>
        </div>
        <Divider />
        <List>
          {["Summary", "Report"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => handleNavigation(index)}
                sx={{
                  "&:hover": {
                    backgroundColor: "#224831",
                    color: "whitesmoke", // Change the background color on hover
                  },
                }}
              >
                <ListItemIcon sx={{ color: "#fcaf03" }}>
                  {index === 0 ? (
                    <SpeedIcon sx={{ color: "inherit" }} />
                  ) : index === 1 ? (
                    <BarChartIcon sx={{ color: "inherit" }} />
                  ) : index === 2 ? (
                    <DescriptionOutlinedIcon sx={{ color: "inherit" }} />
                  ) : (
                    <NotificationsIcon sx={{ color: "inherit" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ color: "inherit" }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />

        <List>
          <ListItemButton
            onClick={handleLogout}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              "&:hover": {
                backgroundColor: "#224831",
                color: "whitesmoke", // Change the background color on hover
              },
            }}
          >
            <ListItemIcon
              onClick={handleLogout}
              sx={{
                minWidth: 2,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: "#fcaf03",
              }}
            >
              {<LogoutIcon />}
            </ListItemIcon>
            <ListItemText
              onClick={handleLogout}
              sx={{
                opacity: open ? 1 : 0,
              }}
              primary="Logout"
            />
          </ListItemButton>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
      </Main> */}
    </Box>
  );
}
