import axios from "axios";

export const farmsNames = () => {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user); // Assuming 'user' is stored as a stringified JSON object

    const mobileNumber = user.mobile["mobile"]; // Accessing the 'mobile' key from the 'user' object

    if (mobileNumber) {
      return axios.get(
        `https://3mpqory1se.execute-api.ap-south-1.amazonaws.com/prod/getFarmNames?mobile=${mobileNumber}`
      );
    } else {
      console.log("Mobile number not found in the user object");
      // Handle the case where mobile number is not present in the user object
    }
  } else {
    console.log("User object not found in local storage");
    // Handle the case where the 'user' object is not found in local storage
  }
};
