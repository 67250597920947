import React, { createContext, useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import AuthService from './authService'; // Ensure AuthService is correctly imported
import axios from "axios";

const AuthContext = createContext();
const AWS_URL = process.env.REACT_APP_AWS_LAMBDA_URL
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [bulkUploadData, setBulkUploadData] = useState(null);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      if (!isDataFetched) {
        fetchBulkUploadData(JSON.parse(storedUser).mobile.mobile);
      }
    }
    setIsLoading(false);
  }, []);

  const login = (userData) => {
    // debugger
    setUser(userData);
    localStorage.setItem("user", JSON.stringify(userData));
    if (!isDataFetched) {
      // debugger
      fetchBulkUploadData(userData.mobile.mobile);
      // debugger
    }
  };

  const fetchBulkUploadData = async (mobile) => {
    try {
      // debugger
      // const response = await axios.get(
      //     `${AWS_URL}getReport_bulkUpload_v2?mobile=${mobile}`,
      // )
      // debugger
      const response = await axios.post(
        `${AWS_URL}getReport_bulkUpload_v2`,
        {
          mobile: mobile
        },
        {
          headers: {
            "Content-Type": "application/json", // Ensure the correct content type is set
          },
        }
      );
      // debugger
      setBulkUploadData(response.data.body);
      setIsDataFetched(true)
      if (
        response.data.body?.crop_monitor_data?.length > 0
      ) {
        // console.log("Crop Data : ", response.data.body.crop_monitor_data)
        // debugger
        // response.data.body.crop_monitor_data.filter((entry) =>
        //   filteredDataMemoized.some((farm) => farm.farm_name === entry.farm_name))
        location.pathname === "/" && navigate("/summary");
      } else {
        navigate("/addFirstFarm");
      }
    } catch (error) {
      console.warn("Error fetching bulk upload data:", error);
    }
  };

  const logout = () => {
    // setUser(null);
    setBulkUploadData(null); 
    setIsDataFetched(false); 
    localStorage.removeItem("user");
    navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        isLoading,
        bulkUploadData,
        fetchBulkUploadData,
        setBulkUploadData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
