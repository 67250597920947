import React from "react";

const Optionsfield = (props) => {
  const renderOptions = (option, i) => {
    const isChecked = option.property === props.property;

    return (
      <label
        key={option.index}
        className={`toggle-container ${isChecked ? "checked" : ""}`}
        style={{ width: `25%` }}
      >
        <input
          onChange={() => props.changeState(option.index)}
          checked={isChecked}
          name="toggle"
          type="radio"
        />
        <div
          className={`toggle txt-s py3 toggle--active-white ${
            isChecked ? "active" : ""
          }`}
          style={{ backgroundColor: isChecked ? "#FF6600" : "white" }}
          onMouseEnter={(e) => {
            if (!isChecked) {
              e.target.style.color = "#FF6600";
            }
          }}
          onMouseLeave={(e) => {
            e.target.style.color = null;
          }}
        >
          {option.name}
        </div>
      </label>
    );
  };

  return (
    <div className="absolute round top left ml8 mt8 border bg-white shadow-darken10 border--white border border--2 z1">
      <div className="toggle-group ml8 mt8 bg-white" style={{ width: `100%` }}>
        {props.options.slice(0, 4).map(renderOptions)}
      </div>
      <div className="toggle-group ml8 mt8 bg-white" style={{ width: `100%` }}>
        {props.options.slice(4, 8).map(renderOptions)}
      </div>
    </div>
  );
};

export default Optionsfield;
