import './App.css';
import Allroutes from './routes/Allroutes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthProvider } from './login/authService/AuthContextProvider';
import './i18n'
import { useTranslation } from 'react-i18next';

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightRegular: 400, // Default is 400, you can adjust this
    fontWeightMedium: 500,  // You can adjust this according to your preference
    fontWeightBold: 700, 
  },
});


function App() {
  const { t } = useTranslation()

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
      <Allroutes />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
