import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthService from "../service/auth-service";
import Elailogo from "../Assests/Elailogo.png"
import newDrishti from "../Assests/Drishti.png"

import PhoneIcon from "@mui/icons-material/Phone";
import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import elailogo from "../assets/logo/ELAI LOGOS.png";

function Signup() {
  const navigate = useNavigate();
  const [alignment, setAlignment] = React.useState("/signup");
  const [selectedRole, setSelectedRole] = React.useState("farmer");
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name field is required"),
    email: Yup.string()
      .email("Email is invalid")
      .required("Email field is required"),
    password: Yup.string()
      .required("Password field is required")
      .min(6, "Password must be at least 6 characters"),
    repassword: Yup.string()
      .required("Confirm password field is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    mobile: Yup.string()
      .required("Mobile number field is required")
      .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      password: "",
      repassword: "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);

      AuthService.register(
        values.name,
        values.email,
        values.mobile,
        values.password,
        values.repassword,
        selectedRole
      )
        .then((response) => {
          setSubmitting(false);
          if (response.message === "E-mail Already Exists") {
            setErrors({
              email: "Email already exists. Please use a different one.",
            });
          } else {
            navigate("/");
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrors({
            message: "Registration failed. Please try again.",
          });
          console.error(error);
        });
    },
  });

  const theme = createTheme({
    palette: {
      background: {
        default: "#fafafb",
      },
    },
  });

  const handleChange = (event, newAlignment) => {
    navigate(event.target.value);
    setAlignment(newAlignment);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "rgba(34, 72, 49, 0.9)",
        padding: "0px",
        margin: "0px",
        minHeight: "100vh",
        fontFamily:'open sans'
      }}
    >
      <ThemeProvider theme={theme}>
        <Container component="main" sx={{ display: "flex" }}>
          <CssBaseline />

          <Grid
            container
            spacing={10}
            sx={{ display: "flex", justifyContent: "space-between", width:'100vw' }}
          >
            <Grid item xs={12} md={8}>
            <Box
              style={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "center",
                width: "100%",
                // height: "100%",
                margin: "20px",
                padding: "0px",
                flexDirection: "column",
                // background: 'linear-gradient(135deg, #224831, #3b8d44)',
                borderRadius: "15px",
                // boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)',
                // background: '#ffffff',
                marginTop: "100px",
              }}
            >
              <img
              src={Elailogo}
              style={{width:'150px', height:'250px', marginBottom:'40px'}}
              />
              <Typography
                variant="h1"
                sx={{
                  color: "#ffffff",
                  // textAlign: 'center',
                  fontSize: { xs: "1.7rem", md: "3.2rem" }, // Responsive font size
                  // maxWidth: "80%",
                  lineHeight: "1.5",
                  // fontWeight: 300,
                  fontFamily: "open sans",
                  
                  // textShadow: '7px 2px 4px rgba(0, 0, 0, 0.9)',
                }}
              >
                 Unlocking decision insights from space to positively impact
                 agriculture
              </Typography>
            </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  bgcolor: "white",
                  borderRadius: 3,
                  py: 3,
                  px: 3,
                  boxShadow: 2,
                  margin: "5px 40px 5px 0px",
                  width: "350px",
                }}
              >
                 <img
                src={newDrishti}
                style={{ width: "80px", height: "80px", marginTop:'10px', marginBottom:'10px' }}
              />

                <ToggleButtonGroup
                  color="success"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="/">Signin</ToggleButton>
                  <ToggleButton value="/signup">Signup</ToggleButton>
                </ToggleButtonGroup>
                {/* 
          <Avatar sx={{ m: 1, bgcolor: "#336600", color: "white" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography> */}
                <form onSubmit={formik.handleSubmit} noValidate>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    autoFocus
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    helperText={formik.errors.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    size="small"
                    color="success"
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    helperText={formik.errors.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    size="small"
                    color="success"
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="mobile"
                    label="Mobile Number"
                    name="mobile"
                    autoComplete="tel"
                    onChange={formik.handleChange}
                    value={formik.values.mobile}
                    helperText={formik.errors.mobile}
                    error={
                      formik.touched.mobile && Boolean(formik.errors.mobile)
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                    color="success"
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    helperText={formik.errors.password}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    size="small"
                    color="success"
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="repassword"
                    label="Re-enter Password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.repassword}
                    helperText={formik.errors.repassword}
                    error={
                      formik.touched.repassword &&
                      Boolean(formik.errors.repassword)
                    }
                    size="small"
                    color="success"
                  />

                  <RadioGroup
                    row
                    aria-label="role"
                    name="role"
                    value={selectedRole}
                    onChange={handleRoleChange}
                  >
                    <FormControlLabel
                      value="farmer"
                      control={<Radio color="success" />}
                      label="Farmer"
                    />
                    <FormControlLabel
                      value="banker"
                      control={<Radio color="success" />}
                      label="Banker"
                    />
                  </RadioGroup>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 1,
                      bgcolor: "#fcaf03",
                      "&:hover": {
                        bgcolor: "#224831",
                        color: "#fcaf03",
                      },
                      color: "#224831",
                    }}
                    disabled={formik.isSubmitting}
                  >
                    {formik.isSubmitting ? "Signing up..." : "Sign Up"}
                  </Button>
                  {formik.errors.message && (
                    <Alert severity="error">{formik.errors.message}</Alert>
                  )}
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link to="/" variant="body2">
                        Already have an account? Sign in
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </Box>
  );
}

export default Signup;
