import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_API;

const Map = ({ FarmerData }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const popup = useRef(new mapboxgl.Popup({ closeButton: false }));

  useEffect(() => {
    if (map.current) {
      map.current.remove();
      map.current = null;
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [78.9629, 20.5937],
      zoom: 4,
      attributionControl: false,
    });

    map.current.on("load", () => {
      const overallBounds = new mapboxgl.LngLatBounds();

      map.current.addSource("google-satellite", {
        type: "raster",
        tiles: [
          "https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", // Google Maps Satellite URL
        ],
        tileSize: 256,
      });

      map.current.addLayer({
        id: "google-satellite-layer2",
        type: "raster",
        source: "google-satellite",
        paint: {
          "raster-opacity": 1, // You can control opacity here
        },
      });

      FarmerData.forEach((farm) => {
        if (!farm.coordinates || farm.coordinates.length === 0) return;

        // Filter valid coordinates
        const coordinates = farm.coordinates
          .map(coord => coord && coord.longitude !== undefined && coord.latitude !== undefined
            ? [coord.longitude, coord.latitude]
            : null)
          .filter(Boolean);

        if (coordinates.length === 0) return;

        const farmId = farm._id;

        // Extend overallBounds with each valid coordinate
        coordinates.forEach((coord) => overallBounds.extend(coord));

        // Add polygon layer for farm boundary
        map.current.addLayer({
          id: `${farmId}-background`,
          type: "fill",
          source: {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: [coordinates],
              },
            },
          },
          paint: {
            "fill-color": "#ADD8E6",
            "fill-opacity": 0.4,
          },
        });

        // Add points layer for each coordinate of the farm
        map.current.addLayer({
          id: `${farmId}-points`,
          type: "circle",
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: coordinates.map((coord) => ({
                type: "Feature",
                properties: { farmName: farm.farm_name },
                geometry: {
                  type: "Point",
                  coordinates: coord,
                },
              })),
            },
          },
          paint: {
            "circle-radius": 6,
            "circle-color": "#ff6600",
          },
        });

        // Show farm name on hover
        map.current.on("mouseenter", `${farmId}-points`, (e) => {
          map.current.getCanvas().style.cursor = "pointer";
          const coordinates = e.features[0].geometry.coordinates.slice();
          const farmName = e.features[0].properties.farmName;

          popup.current.setLngLat(coordinates).setHTML(farmName).addTo(map.current);
        });

        map.current.on("mouseleave", `${farmId}-points`, () => {
          map.current.getCanvas().style.cursor = "";
          popup.current.remove();
        });
      });

      // Fit the map to the overall bounding box that contains all farms if it has valid bounds
      if (overallBounds.isEmpty() === false) {
        map.current.fitBounds(overallBounds, { padding: 40 });
      }
    });

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [FarmerData]);

  return (
    <div
      ref={mapContainer}
      style={{
        // flex: 1,
        width: "100%",
        margin:"0px 15px",
        // minHeight: "80vh",
        borderRadius: "10px",
        overflow: "hidden",
        // boxShadow: 4,
        border: "20px solid #224831",
      }}
    />
  );
};

export default Map;
