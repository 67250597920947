import axios from "axios";
import { createContext, useContext } from "react";

const API_URL = "https://sixdhrxo15.execute-api.ap-south-1.amazonaws.com/prod/";

export const FarmContext = createContext();

class AuthService {
  
  login(email, password) {
    const data = JSON.stringify({ email, password });

    const config = {
      method: "post",
      url: API_URL + "loginFarmerUserDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    return axios(config).then((response) => {
      if (response.data.token) {
        return response.data;
      } else {
        return { success: false, message: "Login failed" };
      }
    }).catch((error) => {
      console.warn("Login error", error);
      throw error;
    });
  }

  // logout() {
  //   localStorage.removeItem("user");
  // }

  register(name, email, mobile, password, repassword, selectedRole) {
    var data = JSON.stringify({
      name: name,
      email: email,
      mobile: mobile,
      password: password,
      password2: repassword,
      role: selectedRole,
    });

    var config = {
      method: "post",
      url: API_URL + "registerFarmerUserDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios(config).then((response) => {
      return response.data;
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
