import { styled } from "@mui/material";
import '../i18n'
import { useTranslation } from 'react-i18next';

export const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(4),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-20px`,
      // ...(open && {
      //   transition: theme.transitions.create("margin", {
      //     easing: theme.transitions.easing.easeOut,
      //     duration: theme.transitions.duration.enteringScreen,
      //   }),
      //   marginLeft: 0,
      // }),
      variants: [
        {
          props: ({ open }) => open,
          style: {
            transition: theme.transitions.create("margin", {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
          },
        },
      ],
    })
  );

  export const convertToDateFormat = (dateString, t) => {
    if (!dateString) return t("N/A");
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  export const convertToDateFormat_v2 = (dateString) => {
    if (!dateString || dateString === "None_to_None") return "N/A";
    
    // Split the string into start and end dates (before and after "_to_")
    const [startDateString, endDateString] = dateString.split("_to_");

    // Format both start and end dates
    const startFormatted = convertToDateFormat(startDateString);
    const endFormatted = convertToDateFormat(endDateString);
  
    // Return the formatted range
    return `${startFormatted} to ${endFormatted}`;
  };