import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { CustomButton, TableHeader } from "./Style";
import * as XLSX from "xlsx";
import {
  convertToDateFormat,
  convertToDateFormat_v2,
} from "../utility/Utility";
import SwapVertIcon from "@mui/icons-material/SwapVert";
// import useStyles from "./Style";
import "../i18n";
import { useTranslation } from "react-i18next";
import { useAuth } from "../login/authService/AuthContextProvider";
import axios from "axios";

const AWS_URL = process.env.REACT_APP_AWS_LAMBDA_URL;
export default function EnhancedTable({ data }) {
  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState(data || []);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();

  const [sortedData, setSortedData] = useState(data);
  const [sortConfig, setSortConfig] = useState({
    key: "farm_name",
    direction: "ascending",
  });

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedArray = [...sortedData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setSortedData(sortedArray);
    setSortConfig({ key, direction });
  };

  // Filter data based on the search term
  useEffect(() => {
    if (data.length > 0) {
      const results = data.filter((row) => {
        const farmName = row?.farm_name || "";
        const crop = row?.crop || "";
        return (
          farmName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          crop.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      // setFilteredData(results);
      setSortedData(results);
    }
  }, [searchTerm, data]);

  const getArea = (farmname) => {
    const result = data.filter((row) => row?.farm_name === farmname);
    if (result.length > 0) {
      return (result[0].area * 0.404686).toFixed(2);
    } else {
      return 0;
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNavigate = (farmData) => {
    navigate(`/dashboard/${farmData.mobile}/${farmData.farm_name}`);
  };

  const convertDateFormat = (dateString, t) => {
    if (!dateString) return t("N/A");
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}_${month}_${year}`;
  };

  const convertExcelDateFormat = (dateString, t) => {
    if (!dateString || dateString === "NA") return t("N/A");
    // const date = new Date(dateString);
    // console.log("date : ", date)
    // const day = String(date.getDate()).padStart(2, "0");
    // const month = String(date.getMonth() + 1).padStart(2, "0");
    // const year = date.getFullYear();
    // return `${year}-${month}-${day}`;
    if (typeof dateString === "string") {
      const [day, month, year] = dateString?.split("-");
      return `${year}-${month}-${day}`;
    }
    return t("N/A");
  };

  const handleSangharReportDownload = async () => {
    const currentDate = new Date();
    const dateString = convertDateFormat(currentDate);

    const response = await axios.post(
      `${AWS_URL}fetchWeeklyData`,
      {
        mobile: "9860636637",
      },
      {
        headers: {
          "Content-Type": "application/json", // Ensure the correct content type is set
        },
      }
    );

    if (response && response.data) {
      // console.log("response : ", response);
      // debugger
      const { timestamps, result } = response.data.body;

      const workbook = XLSX.utils.book_new();
      const masterSheetData = [];

      result[0].forEach((farm, farmIndex) => {
        const masterRow = {
          Mobile: Number(farm.Mobile),
          "Farm Name": farm["Farm Name"],
          "Farm Area (ha)": Number(getArea(farm["Farm Name"])),
        };

        timestamps.forEach((timestamp, timestampIndex) => {
          const farmData = result[timestampIndex][farmIndex]; // Get the farm data for the current timestamp

          masterRow[`Sowing Date ${timestamp}`] =
            farmData["Sowing Date"] || "NA";
          masterRow[`Productivity (kg/ha) ${timestamp}`] =
            Number(farmData["Productivity (kg/ha)"]) || 0;
          masterRow[`Total Yield (kg) ${timestamp}`] =
            Number(farmData["Total Yield (kg)"]) || 0;
          masterRow[`Harvest Date ${timestamp}`] =
            convertExcelDateFormat(farmData["Harvest Date"], t) !== "N/A"
              ? new Date(convertExcelDateFormat(farmData["Harvest Date"], t))
              : "NA";
        });

        masterSheetData.push(masterRow);
      });

      // Calculate totals and averages for Productivity and Total Yield columns
      const totals = {};
      const averages = {};

      timestamps.forEach((timestamp) => {
        const productivityColumn = masterSheetData.map(
          (row) => row[`Productivity (kg/ha) ${timestamp}`]
        );
        const totalYieldColumn = masterSheetData.map(
          (row) => row[`Total Yield (kg) ${timestamp}`]
        );

        // Calculate sum and average for Productivity
        totals[`Productivity (kg/ha) ${timestamp}`] = productivityColumn.reduce(
          (acc, val) => acc + val,
          0
        );
        averages[`Productivity (kg/ha) ${timestamp}`] =
          totals[`Productivity (kg/ha) ${timestamp}`] / productivityColumn.length;

        // Calculate sum and average for Total Yield
        totals[`Total Yield (kg) ${timestamp}`] = totalYieldColumn.reduce(
          (acc, val) => acc + val,
          0
        );
        averages[`Total Yield (kg) ${timestamp}`] =
          totals[`Total Yield (kg) ${timestamp}`] / totalYieldColumn.length;
      });

      // Add a row with totals and averages for the master sheet
      const totalRow = { "Farm Name": "Total" };
      timestamps.forEach((timestamp) => {
        totalRow[`Productivity (kg/ha) ${timestamp}`] =
          totals[`Productivity (kg/ha) ${timestamp}`];
        totalRow[`Total Yield (kg) ${timestamp}`] =
          totals[`Total Yield (kg) ${timestamp}`];
      });

      const avgRow = { "Farm Name": "Average" };
      timestamps.forEach((timestamp) => {
        avgRow[`Productivity (kg/ha) ${timestamp}`] =
          averages[`Productivity (kg/ha) ${timestamp}`];
        avgRow[`Total Yield (kg) ${timestamp}`] =
          averages[`Total Yield (kg) ${timestamp}`];
      });

      masterSheetData.push(totalRow);
      masterSheetData.push(avgRow);

      // Create the Master Data Sheet (Data_Analysis)
      const masterWorksheet = XLSX.utils.json_to_sheet(masterSheetData, {
        header: [
          "Mobile",
          "Farm Name",
          "Farm Area (ha)",
          ...timestamps.map((timestamp) => `Sowing Date ${timestamp}`),
          ...timestamps.map((timestamp) => `Productivity (kg/ha) ${timestamp}`),
          ...timestamps.map((timestamp) => `Total Yield (kg) ${timestamp}`),
          ...timestamps.map((timestamp) => `Harvest Date ${timestamp}`),
        ],
      });

      // Append the Master Sheet as the first sheet
      XLSX.utils.book_append_sheet(workbook, masterWorksheet, "Data_Analysis");

      if (timestamps && timestamps.length > 0) {
        timestamps.forEach((timestamp, index) => {
          const sheetData = result[index].map((farm) => ({
            Mobile: Number(farm.Mobile),
            "Farm Name": farm["Farm Name"],
            "Productivity (kg/ha)":
              Number(farm["Productivity (kg/ha)"]) || Number(0),
            "Total Yield (kg)": Number(farm["Total Yield (kg)"]) || Number(0),
            "Sowing Date": farm["Sowing Date"]
              ? new Date(convertExcelDateFormat(farm["Sowing Date"], t))
              : "NA",
            "Harvest Date":
              convertExcelDateFormat(farm["Harvest Date"], t) !== "N/A"
                ? new Date(convertExcelDateFormat(farm["Harvest Date"], t))
                : "NA",
            "Harvest Window": farm["Harvest Window"] || "NA",
            Timestamp: timestamp,
          }));

          // Calculate the totals and averages for this sheet as well
          const sheetTotalRow = { "Farm Name": "Total" };
          const sheetAvgRow = { "Farm Name": "Average" };

          const productivityColumn = sheetData.map(
            (row) => row["Productivity (kg/ha)"]
          );
          const totalYieldColumn = sheetData.map(
            (row) => row["Total Yield (kg)"]
          );

          sheetTotalRow["Productivity (kg/ha)"] = productivityColumn.reduce(
            (acc, val) => acc + val,
            0
          );
          sheetAvgRow["Productivity (kg/ha)"] =
            sheetTotalRow["Productivity (kg/ha)"] / productivityColumn.length;

          sheetTotalRow["Total Yield (kg)"] = totalYieldColumn.reduce(
            (acc, val) => acc + val,
            0
          );
          sheetAvgRow["Total Yield (kg)"] =
            sheetTotalRow["Total Yield (kg)"] / totalYieldColumn.length;

          sheetData.push(sheetTotalRow);
          sheetData.push(sheetAvgRow);

          const worksheet = XLSX.utils.json_to_sheet(sheetData, {
            header: [
              "Mobile",
              "Farm Name",
              "Productivity (kg/ha)",
              "Total Yield (kg)",
              "Sowing Date",
              "Harvest Date",
              "Harvest Window",
              "Timestamp",
            ],
          });

          // Clean up timestamp for valid sheet name
          const sheetName = `Data_${timestamp.replace(
            /[^a-zA-Z0-9]/g,
            "_"
          )}`.substring(0, 20);

          // Append the worksheet to the workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        });

        XLSX.writeFile(
          workbook,
          `ELAI_Report_${user?.mobile?.name}_${dateString}.xlsx`
        );
      }
    }
  };

  const handleReportDownload = async () => {
    const currentDate = new Date();
    const dateString = convertDateFormat(currentDate);
    // console.log("sortedData : ", sortedData)
    // debugger
    const exportData = sortedData.map((row) => ({
      Mobile: row.mobile,
      "Farm Name": row.farm_name,
      "Crop Season": row.crop_season,
      "Productivity (kg/ha)": Number((row.crop_yield * 1000).toFixed(0)),
      "Total Yield (kg)": Number((row.predicted_yield * 1000).toFixed(0)),
      "Farm Area (ha)": Number((row.area * 0.404686).toFixed(2)),
      "Harvest Date": row.harvest_date ? new Date(row.harvest_date) : null,
      "Harvest Window": convertToDateFormat_v2(row.harvest_window),
      "Predicted Crop": row.crop,
      "Predicted Sowing Date": row.sowing_date
        ? new Date(row.sowing_date)
        : null,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Farms_Data`);

    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "Mobile",
          "Farm Name",
          "Crop Season",
          "Productivity (kg/ha)",
          "Total Yield (kg)",
          "Farm Area (ha)",
          "Harvest Date",
          "Harvest Window",
          "Predicted Crop",
          "Predicted Sowing Date",
        ],
      ],
      { origin: "A1" }
    );

    XLSX.writeFile(
      workbook,
      `ELAI_Report_${user?.mobile?.name}_${dateString}.xlsx`
    );
  };

  const handleDownload = async () => {
    if (user?.mobile?.mobile === "9860636637") handleSangharReportDownload();
    else handleReportDownload();
  };

  // const classes = useStyles();
  return (
    <Box>
      <Paper
        sx={{
          height: "100%",
          // mb: 2,
          backgroundColor: "#224831",
          borderRadius: "10px",

          // minWidth:'600px'
          // overflow: 'hidden'
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "right" }}>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "10px",
              color: "#fcaf03",
              borderColor: "#fcaf03",
              marginRight: "10px",
              "&:hover": { color: "#224831", background: "#fcaf03" },
            }}
            onClick={handleDownload}
          >
            {t("Download Excel")}
          </Button>
          <TextField
            variant="standard"
            placeholder={t("Search by farm or crop")}
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
              backgroundColor: "white",
              borderRadius: "10px",
              width: "165px",
              "& .MuiOutlinedInput-root": {
                borderColor: "#FF6600",
                borderRadius: "25px",
                height: "50px",
              },
            }}
          />
        </Toolbar>
        <TableContainer
          sx={{
            maxHeight: 550,
            minHeight: 550,
            backgroundColor: "#ffffff",
            // overflowX: "hidden",
            "&::-webkit-scrollbar": { width: "8px" },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(252, 175, 3, 0.5)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "rgba(252, 175, 3, 0.8)",
            },
          }}
        >
          <Table
            stickyHeader
            // sx={{ minWidth: "700px" }}
            aria-labelledby="tableTitle"
          >
            <TableHead>
              <TableRow>
                <TableHeader
                  align="left"
                  onClick={() => sortData("farm_name")}
                  sx={{ cursor: "pointer", boxShadow: 5 }}
                >
                  {t("Farm Name")}{" "}
                  {sortConfig.key === "farm_name" && <SwapVertIcon />}
                </TableHeader>
                <TableHeader
                  align="left"
                  onClick={() => sortData("crop")}
                  sx={{ cursor: "pointer", boxShadow: 5 }}
                >
                  {t("Crop")} {sortConfig.key === "crop" && <SwapVertIcon />}
                </TableHeader>
                <TableHeader
                  align="left"
                  onClick={() => sortData("sowing_date")}
                  sx={{ cursor: "pointer", boxShadow: 5 }}
                >
                  {t("Sowing Date")}{" "}
                  {sortConfig.key === "sowing_date" && <SwapVertIcon />}
                </TableHeader>
                <TableHeader
                  align="left"
                  onClick={() => sortData("harvest_date")}
                  sx={{ cursor: "pointer", boxShadow: 5 }}
                >
                  {t("Harvest Date")}{" "}
                  {sortConfig.key === "harvest_date" && <SwapVertIcon />}
                </TableHeader>
                <TableHeader align="left" sx={{ boxShadow: 5 }}>
                  {t("Action")}
                </TableHeader>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                color: "#224831",
                backgroundColor: "#ffffff",
                backgroundBlendMode: "hard-light",
                borderColor: "#224831",
                borderWidth: 2,
                borderStyle: "solid",
              }}
            >
              {sortedData.length > 0 ? (
                sortedData.map((row) => (
                  <TableRow
                    hover
                    key={row._id}
                    sx={{
                      borderColor: "#224831",
                      borderWidth: 2,
                      borderStyle: "solid",
                    }}
                  >
                    <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                      {row.farm_name}
                    </TableCell>
                    <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                      {t(`${row.crop}`)}
                    </TableCell>
                    <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                      {convertToDateFormat(row.sowing_date, t)}
                    </TableCell>
                    <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                      {convertToDateFormat(row.harvest_date, t)}
                    </TableCell>
                    <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                      <CustomButton
                        variant="contained"
                        onClick={() => handleNavigate(row)}
                      >
                        {t("Details")}
                      </CustomButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                    sx={{ color: "#224831", boxShadow: 2 }}
                  >
                    {t("No data available")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
