import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

const WeatherChart = ({ farmCoordinates }) => {
  const [weatherData, setWeatherData] = useState({
    temperature: [],
    precipitation: [],
    humidity: [],
    time: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDataset, setSelectedDataset] = useState("Temperature");

  useEffect(() => {
    if (farmCoordinates) {
      const { latitude, longitude } = farmCoordinates;
      getWeatherData(latitude, longitude);
    }
  }, [farmCoordinates]);

  const handleDatasetClick = (dataset) => {
    setSelectedDataset(dataset);
  };

  const getWeatherData = async (latitude, longitude) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://3mpqory1se.execute-api.ap-south-1.amazonaws.com/prod/setsnDatedWeather?lat=${latitude}&lng=${longitude}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const parsedData = typeof data.body === "string" ? JSON.parse(data.body) : data.body;

      if (parsedData && parsedData[0]?.weather?.code === 429001) {
        setError("API rate limit exceeded. Please try again later.");
        return;
      }

      const tempdata = {
        startTime: [],
        temperatureValues: [],
        precipitationValues: [],
        humidityValues: [],
      };

      const reversedData = [...parsedData].reverse();
      reversedData.forEach((d) =>
        d.weather?.data?.timelines[0]?.intervals.forEach((interval) => {
          const timestamp = new Date(interval.startTime);
          const formattedTime = `${timestamp.toLocaleString("default", {
            day: "numeric",
            month: "short",
          })} ${timestamp.getFullYear()}`;

          if (!tempdata.startTime.includes(formattedTime)) {
            tempdata.startTime.push(formattedTime);
            tempdata.temperatureValues.push(interval.values.temperature);
            tempdata.precipitationValues.push(interval.values.precipitationIntensity);
            tempdata.humidityValues.push(interval.values.humidity);
          }
        })
      );

      setWeatherData({
        temperature: tempdata.temperatureValues,
        precipitation: tempdata.precipitationValues,
        humidity: tempdata.humidityValues,
        time: tempdata.startTime,
      });
    } catch (error) {
      console.error(error);
      setError("Failed to fetch weather data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const chartData = {
    labels: weatherData.time, // Use actual dates as labels
    datasets: [
      {
        label: "Temperature",
        data: weatherData.temperature,
        borderColor: "#FF6600",
        pointRadius: 1,
        pointHoverRadius: 3,
        borderWidth: 3,
        barPercentage: 1.6,
        yAxisID: "y",
      },
      {
        label: "Precipitation",
        data: weatherData.precipitation,
        borderColor: "#0D6E9A",
        pointRadius: 1,
        pointHoverRadius: 3,
        borderWidth: 3,
        barPercentage: 1.6,
        yAxisID: "y",
      },
      {
        label: "Humidity",
        data: weatherData.humidity,
        borderColor: "#336600",
        pointRadius: 1,
        pointHoverRadius: 3,
        borderWidth: 3,
        barPercentage: 1.6,
        yAxisID: "y",
      },
    ],
  };

  const filteredData = {
    labels: weatherData.time,
    datasets: chartData.datasets.filter(
      (dataset) => dataset.label === selectedDataset
    ),
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        mode: "nearest",
        callbacks: {
          title: function (tooltipItems) {
            const dataIndex = tooltipItems[0].dataIndex;
            return weatherData.time[dataIndex];
          },
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            return `Value: ${value}`;
          },
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: selectedDataset,
        },
      },
      x: {
        title: {
          display: true,
          text: "Date",
        },
        ticks: {
          autoSkip: false, // Ensure no labels are skipped
        },
      },
    },
  };

  return (
    <div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <button style={{backgroundColor:'#fcaf03',color:'#224831',padding:'10px',borderRadius:'10px'}} onClick={() => handleDatasetClick("Temperature")}>Temperature</button>
        <button style={{backgroundColor:'#fcaf03',color:'#224831',padding:'10px',borderRadius:'10px'}} onClick={() => handleDatasetClick("Precipitation")}>Precipitation</button>
        <button style={{backgroundColor:'#fcaf03',color:'#224831',padding:'10px',borderRadius:'10px'}} onClick={() => handleDatasetClick("Humidity")}>Humidity</button>
      </div>
      <Line data={filteredData} options={chartOptions} />
    </div>
  );
};

export default WeatherChart;