import { responsiveFontSizes } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme)=>({
container:{
    display:'flex',
    // flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    minHeight:'100vh',
    display: "flex",
    backgroundColor: "rgba(34, 72, 49, 0.9)",
    fontFamily: "open sans",
    // minWidth:'100vw',
    // height:'100%'
    margin:'0px'
    // padding:'0px 190px'
},
logo:{
   
    [theme.breakpoints.up("xs")]: {
        width: "25%", 
        height: "27%",
      },
    [theme.breakpoints.up("sm")]: {
        width: "25%", 
        height: "27%",
      },
      [theme.breakpoints.up("md")]: {
        width: "15%", 
        height: "17%",
      },
      
},
box:{
    display:'flex',
    flexDirection:'column'
},
typo:{
    color: "#ffffff",
    fontFamily: "open sans",
    fontSize: "1rem", // Default font size for xs
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5rem", // Font size for sm and up
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem", // Font size for md and up
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "3rem", // Font size for lg and up
    },
},
loginboxes:{
    backgroundColor:'#ffffff',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    // padding:'0px',
    borderRadius:'10px',
    // maxHeight:'100%',
    // maxWidth:'100%'
},
drishti:{
  width: "90px",
  height: "90px",
  marginTop: "20px",
  marginBottom: "15px",
},
button:{
  mt: 2,
  mb: 1,
  backgroundColor:'#fcaf03 !important' ,
  "&:hover": {
    backgroundColor: "#224831 !important",
    color: "#fcaf03 !important" ,
  },
  color: "#224831 !important",
}
}))

export default useStyles;