import React from "react";
import { Card, CardContent, IconButton, Typography } from "@mui/material";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

const InfoCard = ({ title, content, value, onEditClick, editButton }) => (
  <Card
    style={{
      position: 'relative',
      margin: "auto",
      // width: "calc(50% - 10px)",
      // minWidth: "250px",
      // height: "100px",
      // maxWidth:'400px',
      backgroundColor: '#224831', backdropFilter:'blur(10px)', 
      height:'100px', borderRadius:'10px', width:'100%',
      fontFamily: "open sans",
    }}
  >
    {editButton && <IconButton
      onClick={onEditClick} // Function passed as prop for handling the edit click
      sx={{
        position: 'absolute', // Absolute positioning within the Card
        top: '10px',
        right: '10px',
        color: '#fcaf03',
      }}
    >
      <ModeEditOutlineOutlinedIcon />
    </IconButton>}
    <CardContent style={{ textAlign: "left" }}>
      <Typography style={{ fontWeight: "bold", fontSize: "15px" ,color:'#fcaf03'}} variant="h6">
        {title}  {value}
      </Typography>
     
      <Typography style={{  fontsize:"80px",color:'#ffffff', textAlign:'left'}} variant="h6">
        {content}
      </Typography>
    </CardContent>
  </Card>
);

export default InfoCard;
