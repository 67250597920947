// StyledComponents.js
import { Grid, styled } from "@mui/system";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Card, TableCell, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const MyFarmBox = styled(Box)({
  backgroundColor: "#ffffff",
  padding: "1%",
  borderRadius: "10px",
  margin: "1%",
});

// export const CardGrid = styled(Grid)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "space-between",
//   // You can add additional styles here if needed
// }));

export const TitleBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

// export const Logo = styled(Card)({
//   height: "75px",
//   width: "65px",
//   display: "inline-block",
// });

export const TitleName = styled(Typography)({
  textAlign: "left",
  marginLeft: "10px",
  fontSize: "h1",
  color: "#224831",
  marginTop: "0.5%",
  fontWeight: "bold",
  textUnderlineOffset: "6px",
  fontFamily: "open sans",
});

export const SummaryCard = styled(Card)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "left",
  padding: "5%",
  flexDirection: "column",
  height: "48%",
  width: "90%",
  margin: "0.5%",
  borderRadius: "10px",
  backgroundColor: "#224831",
  marginLeft: "3.5%",
});

export const CustomButton = styled(Button)({
  backgroundColor: "#fcaf03",
  borderRadius: "10px",
  color: "#224831",
  ml:"2px"
});

export const TableHeader = styled(TableCell)({
  color: "#fcaf03",
  fontSize: "1.2rem",
  backgroundColor: "#224831",
});

const useStyles = makeStyles(() => ({
//   myFarms: {
//     backgroundColor: "#ffffff",
//     padding: "1%",
//     // width:'95vw',
//     borderRadius: "10px",
//     // marginTop: "5px",
//     margin: "1%",
//     // height:'90vh'
//   },
  cardGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
//   titleBox: {
//     display: "flex",
//     flexDirection: "row",
//   },
  logo: {
    height: "75px",
    width: "65px",
    // marginBottom:'2%'
  },
//   titleName: {
//     textAlign: "left",
//     marginLeft: "10px",
//     fontSize: "h1",
//     color: "#224831",
//     marginTop:'0.5%',

//     fontWeight: "bold",
//     textUnderlineOffset: "6px",

//     fontFamily: "open sans",
//   },
//   summaryCard: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "left",
//     padding: "5%",
//     flexDirection: "column",
//     height: "48%",
//     width:'90%',
//     margin: "0.5%",
//     borderRadius: "10px",
//     backgroundColor: "#224831 !important",
//     marginLeft: "3.5%",
//   },
//   button: {
//     backgroundColor: "#fcaf03 !important",
//     borderRadius: "10px",
//     color: "#224831 !important",
//   },
//   tableHeader:{
//     color: '#fcaf03', fontSize: '1.2rem' , backgroundColor:'#224831'
//   }
}));

export default useStyles;
