// useStyles.js
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container:{
    minheight: '100vh',
    backgroundImage: 'url(/background.png)',
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    backgroundAttachment: 'fixed',
    backgroundRepeat:'no-repeat',
    '@media (max-width: 768px)': {
      backgroundAttachment: 'scroll', 
    },

    '@media (max-width: 480px)': {
      backgroundPosition: 'top', 
    },
  }
  
}));

export default useStyles;
