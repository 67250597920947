// components/Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom'; // Use Outlet to render child routes
import MiniDrawer from './MiniDrawer';

const Layout = () => {
    
 

  return (
    <div style={styles.container}>
     <MiniDrawer  />
      <div style={styles.content}>
        <Outlet /> {/* This will render the child route components */}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
  },
  content: {
    // marginLeft: '250px', // Space for the drawer
    // padding: '20px',
    // width: '100%',
  },
};

export default Layout;
