import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AuthService from "./authService/authService";
import { useAuth } from "./authService/AuthContextProvider";
import {
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import newDrishti from "../assets/img/Drishti.png";
import { Link } from "react-router-dom";
import useStyles from "./Style";

function Signin({ setSignupFlag }) {
  const { login } = useAuth();
  const classes = useStyles();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email is invalid")
      .required("Email field is required"),
    password: Yup.string().required("Password field is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);

      AuthService.login(values.email, values.password)
        .then((response) => {
          setSubmitting(false);
          if (response.success) {
            login(response);
          } else {
            setErrors({
              message: response.message,
            });
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrors({
            message: "Login failed. Please try again.",
          });
          console.warn(error);
        });
    },
  });

  const handleChange = () => {
    setSignupFlag((prevFlag) => !prevFlag);
  };

  return (
    <Container className={classes.loginboxes}>
      <img className={classes.drishti} src={newDrishti} alt="Drishti" />

      <ToggleButtonGroup
        color="success"
        exclusive
        aria-label="Platform"
        sx={{ mb: 3 }}
      >
        <ToggleButton disabled>Signin</ToggleButton>
        <ToggleButton onClick={handleChange}>Signup</ToggleButton>
      </ToggleButtonGroup>

      <form onSubmit={formik.handleSubmit} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={formik.handleChange}
          value={formik.values.email}
          helperText={formik.errors.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          size="small"
          color="success"
          sx={{ mb: 3 }}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          helperText={formik.errors.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          size="small"
          color="success"
          sx={{ mb: 3 }}
        />

        <Button
          className={classes.button}
          type="submit"
          fullWidth
          variant="contained"
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? "Logging in..." : "Log In"}
        </Button>
        {formik.errors.message && (
          <Alert severity="error">{formik.errors.message}</Alert>
        )}
        <Grid container justifyContent="flex-end" mb={2}>
          <Grid item>
            Don't have an account ?{" "}
            <Link variant="body2" onClick={handleChange}>
              Sign Up
            </Link>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default Signin;
