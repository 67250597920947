import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const InfoCard = ({ title, content,value }) => (
  <Card
    style={{
      margin: "auto",
      // width: "calc(50% - 10px)",
      // minWidth: "250px",
      // height: "100px",
      backgroundColor: '#224831', backdropFilter:'blur(10px)', 
      height:'100px', borderRadius:'10px', width:'100%',
      fontFamily: "open sans",
    }}
  >
    <CardContent style={{ textAlign: "left" }}>
      <Typography style={{ fontWeight: "bold", fontSize: "15px" ,color:'#fcaf03'}} variant="h6">
        {title}  {value}
      </Typography>
     
      <Typography style={{  fontsize:"150px",color:'#ffffff', textAlign:'left'}} variant="h5">
        {content}
      </Typography>
    </CardContent>
  </Card>
);

export default InfoCard;
