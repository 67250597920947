import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { FarmContext } from "../contextAPI/FarmContext";
import { useContext } from "react";
import { useMemo } from "react";
import { useAuth } from "../login/authService/AuthContextProvider";
import { convertToDateFormat, convertToDateFormat_v2 } from "../utility/Utility";
import '../i18n'
import { useTranslation } from 'react-i18next';
import SwapVertIcon from '@mui/icons-material/SwapVert';

export default function HarvestTable() {
  // const [selected, setSelected] = React.useState([]);
  // const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [harvestWindow, setHarvestWindow] = useState("");
  const { filteredData, optedCrop } = useContext(FarmContext);
  const { bulkUploadData } = useAuth();
  const { crop_monitor_data: data = [] } = bulkUploadData || {};
  const {t} = useTranslation();

  // const [sortedData, setSortedData] = useState(data);
  const [sortConfig, setSortConfig] = useState({ key: 'farm_name', direction: 'ascending' });

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = filteredData.map((farm) => farm._id.$oid);
  //     setSelected(newSelected);
  //     setIsAllSelected(true);
  //     return;
  //   }
  //   setSelected([]);
  //   setIsAllSelected(false);
  // };

  const filteredDataMemoized = useMemo(() => {
    if (optedCrop) {
      return data.filter((farm) => farm.crop === optedCrop);
    }
    return data;
  }, [optedCrop, data]);

  const sortedFilteredData = useMemo(() => {
    // Sort filtered data based on the current sortConfig
    const sortedArray = [...filteredDataMemoized].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
  
      // Handle sorting for different types of values (string, number, date)
      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  
    return sortedArray;
  }, [filteredDataMemoized, sortConfig]);

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
  
    setSortConfig({ key, direction });
  };
  
  useEffect(() => {
    if (filteredDataMemoized && filteredDataMemoized.length > 0) {
      // console.log("filteredDataMemoized : ", filteredDataMemoized)
      const harvestDates = filteredDataMemoized
        .map((farm) => (farm.harvest_date ? new Date(farm.harvest_date) : null))
        .filter((date) => date && !isNaN(date))
        .sort((a, b) => a - b);

      if (harvestDates.length > 0) {
        const startDate = harvestDates[0].toLocaleDateString("en-US", {
          month: "short",
          year: "numeric",
        });
        const endDate = harvestDates[
          harvestDates.length - 1
        ].toLocaleDateString("en-US", { month: "short", year: "numeric" });
        setHarvestWindow(`${startDate} - ${endDate}`);
      } else {
        setHarvestWindow("N/A");
      }
    } else {
      setHarvestWindow("N/A");
    }
  }, [filteredData]);

  // const handleClick = (event, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }
  //   setSelected(newSelected);
  //   setIsAllSelected(newSelected.length === filteredData.length);
  // };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          // mb: 1,
          backgroundColor: "#224831",
          borderRadius: "10px",
          margin:"0px",
          padding:"0px"
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            sx={{ marginLeft: "-10px", color: "#ffffff" }}
          >
            {t("Harvest Slot")}: {harvestWindow || ""}
          </Typography>
        </Toolbar>
        <TableContainer
          sx={{
            maxHeight: "60vh",
            // minHeight: "100%",
            // minWidth:750,
            backgroundColor:"#ffffff",

            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(252, 175, 3, 0.5)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "rgba(252, 175, 3, 0.8)",
            },
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: "100%" }}
            aria-labelledby="tableTitle"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    color: "#fcaf03",
                    fontSize: "1.2rem",
                    backgroundColor: "#224831",
                    cursor: "pointer",
                    boxShadow: 5
                  }}
                  onClick={() => sortData('farm_name')}
                >
                  {t("Farm Name")} {sortConfig.key === 'farm_name' && <SwapVertIcon/>}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: "#fcaf03",
                    fontSize: "1.2rem",
                    backgroundColor: "#224831",
                    cursor: "pointer",
                    boxShadow: 5
                  }}
                  onClick={() => sortData('harvest_date')}
                >
                  {t("Harvest Date")} {sortConfig.key === 'harvest_date' && <SwapVertIcon/>}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: "#fcaf03",
                    fontSize: "1.2rem",
                    backgroundColor: "#224831",
                    cursor: "pointer",
                    boxShadow: 5
                  }}
                  onClick={() => sortData('harvest_window')}
                >
                  {t("Harvest Window")} {sortConfig.key === 'harvest_window' && <SwapVertIcon/>}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: "#fcaf03",
                    fontSize: "1.2rem",
                    backgroundColor: "#224831",
                    cursor: "pointer",
                    boxShadow: 5
                  }}
                  onClick={() => sortData('predicted_yield')}
                >
                  {t("Total Yield")} {sortConfig.key === 'predicted_yield' && <SwapVertIcon/>}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{
                color: "#224831",
                backgroundColor: "#ffffff",
                backgroundBlendMode: "hard-light",
                borderColor: "#224831",
                borderWidth: 2,
                borderStyle: "solid",
              }}>
              {sortedFilteredData.length > 0 ? (
              sortedFilteredData.map((farm) => (
                <TableRow hover key={farm._id} sx={{
                  borderColor: "#224831",
                  borderWidth: 2,
                  borderStyle: "solid",
                }}>
                  <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                    {farm.farm_name || ""}
                  </TableCell>
                  <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                    {convertToDateFormat((farm.harvest_date), t)}
                  </TableCell>
                  <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                    {convertToDateFormat_v2(farm.harvest_window) || ""}
                  </TableCell>
                  <TableCell sx={{ color: "#224831", boxShadow: 2 }}>
                    {Number(farm.predicted_yield || 0).toFixed(2)} {t("Tonnes")}
                  </TableCell>
                </TableRow>
              ))
            ): (<TableRow>
              <TableCell colSpan={4} align="center" sx={{ color: "#224831", boxShadow: 2 }}>
                {t("No data available")}
              </TableCell>
            </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
