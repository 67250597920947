import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import 'chartjs-adapter-date-fns'; 

const ChartComponentForgeojson = ({ farmInfoUrl, geojsonData, currentLayer, onLayerChange }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  // Load GeoJSON data
  const loadGeoJsonData = async () => {
    if (farmInfoUrl) {
      try {
        const response = await fetch(farmInfoUrl);
        const data = await response.json();

        if (data.features && data.features.length > 0) {
          const parsedData = parseGeoJsonData(data);
          setChartData(parsedData);
        }
      } catch (error) {
        console.error("Error loading GeoJSON:", error);
      }
    }
  };

  const parseGeoJsonData = (geojson) => {
    const timestampValues = {};

    geojson.features.forEach((feature) => {
      const value = feature.properties.value;
      const timestampRange = feature.properties.timestamp;

     
      const startDate = timestampRange.split("_to_")[0];
      const parsedDate = new Date(startDate);

      if (!timestampValues[parsedDate]) {
        timestampValues[parsedDate] = [];
      }
      timestampValues[parsedDate].push(value);
    });

   // har ek pixcel most recnt value , uska mean  t1, okay // ek hi timestamp pr ek pixcel average sare pixcel ke mean pr 
   
    const meanValuesByTimestamp = Object.keys(timestampValues).map((timestamp) => {
      const values = timestampValues[timestamp];
      const meanValue = values.reduce((sum, val) => sum + val, 0) / values.length;
      return {
        timestamp: new Date(timestamp), 
        meanValue,
      };
    });

     
    
    meanValuesByTimestamp.sort((a, b) => a.timestamp - b.timestamp);

    const timestamps = meanValuesByTimestamp.map((item) => item.timestamp);
    const meanValues = meanValuesByTimestamp.map((item) => item.meanValue);

    return {
      labels: timestamps,
      datasets: [
        {
          label: `Mean Values for ${currentLayer}`,
          data: meanValues,
          borderColor: '#336600',
          backgroundColor: 'rgba(256,256,256,0)',
          borderWidth: 2,
          fill: true,
          tension: 0.4, 
         
        },
      ],
    };
  };

 
  useEffect(() => {
    loadGeoJsonData();
  }, [farmInfoUrl, currentLayer]);

  return (
    <div style={{ width: '620px', padding:'40px',backgroundColor:'#ffffff', borderRadius:'10px'}}>
      <Line
      
      height={200}
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              mode: "index",
              intersect: false,
            },
          },
          scales: {
            x: {
              type: "time", // Ensure this is set to 'time' for date parsing
              time: {
                unit: "day", // Change to 'day', 'week', 'month' depending on your data granularity
                tooltipFormat: 'yyyy-MM-dd', // Tooltip date format
              },
              title: {
                display: true,
                text: "Timestamp",
              },
            },
            y: {
              title: {
                display: true,
                text: "Mean Value",
              },
            },
          },
        }}
      />
 </div>
  );
};

export default ChartComponentForgeojson;
