import React from "react";
import Lottie from "react-lottie";
import animationData from "../assets/lotties/animation_lmhskeoe.json"
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const LoadingSmallAnimation = () => {
  return (
    <div style={{margin:"auto", textAlign: "center" ,alignItems:"center"}}>
      <Lottie options={defaultOptions} height={100} width={100} />
    </div>
  );
};

export default LoadingSmallAnimation;
