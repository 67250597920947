import * as React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
import "../i18n";
import { useTranslation } from "react-i18next";

export default function CropToggle({ setCropForm, setIsChecked, isChecked }) {
  const [crop, setCrop] = useState("");
  const {t} = useTranslation();

  const handleChange = (event) => {
    setCrop(event.target.value || "");
    setCropForm(event.target.value || "");
  };

  const crops = [
    "Rice",
    "Wheat",
    "Maize",
    "Cotton",
    "Mustard",
    "White_Onion",
    "Soyabean",
    "Potato",
    "Gram",
  ];

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            sx={{
              color: "#fcaf03",
              "& .MuiCheckbox-checkbox": {
                backgroundColor: "#fcaf03",
              },
              "&.Mui-checked": {
                color: "#fcaf03",
              },
            }}
          />
        }
        label={t("Select Crop")}
        sx={{
          marginTop: "0px",
          color: "#fcaf03",
        }}
      />
      {isChecked && (
        <FormControl
          sx={{
            // mt: 1,
            minWidth: "100%",
            backgroundColor: "#ffffff",
            // height: "20px",
            borderRadius: "5px",
          }}
        >
          <Select
            native
            value={crop}
            onChange={handleChange}
            input={<OutlinedInput />}
            sx={{
              height: "40px",
              backgroundColor: "#ffffff",
            }}
          >
            <option style={{ color: "#224831" }} value={""} disabled hidden>
              {t("Select Crop")}
            </option>
            {crops.map((cropOption) => (
              <option
                key={cropOption}
                style={{ color: "#224831" }}
                value={cropOption}
              >
                {t(`${cropOption}`)}
              </option>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}
