import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AWS_URL = process.env.REACT_APP_AWS_LAMBDA_URL;
const DataTable = () => {
  let user = localStorage.getItem("user");
  user = JSON.parse(user);
  const mobile = user.mobile["mobile"];
  

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("farm_name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData()
    // axios
    //   .get(
    //     `${AWS_URL}getReport_bulkUpload_v2?mobile=${mobile}`
    //   )
    //   .then((response) => {
    //     setData(response.data.body.crop_monitor_data);
        
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data:", error);
    //     setLoading(false);
    //   });
  }, [mobile]);

  const fetchData = async () => {
    const response = await axios.post(
      `${AWS_URL}getReport_bulkUpload_v2`,
      {
        mobile: mobile
      },
      {
        headers: {
          "Content-Type": "application/json", // Ensure the correct content type is set
        },
      }
    );
    // debugger
    setData(response.data.body.crop_monitor_data);
    setLoading(false);
  }
  

  // Handle sorting
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Sorting function
  const stableSort = (array, comparator) => {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
  };

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Render loading spinner
  if (loading) {
    return <CircularProgress />;
  }

  // Paginated and sorted data
  const sortedData = stableSort(data, getComparator(order, orderBy))?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Paper sx={{marginTop:"100px"}}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "farm_name"}
                  direction={orderBy === "farm_name" ? order : "asc"}
                  onClick={() => handleRequestSort("farm_name")}
                >
                  Farm Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "predicted_crop"}
                  direction={orderBy === "predicted_crop" ? order : "asc"}
                  onClick={() => handleRequestSort("predicted_crop")}
                >
                  Predicted Crop
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "crop_season"}
                  direction={orderBy === "crop_season" ? order : "asc"}
                  onClick={() => handleRequestSort("crop_season")}
                >
                  Crop Season
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "predicted_sowing_date"}
                  direction={
                    orderBy === "predicted_sowing_date" ? order : "asc"
                  }
                  onClick={() => handleRequestSort("predicted_sowing_date")}
                >
                  Predicted Sowing Date
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row.farm_name}</TableCell>
                <TableCell>{row.crop}</TableCell>
                <TableCell>{row.crop_season}</TableCell>
                <TableCell>
                  {new Date(row.sowing_date).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() =>
                      navigate(`/dashboard/${row.mobile}/${row.farm_name}`)
                    }
                  >
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DataTable;