// Allroutes.js
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Map from "../newFarm/DrawFarmMap";
import Dashboard from "../dashboard/Dashboard";
// import Banker from "./Banker";
import CropYeildPren from "../void/CropYeildPren";
import PrivateRoute from "./PrivateRoute";
import UnauthorizedPage from "./UnauthorizedPage";
import NotFoundPage from "./NotFoundPage";
import Report from "../report/Report";
import {
  AuthProvider,
  useAuth,
} from "../login/authService/AuthContextProvider";
import Layout from "../layout/Layout"; // Import the Layout with Drawer
import Summary from "../summary/Summary";
import NewUser from "./NewUser";
import Banker from "../void/Banker";
import Login from "../login/Login";
import { FarmProvider } from "../contextAPI/FarmContext";
import UserBulkUploadData from "../newFarm/UserBulkUploadData";
import Onboarding from "../sugarcane/Onboarding";
import DashboardBeta from "../sugarcane/DashboardBeta";

const Allroutes = () => {

  return (
    <AuthProvider>
      <FarmProvider>
        <Routes>
          {/* No Layout for Signin, Signup, Unauthorized */}
          <Route path="/" element={<Login />} />
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          <Route path="/addFirstFarm" element={<NewUser />} />

          {/* Routes with Layout (contains Drawer) */}
          <Route element={<Layout />}>
            <Route element={<PrivateRoute allowedRoles={["farmer"]} />}>
              <Route path="/map" element={<Map />} />
            </Route>

            {/* <Route element={<PrivateRoute allowedRoles={["farmer"]} />}>
              <Route path="/drawfarm_beta" element={<Onboarding />} />
            </Route> */}

            {/* <Route element={<PrivateRoute allowedRoles={["farmer"]} />}>
            <Route path="/addFirstFarm" element={<NewUser />} />
            </Route> */}
            <Route element={<PrivateRoute allowedRoles={["farmer"]} />}>
              <Route
                path="/userbulkuploaddata"
                element={<UserBulkUploadData />}
              />
            </Route>

            <Route element={<PrivateRoute allowedRoles={["farmer"]} />}>
              <Route path="/summary" element={<Summary />} />
            </Route>

            {/* <Route element={<PrivateRoute allowedRoles={["banker"]} />}>
              <Route path="/banker" element={<Banker />} />
            </Route> */}

            {/* <Route
              element={<PrivateRoute allowedRoles={["banker", "farmer"]} />}
            >
              <Route path="/report" element={<Report />} />
            </Route> */}

            <Route
              element={<PrivateRoute allowedRoles={["banker", "farmer"]} />}
            >
            <Route
              path="/dashboard/:mobile/:farm_name"
              element={<Dashboard />}
            />
            </Route>
            {/* <Route
              path="/dashboard_beta/:mobile/:farm_name"
              element={<DashboardBeta />}
            /> */}
            {/* <Route
              path="/cropdetails/:State/:District/:Taluka/:Village/:Survey_No/:Farm_No/:Farm_Area/:from_date/:to_date"
              element={<CropYeildPren />}
            /> */}
          </Route>

          {/* Fallback route */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </FarmProvider>
    </AuthProvider>
  );
};

export default Allroutes;
