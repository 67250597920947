import React from 'react'
// import EnhancedTable from '../components/ReactTable'
import DataTable from './DataTable'

const Report = () => {
  return (
    <DataTable/>
  )
}

export default Report