import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import authService from "../service/auth-service";
import logo from "../assets/logo/ELAI LOGOS.png";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SpeedIcon from "@mui/icons-material/Speed";
import BarChartIcon from "@mui/icons-material/BarChart";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import HelpIcon from "@mui/icons-material/Help";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "@mui/material/Button";
import { useAuth } from "../context/AuthContextProvider";
import { useNavigate } from "react-router-dom";
import { farmsNames } from "../apis/apis";
import LogoutIcon from "@mui/icons-material/Logout";
// import Drishtilogo from "../Assests/Drishtilogo.png"
import newDrishti from "../Assests/newDrishti-cropped.svg"
const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    variants: [
      {
        props: ({ open }) => open,
        style: {
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
        },
      },
    ],
  })
);
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
export default function PersistentDrawerLeft() {
  // const [farmNames, setFarmNames] = React.useState([]);
  // const [selectedItem, setSelectedItem] = React.useState(null);
  // const [selectedItemfield, setselectedItemfield] = React.useState(null);

  // let user = localStorage.getItem("user");

  // user = JSON.parse(user); // Assuming 'user' is stored as a stringified JSON object

  // const mobile = user.mobile["mobile"];
  // React.useEffect(() => {
  //   farmsNames().then((data) => setFarmNames(data.data));
  // }, []);
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const handleListItemClickfield = (farm_name,index) => {
  //   setselectedItemfield(index);
  //   navigate(`/dashboard/${mobile}/${farm_name}`);
  // };

  const handleAddnewFarm = () => {
    navigate("/map");
  };

  const hanldeLogout = () => {
    authService.logout();
    navigate("/");
  };

  const handleNavigation = (index) => {
    switch (index) {
      case 0:
        navigate("/summary");
        break;
      case 1:
        navigate("/report");
        break;
      default:
        navigate("/");
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline/>
      <AppBar open={open} sx={{ backgroundColor: "#ffffff" }}>
        <Toolbar>
          <IconButton
            color="#000000"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                mr: 2,
                mb: 0,
              },
              open && { display: "none" },
            ]}
          >
            <MenuIcon />
          </IconButton>
          
         
          <Avatar
            alt=""
            src={'../Assests/profile.jpg'}
            sx={{ marginRight: "10px" }}
          />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ color: "#000000" }}
          >
            welcome
          </Typography>
          <img
          src={newDrishti}
          alt="Drishti"
          style={{height:'90px', width:'85px',marginLeft:'500px' }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "14px",
              marginLeft: "auto",
            }}
          >
            
            {/* <TextField
              variant="outlined"
              label="Farm Name or Farm ID"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchOutlinedIcon sx={{ color: "#FF6600" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  "&::placeholder": {
                    color: "#ff6600",
                    opacity: 1,
                  },
                },
              }}
              sx={{
                width: "310px",
                "& .MuiOutlinedInput-root": {
                  borderColor: "#FF6600",
                  borderRadius: "25px",
                  height: "50px",
                  "& fieldset": {
                    borderColor: "#FF6600",
                  },
                  "&:hover fieldset": {
                    borderColor: "#FF6600",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#FF6600",
                  },
                  "& input::placeholder": {
                    color: "#FF6600",
                    opacity: 1,
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#FF6600",
                },
              }}
            /> */}
            <Button
              onClick={handleAddnewFarm}
              variant="contained"
              startIcon={<AddOutlinedIcon sx={{ color: "#224831" }} />}
              sx={{
                borderRadius: "10px",
                padding: "12px 14px",
                color: "#224831",
                // borderColor: "#fcaf03",
                // borderWidth: "2px",
                borderStyle: "solid",
               backgroundColor:'#fcaf03'
               
              }}
            >
              Add new Farm
            </Button>
            
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#ffffff",
            color: "#000000",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0 8px",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <IconButton onClick={handleDrawerClose} sx={{ color: "#fcaf03" }}>
            {theme.Direction === "ltr" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              flexGrow: 1,
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                display: "block",
                width: "80%",
                maxWidth: "70px",
                margin: "0px 50px 0px 20px",
              }}
            />
          </div>
        </div>
        <Divider />
        <List>
          {["Dashboard", "Report"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => handleNavigation(index)} sx={{"&:hover": {
                backgroundColor: "#224831",
                color: "whitesmoke", // Change the background color on hover
              },}}>
                <ListItemIcon sx={{ color: "#fcaf03" }}>
                  {index === 0 ? (
                    <SpeedIcon sx={{ color: "inherit" }} />
                  ) : index === 1 ? (
                    <BarChartIcon sx={{ color: "inherit" }} />
                  ) : index === 2 ? (
                    <DescriptionOutlinedIcon sx={{ color: "inherit" }} />
                  ) : (
                    <NotificationsIcon sx={{ color: "inherit" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ color: "inherit" }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        {/* <List>
        {['Setting', 'Account', 'Help'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon sx={{ color: '#FF6600' }}>
                {index === 0 ? <SettingsIcon sx={{ color: 'inherit' }} /> :
                  index === 1 ? <HomeIcon sx={{ color: 'inherit' }} /> :
                    <HelpIcon sx={{ color: 'inherit' }} />}
              </ListItemIcon>
              <ListItemText primary={text} sx={{ color: 'inherit' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}

        {/* <List>
          {farmNames.map((farm_name, index) => (
            <ListItem key={farm_name} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 2,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {index + 1}
                </ListItemIcon>
                <ListItemText
                  onClick={() => {
                    handleListItemClickfield(farm_name,index);
                  }}
                  primary={farm_name}
                  sx={{
                    opacity: open ? 1 : 0,
                    color: selectedItemfield === index ? "green" : "black",
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}

        <List>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              "&:hover": {
                backgroundColor: "#224831",
                color: "whitesmoke", // Change the background color on hover
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 2,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: "#fcaf03",
              }}
            >
              {<LogoutIcon />}
            </ListItemIcon>
            <ListItemText
              onClick={hanldeLogout}
              sx={{
                opacity: open ? 1 : 0,
              }}
              primary="Logout"
            />
          </ListItemButton>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
      </Main>
    </Box>
  );
}
