import {
  Box,
  Button,
  Dialog,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Stack,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { TableHeader } from "../summary/Style";
import { FarmContext } from "../contextAPI/FarmContext";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import SaveIcon from "@mui/icons-material/Save";
import { convertToDateFormat } from "../utility/Utility";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import '../i18n'
import { useTranslation } from 'react-i18next';

export default function EditCropModel({ mobile, farm_name }) {
  const AWS_URL = process.env.REACT_APP_AWS_LAMBDA_URL;
  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState("");
  const [newSeason, setNewSeason] = useState(false);
  const [sowingDate, setSowingDate] = useState("");
  const { geojsonData } = useContext(FarmContext);
  const currentYear = new Date().getFullYear();
  const [date, setDate] = useState(31); // Default value
  const [month, setMonth] = useState(1); // Default value
  const [year, setYear] = useState(currentYear); // Default to current year
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const {t} = useTranslation();

  const navigate = useNavigate();

  const [rows, setRows] = useState([
    {
      mobile: mobile,
      farm_name: farm_name,
      crop: geojsonData?.crop,
      sowing_date: geojsonData?.sowing_date,
      crop_season: geojsonData?.crop_season,
      new: false,
    },
  ]);

  const validateInput = async () => {
    const formattedDate = `${year}-${String(month).padStart(2, "0")}-${String(date).padStart(2, "0")}`;
    alert(`Crop Submitted successfully. Please wait 24 to see the changes.`);
    setOpen((prevState) => !prevState);

    const farmData = {
      mobile: mobile,
      farm_name: farm_name,
      date: formattedDate,
      crop: crop,
    };

    try {
      // // debugger;
      const response = await axios.post(`${AWS_URL}createFarm_v3`, farmData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = response.data;
      if (responseData.statusCode === 200) {
        if (responseData.body === "Database operation failed") {
          // setShowSuccessAlert(false);
          alert("Something went wrong! Please try again");
        } else if (responseData.body === "API queries successful") {
          // Skip health score check if API queries are successful
          navigate(`/dashboard/${mobile}/${farm_name}`);
        } else if (responseData.body === "Farm created successfully") {
          // Skip health score check if API queries are successful
          navigate(`/dashboard/${mobile}/${farm_name}`);
        } else if (responseData.body === "no data") {
          // Handle the case when no data is available
          // setShowSuccessAlert(false);
          alert("Something went wrong! Please try again");
        }
      } else if (responseData.statusCode === 504) {
        // Handle timeout scenario: Retry fetching data until health score is available
        fetchDataUntilHealthScore(mobile, farm_name);
      }
    } catch (error) {
      if (error.response?.status === 504) {
        fetchDataUntilHealthScore(mobile, farm_name);
      } else {
        // setShowSuccessAlert(false);
        alert("Something went wrong! Please try again");
        console.warn("Error saving farm data:", error);
      }
    }
  };

  const fetchDataUntilHealthScore = async (mobile, farmName) => {
    const timeout = new Promise((_, reject) =>
      setTimeout(
        () => navigate(`/dashboard/${mobile}/${farmName}`),
        // reject("Something went wrong, please try again"),
        240000
      )
    );

    try {
      let result = await axios.post(
        `${AWS_URL}getCropMonitorData_v2`,
        {
          mobile: mobile,
          farm_name: farmName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Check if health_score is available; if not, continue fetching
      while (!result?.data?.result?.health_score) {
        result = await Promise.race([
          new Promise((resolve) => setTimeout(resolve, 3000)), // Delay before next request
          timeout,
        ]);

        if (result !== timeout) {
          result = await axios.post(
            `${AWS_URL}getCropMonitorData_v2`,
            {
              mobile: mobile,
              farm_name: farmName,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }
      }

      navigate(`/dashboard/${mobile}/${farmName}`);
    } catch (error) {
      // Timeout or other errors during fetching data
      // setShowSuccessAlert(false);
      alert("Something went wrong! Please try again");
      console.warn("Error fetching crop monitor data:", error);
    }
  };

  const navigateFarm = (farmName) => {
    navigate(`/dashboard/${mobile}/${farmName}`);
  };

  const saveCrop = () => {
    alert(`Crop Submitted successfully. Please wait 24 to see the changes.`);
    setOpen((prevState) => !prevState);
  };

  const addNewCropCycle = () => {
    // alert(`Are you sure you want to add a new crop cycle for existing farm`);
    if (!newSeason) {
      setNewSeason((prevState) => !prevState);

      setRows((prevRows) => [
        ...prevRows,
        {
          mobile: mobile,
          farm_name: `${farm_name}_S${rows.length}`,
          crop: crop,
          sowing_date: "",
          crop_season: "",
          new: true,
        },
      ]);
    }
    // setOpen((prevState) => !prevState)
  };

  const handleChange = (event) => {
    const userConfirmed = window.confirm(
      `Are you sure you want to set the crop as ${event.target.value}?`
    );

    if (userConfirmed) {
      setCrop(event.target.value || "");
      // console.log("Crop selected : ", event.target.value);
    } else {
      // setSelectCrop((prevState) => !prevState);
      // console.log("User cancelled");
    }
  };

  // const handleDateChange = (e) => {
  //   // console.log("newDate : ", e.target.value);
  //   debugger
  //   let newDate = e.target.value;
  //   setSowingDate(newDate);
  // };

  const validateDate = () => {
    const maxDays = getDaysInMonth(month, year);
    if (date > maxDays) {
      setDate(maxDays); // Adjust the date to the maximum valid day
    }
  };

  // Call validateDate whenever date, month, or year changes
  useEffect(() => {
    validateDate();
  }, [date, month, year]);

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  // Function to get the number of days in the selected month/year
  const getDaysInMonth = (month, year) => {
    if (month === 2 && isLeapYear(year)) {
      return 29; // February in a leap year has 29 days
    }
    return daysInMonth[month - 1]; // Regular month days
  };

  // const DateInput = () => {
  //   return (
  //     <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
  //       <input
  //         type="number"
  //         value={date}
  //         min="1"
  //         max="31"
  //         onChange={(e) =>
  //           setDate(Math.max(1, Math.min(31, Number(e.target.value))))
  //         }
  //         style={{ width: "50px", padding: "2px", textAlign: "center" }}
  //       />
  //       <input
  //         type="number"
  //         value={month}
  //         min="1"
  //         max="12"
  //         onChange={(e) =>
  //           setMonth(Math.max(1, Math.min(12, Number(e.target.value))))
  //         }
  //         style={{ width: "50px", padding: "2px", textAlign: "center" }}
  //       />
  //       <input
  //         type="number"
  //         value={year}
  //         min="2000"
  //         max={currentYear}
  //         onChange={(e) =>
  //           setYear(
  //             Math.max(2000, Math.min(currentYear, Number(e.target.value)))
  //           )
  //         }
  //         style={{ width: "60px", padding: "2px", textAlign: "center" }}
  //       />
  //     </div>
  //   );
  // };

  const formatDateForDisplay = (date) => {
    if (!date) return "";
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  const DateInput = () => {
    const handleDateChange = (e) => {
      const newDate = e.target.value; // This will be in yyyy-mm-dd
      setSowingDate(newDate);
    };

    return (
      <TextField
        // label="Sowing Date"
        value={sowingDate}
        onChange={handleDateChange}
        fullWidth
        type="date"
        InputLabelProps={{ shrink: true }}
        variant="outlined"
        margin="10px" // Reduced margin
        size="small"
        color="success"
        sx={{
          backgroundColor: "#ffffff",
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#fcaf03", // Label color when typing
          },
          borderRadius: "5px",
        }}
      />
    )};
  //     // <LocalizationProvider dateAdapter={AdapterDateFns}>
  //     //   <DatePicker
  //     //      label="Sowing Date"
  //     //     value={sowingDate}
  //     //     onChange={handleSowingDateChange}
  //     //     renderInput={(params) => (
  //     //       <TextField
  //     //         {...params}
  //     //         variant="outlined"
  //     //         size="small"
  //     //         color="success"
  //     //         sx={{
  //     //           marginTop: "0px",
  //     //           padding: "0px",
  //     //           height: "30px",
  //     //           backgroundColor: "#ffffff",
  //     //           "& .MuiInputLabel-root.Mui-focused": {
  //     //             color: "#fcaf03", // Label color when typing
  //     //           },
  //     //           borderRadius: "5px",
  //     //         }}
  //     //       />
  //     //     )}
  //     //   />
  //     // </LocalizationProvider>
  //   );
  // };

  {
    /* <DateCalendar value={sowingDate} onChange={handleSowingDateChange} /> */
  }
  // //   <DatePicker
  //     // label="Sowing Date"
  //     value={sowingDate}
  //     onChange={handleSowingDateChange}
  //     renderInput={(params) => (
  //       <TextField
  //         {...params}
  //         variant="outlined"
  //         size="small"
  //         color="success"
  //         sx={{
  //           marginTop: "0px",
  //           padding: "0px",
  //           height: "30px",
  //           backgroundColor: "#ffffff",
  //           "& .MuiInputLabel-root.Mui-focused": {
  //             color: "#fcaf03", // Label color when typing
  //           },
  //           borderRadius: "5px",
  //         }}
  //       />
  //     )}
  //   />

  const SelectDropdown = ({ value, onChange }) => {
    const crops = [
      "Rice",
      "Wheat",
      "Maize",
      "Cotton",
      "Mustard",
      "White_Onion",
      "Soyabean",
      "Potato",
      "Gram",
    ];

    return (
      <Select
        native
        value={value}
        onChange={onChange}
        input={<OutlinedInput label="crop" id="demo-dialog-native" />}
        sx={{
          height: "30px",
          color: "#224831",
        }}
      >
        <option
          key={"null"}
          style={{ color: "#224831" }}
          value={""}
          disabled
          hidden
        >
          Select Crop
        </option>
        {crops.map((crop) => (
          <option key={crop} style={{ color: "#224831" }} value={crop}>
            {crop}
          </option>
        ))}
      </Select>
    );
  };

  return (
    <Box>
      <Button
        variant="outlined"
        sx={{
          height: "50px",
          color: "#224831",
          borderColor: "#224831",
          borderRadius: "10px",
          "&:hover": { background: "#fcaf03" },
        }}
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <AddToPhotosIcon />
      </Button>
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={() => setOpen((prevState) => !prevState)}
        sx={{
          color: "#fcaf03",
          borderRadius: "50px",
          position: "fixed",
          left: "0%",
          top: "0%",
        }}
        PaperProps={{
          sx: {
            border: "1px solid #fcaf03",
            borderRadius: "20px",
            overflow: "hidden",

            maxHeight: 550,
            minHeight: 550,
            minWidth: 1000,
            maxWidth: 1000,
          },
        }}
      >
        <TableContainer
          sx={{
            maxHeight: 500,
            minHeight: 500,
            minWidth: 1000,
            maxWidth: 1000,
            "&::-webkit-scrollbar": { width: "8px" },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(252, 175, 3, 0.5)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "rgba(252, 175, 3, 0.8)",
            },
          }}
        >
          <Table stickyHeader aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                <TableHeader align="left">Sr No</TableHeader>
                <TableHeader align="left">Mobile</TableHeader>
                <TableHeader align="left">Farm Name</TableHeader>
                <TableHeader align="left">Crop</TableHeader>
                <TableHeader align="left">Sowing Date</TableHeader>
                <TableHeader align="left">Crop Season</TableHeader>
                <TableHeader align="left">Load</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow hover key={index}>
                  <TableCell sx={{ color: "#224831" }}>{index + 1}</TableCell>
                  <TableCell sx={{ color: "#224831" }}>{row.mobile}</TableCell>
                  <TableCell sx={{ color: "#224831" }}>
                    {row.farm_name}
                    {/* {newSeason ? (`${row.farm_name}`) : (`${row.farm_name+`_S${index}` }`) } */}
                  </TableCell>
                  <TableCell sx={{ color: "#224831" }}>
                    {row.crop ? (
                      row.crop
                    ) : (
                      <SelectDropdown value={crop} onChange={handleChange} />
                    )}
                  </TableCell>
                  <TableCell sx={{ color: "#224831", padding: "0px" }}>
                    {row.sowing_date ? (
                      convertToDateFormat(row.sowing_date, t)
                    ) : (
                      <DateInput />
                    )}
                  </TableCell>
                  <TableCell sx={{ color: "#224831" }}>
                    {row.crop_season}
                  </TableCell>
                  <TableCell sx={{ color: "#224831" }}>
                    {!row.new ? (
                      <SaveIcon
                        onClick={() => navigateFarm(row.farm_name)}
                        sx={{
                          color: "#fcaf03",
                          "&:hover": {
                            color: "#224831", // Change to a different color on hover
                            cursor: "pointer", // Change cursor to pointer on hover
                          },
                        }}
                      />
                    ) : (
                      <SaveIcon sx={{ color: "grey" }} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
          spacing={2}
          direction="row"
          sx={{
            alignContent: "space-around",
            left: "90%",
            top: "90%",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              width: "140px",
              height: "30px",
              color: "#224831",
              borderColor: "#224831",
              borderRadius: "10px",
              "&:hover": { background: "#fcaf03" },
            }}
            disabled={newSeason}
            onClick={addNewCropCycle}
          >
            Add New Cycle
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: "140px",
              height: "30px",
              color: "#224831",
              borderColor: "#224831",
              borderRadius: "10px",
              "&:hover": { background: "#fcaf03" },
            }}
            onClick={saveCrop}
          >
            Save
          </Button>
        </Stack>
        {/* </Paper> */}
      </Dialog>
    </Box>
  );
}
