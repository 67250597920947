import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import * as turf from "@turf/turf";
import {
  Button,
  TextField,
  Grid,
  Box,
  Alert,
  Typography,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
// import CircularProgress from "@mui/material/CircularProgress";
import FileUpload from "./FileUpload";
// import { useAuth } from "../login/authService/AuthContextProvider";
import { FarmContext } from "../contextAPI/FarmContext";
import CropToggle from "./CropToggle";
import axios from "axios";
// import SatelliteGif from '../assets/img/satellite.gif';
import waitingVid from "../assets/img/waiting.mp4";
import "../i18n";
import { useTranslation } from "react-i18next";
import { useAuth } from "../login/authService/AuthContextProvider";

const AWS_URL = process.env.REACT_APP_AWS_LAMBDA_URL;
const Map = () => {
  // const [crop, setCrop] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const [area, setArea] = useState(0);
  const [mobile, setMobile] = useState("");
  const [farmName, setFarmName] = useState("");
  const [sowingDate, setSowingDate] = useState("");
  const [areaInAcres, setAreaInAcres] = useState(0);
  const [crop, setCrop] = useState("");
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const drawRef = useRef(null);
  const navigate = useNavigate();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  // const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [message, setMessage] = useState("");
  const {t} = useTranslation();
  const { user } = useAuth()
  // const { fetchGeojsonData, healthScore } = useContext(FarmContext);

  // const handleNavgateTobulkfile = () => {
  //   navigate("/userbulkuploaddata");
  // };

  // const { setBulkUploadData } = useAuth();
  const {
    setGeojsonData,
    setMultiIndexStressResults,
    setFarmInfoUrl,
  } = useContext(FarmContext);

  useEffect(() => {
    // setShowSuccessAlert(true);
    // setBulkUploadData(null); // Clear bulk upload data
    setFarmInfoUrl("");
    setGeojsonData({});
    // setHealthScore(0);
    // setHealthCategory("N/A");
    setMultiIndexStressResults([]);
    setMobile(user?.mobile?.mobile);
  }, []);

  const handleMobileChange = useCallback((event) => {
    const value = event.target.value.replace(/\s/g, "");
    setMobile(value);
  }, []);

  const handleFarmNameChange = useCallback((event) => {
    const value = event.target.value.replace(/\s/g, "");
    setFarmName(value);
  }, []);

  const handleSowingDateChange = useCallback((event) => {
    setSowingDate(event.target.value);
  }, []);

  const handleSubmit = async (event) => {
    //   if(areaInAcres<0.5)
    //   setMessage("Area must be greater than 0.5 acres")
    // else{
    setShowSuccessAlert(true);
    event.preventDefault();

    const farmData = {
      mobile: mobile,
      farm_name: farmName,
      date: sowingDate,
      coordinates: coordinates.map((coord) => ({
        latitude: coord[1],
        longitude: coord[0],
      })),
      area: areaInAcres,
      crop: isChecked ? crop : null,
    };

    const coordsNew = coordinates.map((coord) => ({
      latitude: coord[1],
      longitude: coord[0],
    }));

    try {
      // // debugger;
      const response = await axios.post(`${AWS_URL}createFarm_v3`, farmData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = response.data;
      if (responseData.statusCode === 200) {
        if (responseData.body === "Database operation failed") {
          setShowSuccessAlert(false);
          alert("Something went wrong! Please try again");
        } else if (responseData.body === "API queries successful") {
          // Skip health score check if API queries are successful
          navigate(`/dashboard/${mobile}/${farmName}`);
        } else if (responseData.body === "Farm created successfully") {
          // Skip health score check if API queries are successful
          navigate(`/dashboard/${mobile}/${farmName}`);
        } else if (responseData.body === "no data") {
          // Handle the case when no data is available
          setShowSuccessAlert(false);
          alert("Something went wrong! Please try again");
        }
      } else if (responseData.statusCode === 504) {
        // Handle timeout scenario: Retry fetching data until health score is available
        fetchDataUntilHealthScore(mobile, farmName);
      }
    } catch (error) {
      if (error.response?.status === 504) {
        fetchDataUntilHealthScore(mobile, farmName);
      } else {
        setShowSuccessAlert(false);
        alert("Something went wrong! Please try again");
        console.warn("Error saving farm data:", error);
      }
    }
  };

  const fetchDataUntilHealthScore = async (mobile, farmName) => {
    const abortController = new AbortController();
    const timeoutSignal = abortController.signal;

    let hasNavigated = false;
    let alertShown = false;

    const timeout = new Promise((_, reject) => {
      const timer = setTimeout(() => {
        if (!timeoutSignal.aborted && !hasNavigated) {
          alertShown = true;
          setShowSuccessAlert(false);
          alert("Server taking too much time... Please try again!");
          // reject(new Error("Navigation triggered"));
        }
      }, 90000);

      timeoutSignal.addEventListener("abort", () => {
        clearTimeout(timer);
      });
    });

    try {
      let result = await axios.post(
        `${AWS_URL}getCropMonitorData_v2`,
        {
          mobile: mobile,
          farm_name: farmName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          signal: timeoutSignal,
        }
      );

      // Check if health_score is available; if not, continue fetching
      while (!result?.data?.result?.health_score) {
        result = await Promise.race([
          new Promise((resolve) => setTimeout(resolve, 3000)), // Delay before next request
          timeout,
        ]);

        if (result !== timeout) {
          result = await axios.post(
            `${AWS_URL}getCropMonitorData_v2`,
            {
              mobile: mobile,
              farm_name: farmName,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              signal: timeoutSignal,
            }
          );
        }
      }
      hasNavigated = true;
      if (!alertShown)
        // Once health score is found, navigate to the dashboard
        navigate(`/dashboard/${mobile}/${farmName}`);
    } catch (error) {
      // Timeout or other errors during fetching data
      setShowSuccessAlert(false);
      alert("Something went wrong! Please try again");
      console.warn("Error fetching crop monitor data:", error);
    }
  };

  const getUserLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userLocation = [longitude, latitude];

          mapRef.current.setCenter(userLocation);
          new mapboxgl.Marker().setLngLat(userLocation).addTo(mapRef.current);
        },
        (error) => {
          console.warn("Error getting user location:", error);
        }
      );
    } else {
      console.warn("Geolocation is not supported by this browser.");
    }
  }, []);

  const updateCoordinates = useCallback(() => {
    const drawData = drawRef.current.getAll();
    if (drawData.features.length) {
      const drawnPolygon = drawData.features[0].geometry.coordinates[0];
      setCoordinates(drawnPolygon);
      calculateArea(drawnPolygon);
    }
  }, []);

  const calculateArea = useCallback((polygon) => {
    if (polygon.length >= 3) {
      const areaSquareMeters = turf.area({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [polygon],
        },
      });

      const areaAcres = areaSquareMeters / 4046.86;
      setArea(areaSquareMeters);
      setAreaInAcres(areaAcres.toFixed(2));
      if (areaAcres < 0.5) setMessage("Area must be greater than 0.5 acres");
    }
  }, []);

  const isFormValid = useMemo(() => {
    // if(areaInAcres<0.5) setMessage("Area must be greater than 0.5 acres")
    return (
      mobile.trim() !== "" &&
      mobile.length === 10 &&
      farmName.trim() !== "" &&
      sowingDate.trim() !== "" &&
      areaInAcres >= 0.5
    );
  }, [mobile, farmName, sowingDate, areaInAcres]);

  useEffect(() => {
    const coordinatesGeocoder = (query) => {
      const matches = query.match(/^(\-?\d+\.\d+)\s*,\s*(\-?\d+\.\d+)$/);
      if (!matches) {
        return null;
      }

      const lng = parseFloat(matches[2]);
      const lat = parseFloat(matches[1]);

      return [
        {
          center: [lng, lat],
          geometry: {
            type: "Point",
            coordinates: [lng, lat],
          },
          place_name: "Coordinates: " + lat + ", " + lng,
          place_type: ["coordinate"],
          properties: {},
          type: "Feature",
        },
      ];
    };

    getUserLocation();
    mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_API;

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [73.9329, 19.0595],
      zoom: 18,
    });

    mapRef.current.on("load", () => {
      mapRef.current.addSource("google-satellite", {
        type: "raster",
        tiles: ["https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"],
        tileSize: 256,
      });

      mapRef.current.addLayer({
        id: "google-satellite-layer",
        type: "raster",
        source: "google-satellite",
        paint: {
          "raster-opacity": 1,
        },
      });
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      flyTo: {
        bearing: 0,
        speed: 2,
        curve: 1,
        easing: (t) => t,
      },
      mapboxgl: mapboxgl,
      localGeocoder: coordinatesGeocoder,
    });

    mapRef.current.addControl(geocoder);

    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
    });

    mapRef.current.addControl(drawRef.current);

    mapRef.current.on("draw.create", updateCoordinates);
    mapRef.current.on("draw.update", updateCoordinates);

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, [getUserLocation, updateCoordinates]);

  return (
    // <Main open={open}>
    // <DrawerHeader />
    <>
      {!showSuccessAlert && (
        <Box
          sx={{
            marginTop: "100px",
            maxWidth: "95vw",
            display: "flex",
            gap: "50px",
            mr: "20px",
            ml: "20px",
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingBottom: "20px",
            // height: "720px",
            overflowY: "hidden",

            // overflowX: "hidden",
            flexDirection: "row",
            backgroundColor: "#ffffff",
            // marginTop: "50px",
            borderRadius: "20px",
          }}
        >
          <Grid
            container
            md={4}
            sm={4}
            xs={4}
            spacing={1}
            p={3}
            sx={{
              // Width: "450px",
              bgcolor: "white",
              borderRadius: "10px",
              boxShadow: 4,
              // marginLeft: "5px",
              // height: "calc(100vh - 102px)", // Adjusted height
              overflowY: "hidden",
              backgroundColor: "#224831",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "rgba(252, 175, 3, 0.5)",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "rgba(252, 175, 3, 0.8)",
              },
            }}
          >
            <Grid item>
              <Typography
                // variant="h5"
                sx={{
                  color: "#ffffff",
                  padding: "0px !important",
                  fontWeight: "bold",
                  fontSize: "1.6rem",
                  marginTop: "-10px",
                }}
              >
                {t("Farm Onboarding Form")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Typography color="#fcaf03">{t("Mobile")}</Typography>
              <TextField
                // label="Mobile"
                value={mobile}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleMobileChange(e);
                  }
                }}
                fullWidth
                variant="outlined"
                margin="10px" // Reduced margin
                size="small"
                color="success"
                sx={{
                  backgroundColor: "#ffffff",
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#fcaf03", // Label color when typing
                  },
                  borderRadius: "5px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Typography color="#fcaf03">{t("Farm Name")}</Typography>
              <TextField
                // label="Farm Name"
                value={farmName}
                onChange={handleFarmNameChange}
                fullWidth
                variant="outlined"
                margin="10px" // Reduced margin
                size="small"
                color="success"
                sx={{
                  backgroundColor: "#ffffff",
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#fcaf03", // Label color when typing
                  },
                  borderRadius: "5px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Typography color="#fcaf03">{t("Sowing Date")}</Typography>
              <TextField
                // label="Sowing Date"
                value={sowingDate}
                onChange={handleSowingDateChange}
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                margin="10px" // Reduced margin
                size="small"
                color="success"
                sx={{
                  backgroundColor: "#ffffff",
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#fcaf03", // Label color when typing
                  },
                  borderRadius: "5px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Typography sx={{ color: "#fcaf03" }}>
                *{t("Draw farm boundary to populate")}
              </Typography>

              <TextField
                // label="Area"
                value={`${areaInAcres} ${t("acres")}`}
                fullWidth
                InputProps={{ readOnly: true }}
                variant="outlined"
                margin="10px" // Reduced margin
                size="small"
                color="success"
                sx={{
                  backgroundColor: "#ffffff",
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#fcaf03", // Label color when typing
                  },
                  borderRadius: "5px",
                }}
              />
              {areaInAcres < 0.5 && (
                <Typography sx={{ color: "red" }}>{message}</Typography>
              )}
              <CropToggle
                setCropForm={setCrop}
                setIsChecked={setIsChecked}
                isChecked={isChecked}
              />
            </Grid>

            {/* {crop} */}
            <Grid item xs={12} md={12} sm={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
                sx={{
                  bgcolor: "#fcaf03",
                  color: "#224831",
                  "&:hover": { bgcolor: "#224831", color: "#fcaf03" },
                  borderRadius: "10px",
                  padding: "8px 16px", // Reduced padding for compact look
                  borderWidth: "2px",
                  marginLeft: "0px",
                  margin: "5px",
                }}
                disabled={!isFormValid}
              >
                {t("Submit")}
              </Button>
            </Grid>

            <Grid item xs={12} md={12} sm={12}>
              <FileUpload />
            </Grid>
          </Grid>

          <Grid
            container
            xs={8}
            sm={8}
            md={8}
            spacing={3}
            p={3}
            sx={{
              bgcolor: "white",
              borderRadius: "10px",
              boxShadow: 4,
              // height: "calc(100vh - 130px)", // Adjusted height
              overflowY: "hidden",
              backgroundColor: "#224831",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "-8px",
            }}
          >
            <Box
              xs={12}
              ref={mapContainer}
              sx={{
                // flex: 1,
                width: "100%", // Full width to match container
                height: "100%", // Full height to match container
                borderRadius: "10px",
                overflowY: "hidden",
                boxShadow: 4,
                // border: "3px solid #FFFFFF",
              }}
            />
          </Grid>
        </Box>
      )}
      {showSuccessAlert && (
        <video
          src={waitingVid}
          alt="Elai Agritech"
          style={{
            height: "100%",
            width: "100vw",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 99999,
            objectFit: "fill",
          }}
          autoPlay
          // controls
          loop
          muted
        ></video>
      )}
    </>
  );
};
export default Map;
