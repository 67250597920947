import React, { useState } from "react";
import * as XLSX from "xlsx";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Paper,
  Button,
} from "@mui/material";

const FileUpload = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      alert("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      // Replace with actual implementation for file conversion
      const jsonData = await convertFileToJson(formData);

      // Replace with actual implementation for MongoDB upload
      await uploadJsonToMongo(jsonData);

      alert("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file. Please try again.");
    }
  };

  // Function to convert file to JSON (using 'xlsx' library for Excel)
  const convertFileToJson = async (formData) => {
    const file = formData.get("file");
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        const data = event.target.result;
        // Example using 'xlsx' library to parse Excel file to JSON
        const workbook = XLSX.read(data, { type: "binary" });
        const jsonData = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]]
        );
        resolve(jsonData);
      };

      reader.onerror = (error) => reject(error);

      reader.readAsBinaryString(file);
    });
  };

  // Function to upload JSON data to MongoDB
  const uploadJsonToMongo = async (jsonData) => {
    try {
      const response = await fetch(
        "https://3mpqory1se.execute-api.ap-south-1.amazonaws.com/prod/Excelupload",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(jsonData), // Ensure jsonData is an array of objects
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          `Failed to upload JSON data to MongoDB: ${errorResponse.message}`
        );
      }

      const result = await response.json();
    } catch (error) {
      console.error("Error uploading JSON to MongoDB:", error);
      throw new Error("Error uploading JSON to MongoDB: " + error.message);
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center",}}>
      <Card sx={{ maxWidth: 600, }}>
        <CardContent>
          <Typography variant="h8" color="#fcaf03" gutterBottom>
            Upload bulk excel/csv File
          </Typography>
          <Paper elevation={3} sx={{ padding: 2 , backgroundColor: 'rgba(0, 0, 0, 0.1)',}}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <input
                    type="file"
                    accept=".csv,.xlsx,.xls"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file-input"
                  />
                  <label htmlFor="file-input">
                    <Button
                      variant="contained"
                      component="span"
                 
                      style={{
                        padding: "8px 16px",
                        margin: "0 8px",
                        backgroundColor: "white",
                        color: "#224831",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Select File
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      padding: "8px 16px",
                      margin: "0 8px",
                      backgroundColor: "#fcaf03",
                      color: "#224831",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Upload
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FileUpload;