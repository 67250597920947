// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/assets/img/background4.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); 
    /* background-color: '#D4F6FF'; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed; /* Keeps the image fixed while scrolling */
}

.button{
    background-color: #fcaf03;
    color: #224831;
}


`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;IACT,UAAU;IACV,yDAA0D;IAC1D,iCAAiC;IACjC,sBAAsB;IACtB,4BAA4B;IAC5B,2BAA2B;IAC3B,4BAA4B,EAAE,0CAA0C;AAC5E;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":["body, html, #root {\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    background-image: url('../src/assets/img/background4.jpg'); \n    /* background-color: '#D4F6FF'; */\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center;\n    background-attachment: fixed; /* Keeps the image fixed while scrolling */\n}\n\n.button{\n    background-color: #fcaf03;\n    color: #224831;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
