
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContextProvider';

const PrivateRoute = ({ allowedRoles }) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  return allowedRoles.includes(user.mobile.role) ? <Outlet /> : <Navigate to="/unauthorized" />;
};

export default PrivateRoute;
