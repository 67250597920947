import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Pagination,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import Map from "../components/MapOverview";
import LoadingAnimation from "../components/LoadingAnimation";
import authService from "../service/auth-service";
import { useNavigate } from "react-router-dom";

function Banker() {
  const navigate = useNavigate();

  const [surveyData, setSurveyData] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const [villages, setVillages] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedTaluka, setSelectedTaluka] = useState("");
  const [selectedVillage, setSelectedVillage] = useState("");
  const [selectedSurveyNumber, setSelectedSurveyNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredSurveyNumbers, setFilteredSurveyNumbers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [surveyDataBySurveyNumber, setSurveyDataBySurveyNumber] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const surveysPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://3mpqory1se.execute-api.ap-south-1.amazonaws.com/prod/getCadastralSurveyData"
        );
        const data = response.data;
        setSurveyData(data);
        const uniqueStates = [...new Set(data.map((item) => item.State))];
        setStates(uniqueStates);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedState) {
      const filteredDistricts = surveyData
        .filter((item) => item.State === selectedState)
        .map((item) => item.District);
      setDistricts([...new Set(filteredDistricts)]);
    }
  }, [selectedState, surveyData]);

  useEffect(() => {
    if (selectedDistrict) {
      const filteredTalukas = surveyData
        .filter((item) => item.District === selectedDistrict)
        .map((item) => item.Taluka);
      setTalukas([...new Set(filteredTalukas)]);
    }
  }, [selectedDistrict, surveyData]);

  useEffect(() => {
    if (selectedTaluka) {
      const filteredVillages = surveyData
        .filter((item) => item.Taluka === selectedTaluka)
        .map((item) => item.Village);
      setVillages([...new Set(filteredVillages)]);
    }
  }, [selectedTaluka, surveyData]);

  useEffect(() => {
    if (selectedVillage) {
      const filteredSurveyNumbers = surveyData
        .filter(
          (item) =>
            item.State === selectedState &&
            item.District === selectedDistrict &&
            item.Taluka === selectedTaluka &&
            item.Village === selectedVillage
        )
        .map((item) => item.Survey_No);
      setFilteredSurveyNumbers(filteredSurveyNumbers);
      setCurrentPage(1);
    }
  }, [
    selectedVillage,
    selectedState,
    selectedDistrict,
    selectedTaluka,
    surveyData,
  ]);

  useEffect(() => {
    if (selectedSurveyNumber) {
      const filteredData = surveyData.filter(
        (item) => item.Survey_No === selectedSurveyNumber
      );
      setSurveyDataBySurveyNumber(filteredData);
    }
  }, [selectedSurveyNumber, surveyData]);

  const handleStateChange = (event) => {
    const state = event.target.value;
    setSelectedState(state);
    setSelectedDistrict("");
    setSelectedTaluka("");
    setSelectedVillage("");
    setSelectedSurveyNumber("");
  };

  const handleDistrictChange = (event) => {
    const district = event.target.value;
    setSelectedDistrict(district);
    setSelectedTaluka("");
    setSelectedVillage("");
    setSelectedSurveyNumber("");
  };

  const handleTalukaChange = (event) => {
    const taluka = event.target.value;
    setSelectedTaluka(taluka);
    setSelectedVillage("");
    setSelectedSurveyNumber("");
  };

  const handleVillageChange = (event) => {
    setSelectedVillage(event.target.value);
    setSelectedSurveyNumber("");
  };

  const handleSurveyNumberChange = (event) => {
    setSelectedSurveyNumber(event.target.value);
  };

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const indexOfLastSurvey = currentPage * surveysPerPage;
  const indexOfFirstSurvey = indexOfLastSurvey - surveysPerPage;
  const currentSurveys = searchQuery
    ? filteredSurveyNumbers.filter((surveyNo) => surveyNo.includes(searchQuery))
    : filteredSurveyNumbers.slice(indexOfFirstSurvey, indexOfLastSurvey);

  const renderSurveyNumbers = currentSurveys.map((surveyNo, index) => (
    <MenuItem key={index} value={surveyNo}>
      {surveyNo}
    </MenuItem>
  ));

  const totalPages = Math.ceil(filteredSurveyNumbers.length / surveysPerPage);

  const hanldeLogout = () => {
    authService.logout();
    navigate("/");
  };

  return states.length !== 0 ? (
    <div style={{ display: "flex", flexDirection: "row" ,padding:"15px",gap:"15px"}}>
      <Grid item xs={3}>
        {loading && <CircularProgress />}

        <FormControl sx={{ minWidth: 200 }} disabled={loading}>
          <InputLabel>State</InputLabel>
          <Select value={selectedState} onChange={handleStateChange}>
            {states.map((state, index) => (
              <MenuItem key={index} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <br />
        <FormControl sx={{ minWidth: 200 }} disabled={loading}>
          <InputLabel>District</InputLabel>
          <Select value={selectedDistrict} onChange={handleDistrictChange}>
            {districts.map((district, index) => (
              <MenuItem key={index} value={district}>
                {district}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <br />
        <FormControl sx={{ minWidth: 200 }} disabled={loading}>
          <InputLabel>Taluka</InputLabel>
          <Select value={selectedTaluka} onChange={handleTalukaChange}>
            {talukas.map((taluka, index) => (
              <MenuItem key={index} value={taluka}>
                {taluka}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <br />
        <FormControl sx={{ minWidth: 200 }} disabled={loading}>
          <InputLabel>Village</InputLabel>
          <Select value={selectedVillage} onChange={handleVillageChange}>
            {villages.map((village, index) => (
              <MenuItem key={index} value={village}>
                {village}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <br />
        <TextField
          id="from-date"
          label="From Date"
          type="date"
          value={fromDate}
          onChange={handleFromDateChange}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ minWidth: 200 }}
          disabled={loading}
        />
        <br />
        <br />
        <TextField
          id="to-date"
          label="To Date"
          type="date"
          value={toDate}
          onChange={handleToDateChange}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ minWidth: 200 }}
          disabled={loading}
        />
        <br />
        <br />
        <TextField
          id="search-survey-number"
          label="Search Survey Number"
          type="text"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ minWidth: 200 }}
        />
        <br />
        <br />
        <FormControl sx={{ minWidth: 200 }} disabled={loading}>
          <InputLabel>Survey Number</InputLabel>
          <Select
            value={selectedSurveyNumber}
            onChange={handleSurveyNumberChange}
          >
            {renderSurveyNumbers}
          </Select>
        </FormControl>
        <br />
        <br />
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          variant="outlined"
          shape="rounded"
          size="large"
        />
      </Grid>
      <Grid item xs={7} style={{width:"100%"}}>
        <Map
          FarmerData={surveyDataBySurveyNumber}
          from_date={fromDate}
          to_date={toDate}
          disabled={loading}
        />
      </Grid>
      <div style={{ marginLeft: "10px" }}>
        <Button variant="contained" color="success" onClick={hanldeLogout}>
          Logout
        </Button>
      </div>
    </div>
  ) : (
    <LoadingAnimation />
  );
}

export default Banker;
