import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import translationEn from "./locales/en/translation.json";
import translationAr from "./locales/ar/translation.json";

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
  debug: true,
  lng: "ar",
  fallbackLng: "en",

  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },

  resources: {
    en: {
      translation: translationEn,
    },
    ar: {
      translation: translationAr,
    },
    hi: {
      translation: {
        greeting: "नमस्ते, स्वागत है!",
      },
    },
  },
//   ns: ["translation"],
//   defaultNS: "translation",
});

export default i18n;
