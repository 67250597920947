import React, { useEffect, useState } from 'react';
import useStyles from './Style';
import HarvestTable from './Harvesttable';
import { Card, Grid, Typography ,Box} from '@mui/material';

const HarvestWindow = ({ filteroutData = [] }) => {
  
  const classes = useStyles();
  const [thisWeekCount, setThisWeekCount] = useState(0);
  const [thisWeekQuantity, setThisWeekQuantity] = useState(0);
  const [nextWeekCount, setNextWeekCount] = useState(0);
  const [nextWeekQuantity, setNextWeekQuantity] = useState(0);

  useEffect(() => {
    const currentDate = new Date();
    let thisWeekCount = 0;
    let thisWeekQuantity = 0;
    let nextWeekCount = 0;
    let nextWeekQuantity = 0;

    filteroutData.forEach((farm) => {
      const harvestDate = new Date(farm.harvest_date);

      // Check if the harvest date is within 7 days (this week)
      const daysDifference = (harvestDate - currentDate) / (1000 * 60 * 60 * 24);
      if (daysDifference >= 0 && daysDifference <= 7) {
        thisWeekCount++;
        thisWeekQuantity += farm.crop_yield || 0;
      } else if (daysDifference > 7 && daysDifference <= 15) {
        nextWeekCount++;
        nextWeekQuantity += farm.crop_yield || 0;
      }
    });

    setThisWeekCount(thisWeekCount);
    setThisWeekQuantity(thisWeekQuantity);
    setNextWeekCount(nextWeekCount);
    setNextWeekQuantity(nextWeekQuantity);
  }, [filteroutData]);

  return (
    <Box className={classes.container} sx={{ display: 'flex', color: '#000000' }}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <HarvestTable filteroutData={filteroutData} />
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: 'flex', height: '300px' }}>
          <div sx={{ display: 'flex', flexDirection: 'column' }}>
            <Card
              sx={{
                display: 'flex',
                justifyContent: 'center',
                // alignItems: 'center',
                flexDirection: 'column',
                width: '350px',
                height: '275px',
                marginLeft: '45px',
                marginBottom: '15px',
                borderRadius: "10px",
                  backgroundColor: "#224831",
                  paddingLeft:'20px'
                 
              }}
            >
              <Typography variant="h5" color="#fcaf03" sx={{fontWeight:'bold'}}>
                Harvesting this week
              </Typography>
              <Typography variant="h4" color="white" sx={{ marginBottom: '30px' }}>
                {thisWeekCount} Farms
              </Typography>
              <Typography variant="h5" color="#fcaf03" sx={{fontWeight:'bold'}}>
                Harvest quantity this week
              </Typography>
              <Typography variant="h4" color="white">
                {thisWeekQuantity.toFixed(2)} Tonnes
              </Typography>
            </Card>

            <Card
              sx={{
                display: 'flex',
                justifyContent: 'center',
                // alignItems: 'center',
                flexDirection: 'column',
                width: '350px',
                height: '275px',
                marginLeft: '45px',
                borderRadius: "10px",
                  backgroundColor: "#224831",
                  marginBottom: "15px",
                  paddingLeft:'20px'


                 
              }}
            >
              <Typography variant="h5" color="#fcaf03" sx={{fontWeight:'bold'}}>
                Harvesting next week
              </Typography>
              <Typography variant="h4" color="white" sx={{ marginBottom: '30px' }}>
                {nextWeekCount} Farms
              </Typography>
              <Typography variant="h5" color="#fcaf03" sx={{fontWeight:'bold'}}>
                Harvest quantity next week
              </Typography>
              <Typography variant="h4" color="white">
                {nextWeekQuantity.toFixed(2)} Tonnes
              </Typography>
            </Card>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HarvestWindow;
