import React from "react";
import { Card, Typography } from "@mui/material";
import { SummaryCard } from "./Style";
// import useStyles from "./Style";

const Summarycards = ({
  titleone,
  subHeader,
  contentone,
  titletwo,
  contenttwo,
}) => {
  // const classes = useStyles();
  return (
    <SummaryCard sx={{margin:"0px 20px"}}>
      <Typography variant="h5" color="#fcaf03" sx={{ fontWeight: "bold" }}>
        {titleone}
      </Typography>
      <Typography variant="h7" color="#FFFFFF">
        {subHeader}
      </Typography>
      {/* Check if contentone is a React element */}
      {React.isValidElement(contentone) ? (
        contentone
      ) : (
        <Typography variant="h5" color="white">
          {contentone}
        </Typography>
      )}

      <Typography variant="h5" color="#fcaf03" sx={{ fontWeight: "bold" }}>
        {titletwo}
      </Typography>
      <Typography variant="h5" color="white">
        {contenttwo}
      </Typography>
    </SummaryCard>
  );
};

export default Summarycards;
