import React from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';

export default function App({ crop, soilData }) {
  // Simulated larger crop recommendations based on CSV data
  const cropRecommendations = {
    Cotton: {
      N: { Low: { kg: '217.4', bags: '4.3' }, Medium: { kg: '108.7', bags: '2.2' }, High: { kg: '0.0', bags: '0.0' } },
      P: { Low: { kg: '312.5', bags: '6.3' }, Medium: { kg: '156.25', bags: '3.1' }, High: { kg: '0.0', bags: '0.0' } },
      K: { Low: { kg: '83.3', bags: '1.7' }, Medium: { kg: '41.7', bags: '0.8' }, High: { kg: '0.0', bags: '0.0' } },
      expenditureLevel: 'High'
    },
    Gram: {
      N: { Low: { kg: '65.2', bags: '1.3' }, Medium: { kg: '32.6', bags: '0.7' }, High: { kg: '0.0', bags: '0.0' } },
      P: { Low: { kg: '312.5', bags: '6.3' }, Medium: { kg: '156.25', bags: '3.1' }, High: { kg: '0.0', bags: '0.0' } },
      K: { Low: { kg: '41.7', bags: '0.8' }, Medium: { kg: '20.8', bags: '0.4' }, High: { kg: '0.0', bags: '0.0' } },
      expenditureLevel: 'High'
    },
    Maize: {
      N: { Low: { kg: '293.5', bags: '5.9' }, Medium: { kg: '146.7', bags: '2.9' }, High: { kg: '0.0', bags: '0.0' } },
      P: { Low: { kg: '406.25', bags: '8.1' }, Medium: { kg: '203.12', bags: '4.1' }, High: { kg: '0.0', bags: '0.0' } },
      K: { Low: { kg: '75.0', bags: '1.5' }, Medium: { kg: '37.5', bags: '0.8' }, High: { kg: '0.0', bags: '0.0' } },
      expenditureLevel: 'Medium'
    },
    Mustard: {
      N: { Low: { kg: '152.2', bags: '3.0' }, Medium: { kg: '76.1', bags: '1.5' }, High: { kg: '0.0', bags: '0.0' } },
      P: { Low: { kg: '218.75', bags: '4.4' }, Medium: { kg: '109.38', bags: '2.2' }, High: { kg: '0.0', bags: '0.0' } },
      K: { Low: { kg: '41.7', bags: '0.8' }, Medium: { kg: '20.8', bags: '0.4' }, High: { kg: '0.0', bags: '0.0' } },
      expenditureLevel: 'Medium'
    },
    Onion: {
      N: { Low: { kg: '239.1', bags: '4.8' }, Medium: { kg: '119.6', bags: '2.4' }, High: { kg: '0.0', bags: '0.0' } },
      P: { Low: { kg: '437.5', bags: '8.8' }, Medium: { kg: '218.75', bags: '4.4' }, High: { kg: '0.0', bags: '0.0' } },
      K: { Low: { kg: '91.7', bags: '1.8' }, Medium: { kg: '45.8', bags: '0.9' }, High: { kg: '0.0', bags: '0.0' } },
      expenditureLevel: 'Medium'
    },
    Potato: {
      N: { Low: { kg: '293.5', bags: '5.9' }, Medium: { kg: '146.7', bags: '2.9' }, High: { kg: '0.0', bags: '0.0' } },
      P: { Low: { kg: '562.5', bags: '11.3' }, Medium: { kg: '281.25', bags: '5.6' }, High: { kg: '0.0', bags: '0.0' } },
      K: { Low: { kg: '208.3', bags: '4.2' }, Medium: { kg: '104.2', bags: '2.1' }, High: { kg: '0.0', bags: '0.0' } },
      expenditureLevel: 'High'
    },
    Rice: {
      N: { Low: { kg: '217.4', bags: '4.3' }, Medium: { kg: '108.7', bags: '2.2' }, High: { kg: '0.0', bags: '0.0' } },
      P: { Low: { kg: '312.5', bags: '6.3' }, Medium: { kg: '156.25', bags: '3.1' }, High: { kg: '0.0', bags: '0.0' } },
      K: { Low: { kg: '66.7', bags: '1.3' }, Medium: { kg: '33.3', bags: '0.7' }, High: { kg: '0.0', bags: '0.0' } },
      expenditureLevel: 'Medium'
    },
    Soybean: {
      N: { Low: { kg: '54.4', bags: '1.1' }, Medium: { kg: '27.2', bags: '0.5' }, High: { kg: '0.0', bags: '0.0' } },
      P: { Low: { kg: '406.25', bags: '8.1' }, Medium: { kg: '203.12', bags: '4.1' }, High: { kg: '0.0', bags: '0.0' } },
      K: { Low: { kg: '75.0', bags: '1.5' }, Medium: { kg: '37.5', bags: '0.8' }, High: { kg: '0.0', bags: '0.0' } },
      expenditureLevel: 'Medium'
    },
    Wheat: {
      N: { Low: { kg: '239.1', bags: '4.8' }, Medium: { kg: '119.6', bags: '2.4' }, High: { kg: '0.0', bags: '0.0' } },
      P: { Low: { kg: '343.75', bags: '6.9' }, Medium: { kg: '171.875', bags: '3.4' }, High: { kg: '0.0', bags: '0.0' } },
      K: { Low: { kg: '91.7', bags: '1.8' }, Medium: { kg: '45.8', bags: '0.9' }, High: { kg: '0.0', bags: '0.0' } },
      expenditureLevel: 'Low'
    }
  };

  // Get recommendations for the selected crop
  const cropData = cropRecommendations[crop] || {};

  // Define nutrient data and range values based on simulated CSV-like logic
  const nutrientData = [
    {
      parameter: 'Available Nitrogen(N)',
      class: soilData.N_Predicted,
      rangeValue1: soilData.N_Predicted === 'Low' ? '< 280 kg/ha' : soilData.N_Predicted === 'Medium' ? '280 - 480 kg/ha' : '> 480 kg/ha',
      rangeValue2: 'High: > 480 kg/ha\nMedium: 280 - 480 kg/ha\nLow: < 280 kg/ha'
    },
    {
      parameter: 'Available Phosphorus(P)',
      class: soilData.P_Predicted,
      rangeValue1: soilData.P_Predicted === 'Low' ? '< 11 kg/ha' : soilData.P_Predicted === 'Medium' ? '11 - 22 kg/ha' : '> 22 kg/ha',
      rangeValue2: 'High: > 22 kg/ha\nMedium: 11 - 22 kg/ha\nLow: < 11 kg/ha'
    },
    {
      parameter: 'Available Potassium(K)',
      class: soilData.K_Predicted,
      rangeValue1: soilData.K_Predicted === 'Low' ? '< 110 kg/ha' : soilData.K_Predicted === 'Medium' ? '110 - 280 kg/ha' : '> 280 kg/ha',
      rangeValue2: 'High: > 280 kg/ha\nMedium: 110 - 280 kg/ha\nLow: < 110 kg/ha'
    },
    {
      parameter: 'Soil pH',
      class: soilData.pH_Predicted,
      rangeValue1: soilData.pH_Predicted === 'Acidic' ? '< 6' : soilData.pH_Predicted === 'Normal to Saline' ? '6.0 - 8.5' : soilData.pH_Predicted === 'Alkaline' ? '> 9.0' : '8.5 - 9.0',
      rangeValue2: 'Acidic: < 6.0\nNormal to Saline: 6.0 - 8.5\nTending to become Alkaline: 8.5 - 9.0\nAlkaline: > 9.0'
    },
    {
      parameter: 'Electrical Conductivity(EC)',
      class: soilData.EC_Predicted,
      rangeValue1: soilData.EC_Predicted === 'Low' ? '< 0.2 dS/m' : soilData.EC_Predicted === 'Medium' ? '0.2 - 0.8 dS/m' : '> 0.8 dS/m',
      rangeValue2: 'High: > 0.8 dS/m\nMedium: 0.2 - 0.8 dS/m\nLow: < 0.2 dS/m'
    },
    {
      parameter: 'Organic Carbon(OC)',
      class: soilData.OC_Predicted,
      rangeValue1: soilData.OC_Predicted === 'Low' ? '< 0.5%' : soilData.OC_Predicted === 'Medium' ? '0.5 - 0.75%' : '> 0.75%',
      rangeValue2: 'High: > 0.5\nMedium: 0.5 - 0.75\nLow: < 0.75'
    }
  ];

  // Define fertilizer data based on crop and soil data
  const fertilizerData = [
    { fertilizer: 'Urea', kg: cropData.N?.[soilData.N_Predicted]?.kg || 'N/A', bags: cropData.N?.[soilData.N_Predicted]?.bags || 'N/A' },
    { fertilizer: 'Single Superphosphate (SSP)', kg: cropData.P?.[soilData.P_Predicted]?.kg || 'N/A', bags: cropData.P?.[soilData.P_Predicted]?.bags || 'N/A' },
    { fertilizer: 'Muriate of Potash(MOP)', kg: cropData.K?.[soilData.K_Predicted]?.kg || 'N/A', bags: cropData.K?.[soilData.K_Predicted]?.bags || 'N/A' }
  ];

  const expenditureLevel = cropData.expenditureLevel || 'N/A';

  // Render the component UI
  const commonStyle = {
     background:'#224831',
    border: '2px solid #000000',
    borderRadius: '25px',
    width: '100%',
    color:'#000000'
  };

  const headerStyle = {
    padding: '4px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#fcaf03',
    borderRadius: '25px',
    
  };

  const renderNutrientRows = () => nutrientData.map((item, index) => (
    <React.Fragment key={index}>
      <Grid item xs={3} sx={{display:"flex",alignItems:'center', padding: '8px', borderTop:  "1px solid #ffffff", borderRight:  "1px solid #ffffff", color: '#ffffff' }}>
        {item.parameter}
      </Grid>
      <Grid item xs={2} sx={{ display:"flex",alignItems:'center',padding: '8px', borderTop:  "1px solid #ffffff", borderRight:  "1px solid #ffffff", color: '#ffffff' }}>
        {item.class}
      </Grid>
      <Grid item xs={3} sx={{display:"flex",alignItems:'center', padding: '8px', borderTop:  "1px solid #ffffff", borderRight:  "1px solid #ffffff", color: '#ffffff' }}>
        {item.rangeValue1}
      </Grid>
      <Grid item xs={4} sx={{ display:"flex",alignItems:'center',textAlign: 'left', padding: '8px', borderTop:  "1px solid #ffffff", color: '#ffffff' }}>
        {item.rangeValue2}
      </Grid>
    </React.Fragment>
  ));

  const renderFertilizerRows = () => fertilizerData.map((item, index) => (
    <React.Fragment key={index}>
      <Grid item xs={4} sx={{display:"flex",alignItems:'center',  padding: '8px', borderTop:  "1px solid #ffffff", borderRight:  "1px solid #ffffff", color: '#ffffff' }}>
        {item.fertilizer}
      </Grid>
      <Grid item xs={4} sx={{ display:"flex",alignItems:'center', padding: '8px', borderTop:  "1px solid #ffffff", borderRight:  "1px solid #ffffff", color: '#ffffff' }}>
        {item.kg}
      </Grid>
      <Grid item xs={4} sx={{ display:"flex",alignItems:'center', padding: '8px', borderTop:  "1px solid #ffffff", color: '#ffffff' }}>
        {item.bags}
      </Grid>
    </React.Fragment>
  ));

  return (
    <Box sx={{ padding: 2 , }}>
      {/* <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold' }}>
        Soil Health Data for {crop}
      </Typography> */}

      <Grid container spacing={2} sx={{paddingLeft:'0px'}}>
        <Grid item xs={12} md={8}  >
          <Paper sx={{   backgroundColor:"#224831", borderRadius:'10px',   paddingLeft:'0px'}}>
            <Typography variant="h5" sx={{ color: '#fcaf03', fontWeight: 'bold',marginLeft:'20px',marginBottom:'20px'}}>
              Tested Nutrients Level
            </Typography>
            <Grid container>
              <Grid item xs={2} sx={headerStyle}>Parameter</Grid>
              <Grid item xs={3} sx={headerStyle}>Class</Grid>
              <Grid item xs={1} sx={{ ...headerStyle, borderRight: 'none' }} >Range</Grid>
              <Grid item xs={6} sx={{ ...headerStyle, borderRight: 'none' }} >Reference</Grid>
            </Grid>
            <Grid container>{renderNutrientRows()}</Grid>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Paper sx={{ ...commonStyle , border:"0px", borderRadius:'10px',marginLeft:'15px'}}>
            <Typography variant="h5" sx={{ color: '#fcaf03', fontWeight: 'bold',marginLeft:'20px', marginBottom:'20px' }}>
              Fertilizer Recommendation
            </Typography>
            <Grid container>
              <Grid item xs={3} sx={headerStyle}>Fertilizer</Grid>
              <Grid item xs={4} sx={headerStyle}>kg/ha</Grid>
              <Grid item xs={4} sx={{ ...headerStyle, borderRight: 'none' }}>Bags/ha</Grid>
            </Grid>
            <Grid container>{renderFertilizerRows()}</Grid>
          </Paper>
          
          {/* <Box sx={commonStyle} height="50px" mt={2}>
            <Typography variant="h6" align="center" sx={{ color: '#ff6600', fontWeight: 'bold' }}>
              Fertilizer Expenditure Level: {expenditureLevel}
            </Typography>
          </Box> */}
        </Grid>
      </Grid>
    </Box>
  );
}
