import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
// import "chart.js/auto";
import "chartjs-adapter-date-fns";
import { FarmContext } from "../../contextAPI/FarmContext";
import { useParams } from "react-router-dom";
import '../../i18n'
import { useTranslation } from 'react-i18next';

const ChartComponentForgeojson2 = (isOpen) => {
  const {t} = useTranslation()
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const { mobile, farm_name } = useParams();
  const { indexValue, geoJsonData } = useContext(FarmContext);

  // Load GeoJSON data
  const loadGeoJsonData = async () => {
    if (
      geoJsonData[indexValue]?.features &&
      geoJsonData[indexValue]?.features.length > 0
    ) {
      const parsedData = parseGeoJsonData(geoJsonData[indexValue]);
      console.log("parsedData : ", parsedData)
      debugger
      setChartData(parsedData);
    }
  };

  const applyRollingWindow = (data, windowSize = 2) => {
    if (data.length < windowSize) return data; // Return data as is if it's smaller than window size
  
    const smoothedData = [];
    
    // Apply the moving average to each data point in the window
    for (let i = 0; i < data.length; i++) {
      const start = Math.max(0, i - Math.floor(windowSize / 2));
      const end = Math.min(data.length, i + Math.ceil(windowSize / 2));
      
      const window = data.slice(start, end);
      const windowAverage = window.reduce((sum, val) => sum + val, 0) / window.length;
  
      smoothedData.push(windowAverage);
    }
  
    return smoothedData;
  };

  const parseGeoJsonData = (geojson) => {
    const timestampValues = {};

    geojson.features.forEach((feature) => {
      const value = feature.properties.value;
      const timestampRange = feature.properties.timestamp;
      // console.log("geojson : ", geojson)
      const startDate = timestampRange?.split("_to_")[1];
      const parsedDate = new Date(startDate);

      if (!timestampValues[parsedDate]) {
        timestampValues[parsedDate] = [];
      }
      timestampValues[parsedDate].push(value);
    });

    const meanValuesByTimestamp = Object.keys(timestampValues).map(
      (timestamp) => {
        const values = timestampValues[timestamp];
        const meanValue =
          values.reduce((sum, val) => sum + val, 0) / values.length;
        return {
          timestamp: new Date(timestamp),
          meanValue,
        };
      }
    );

    meanValuesByTimestamp.sort((a, b) => a.timestamp - b.timestamp);

    const timestamps = meanValuesByTimestamp.map((item) => item.timestamp);
    // console.log("timestamps : ", timestamps)
    const meanValues = meanValuesByTimestamp.map((item) => item.meanValue);

    const smoothedMeanValues = applyRollingWindow(meanValues);

    return {
      labels: timestamps,
      datasets: [
        {
          label: t(`Mean Values`),
          data: smoothedMeanValues,
          borderColor: "#336600",
          backgroundColor: "rgba(256,256,256,0)",
          borderWidth: 2,
          fill: true,
          tension: 0.4,
        },
      ],
    };
  };

  useEffect(() => {
    loadGeoJsonData();
  }, [mobile, farm_name, indexValue, geoJsonData]);

  return (
    <>
{isOpen && isOpen.isOpen && (
  <div
  style={{
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    minHeight: "400px",
    minWidth: "100%",
  }}
>
      <Line
        style={{ minWidth: "100%", minHeight: "400px" }}
        height={200}
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              mode: "index",
              intersect: false,
            },
          },
          scales: {
            x: {
              type: "time", // Ensure this is set to 'time' for date parsing
              time: {
                unit: "week", // Change to 'day', 'week', 'month' depending on your data granularity
                tooltipFormat: "yyyy-MM-dd", // Tooltip date format
              },
              title: {
                display: true,
                text: "Timestamp",
                font: {
                  weight: "bold",
                  size: 16,
                },
              },
              ticks: {
                maxRotation: 0,
                minRotation: 0,
                font: {
                  weight: "bold", // Make the title bold
                  size: 14, // Set the font size to make it more visible
                },
              },
            },
            y: {
              min: 0.0,  // Set minimum value
              // max: 1.0,
              title: {
                display: true,
                text: "Mean Value",
                font: {
                  weight: "bold",
                  size: 16,
                },
              },
              ticks: {
                stepSize: 0.1,
                maxRotation: 0,
                minRotation: 0,
                font: {
                  weight: "bold", // Make the title bold
                  size: 14, // Set the font size to make it more visible
                },
              },
              beginAtZero: true,
            },
          },
        }}
      />
      </div>
    )}
    </>
  );
};

export default ChartComponentForgeojson2;
