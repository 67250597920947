import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
// import "chart.js/auto";
import "chartjs-adapter-date-fns";
// import { FarmContext } from "../contextAPI/FarmContext";
// import { useParams } from "react-router-dom";
import "../i18n";
import { useTranslation } from "react-i18next";
import { WeatherButton } from "./Weather2";
import { Box } from "@mui/material";

const ChartComponentForgeojson2 = ({
  isOpen,
  meanNDVI,
  meanNDMI,
  meanPSRI,
  sugarData,
}) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  // const { mobile, farm_name } = useParams();
  // const { indexValue, geoJsonData } = useContext(FarmContext);
  // const [selectedIndex, setSelectedIndex] = useState(meanNDVI);
  const [selectedDataset, setSelectedDataset] = useState("Temperature");

  // useEffect(() => {
  //   handleIndex("NDVI");
  // setChartData
  // console.log("sugarData before : ", sugarData)
  // if (meanNDVI && Object.keys(meanNDVI).length > 0) {
  // Parse meanNDVI values
  // console.log("sugarData after : ", sugarData)
  // console.log("meanNDVI : ", meanNDVI)
  // debugger
  // const timestamps = Object.keys(meanNDVI);
  // const ndviValues = Object.values(meanNDVI);

  // const labels = timestamps.map((timestamp) => new Date(timestamp));
  // debugger
  // setChartData({
  //   labels: labels,
  //   datasets: [
  //     {
  //       label: t('Mean NDVI Values'),
  //       data: ndviValues,
  //       borderColor: '#336600',
  //       backgroundColor: 'rgba(256,256,256,0)',
  //       borderWidth: 2,
  //       fill: true,
  //       tension: 0.5,
  //     },
  //   ],
  // });
  // }
  // }, [])

  useEffect(() => {
    if (meanNDVI) {
      handleIndex(meanNDVI); // Set the default chart data to NDVI
    }
  }, [meanNDVI]);

  const handleIndex = (index) => {
    const selectedIndex =
      index === meanNDVI ? "NDVI" : index === meanNDMI ? "NDMI" : "PSRI";
    setSelectedDataset(selectedIndex);
    const timestamps = Object.keys(index);
    const ndviValues = Object.values(index);

    const labels = timestamps.map((timestamp) => new Date(timestamp));
    // debugger;
    setChartData({
      labels: labels,
      datasets: [
        {
          label: t("Mean NDVI Values"),
          data: ndviValues,
          borderColor: "#336600",
          backgroundColor: "rgba(256,256,256,0)",
          borderWidth: 2,
          fill: true,
          tension: 0.4,
        },
      ],
    });
  };

  return (
    <>
      {isOpen && (
        <div
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            minHeight: "250px",
            minWidth: "90vw",
            padding: "20px"
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              justifyItems: "center",
              marginBottom: "5px",
              // marginTop: "5px",
            }}
          >
            <WeatherButton
              variant="outlined"
              onClick={() => handleIndex(meanNDVI)}
              selected={selectedDataset === "NDVI"}
            >
              {t("Vegetation")}
            </WeatherButton>
            <WeatherButton
              variant="outlined"
              onClick={() => handleIndex(meanPSRI)}
              selected={selectedDataset === "PSRI"}
            >
              {t("Stress")}
            </WeatherButton>
            <WeatherButton
              variant="outlined"
              onClick={() => handleIndex(meanNDMI)}
              selected={selectedDataset === "NDMI"}
            >
              {t("Moisture")}
            </WeatherButton>
          </Box>
          <Line
            style={{ minWidth: "85vw", minHeight: "200px" }}
            height={100}
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  mode: "index",
                  intersect: false,
                },
              },
              scales: {
                x: {
                  type: "time", // Ensure this is set to 'time' for date parsing
                  time: {
                    unit: "week", // Change to 'day', 'week', 'month' depending on your data granularity
                    tooltipFormat: "yyyy-MM-dd", // Tooltip date format
                  },
                  title: {
                    display: true,
                    text: "Timestamp",
                    font: {
                      weight: "bold",
                      size: 16,
                    },
                  },
                  ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    font: {
                      weight: "bold", // Make the title bold
                      size: 14, // Set the font size to make it more visible
                    },
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "Mean Value",
                    font: {
                      weight: "bold",
                      size: 16,
                    },
                  },
                  ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    font: {
                      weight: "bold", // Make the title bold
                      size: 14, // Set the font size to make it more visible
                    },
                  },
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default ChartComponentForgeojson2;
