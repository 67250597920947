import React from "react";
import { useEffect } from "react";
import { Box, Grid, Typography, Card, CardContent } from "@mui/material";
import { NavLink } from "react-router-dom";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import SatelliteAltOutlinedIcon from "@mui/icons-material/SatelliteAltOutlined";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import MiniDrawer from "../components/MiniDrawer";
import InfoCard from "../components/InfoCard";
import Map from "../maps/Map";
import WeatherChart from "../charts/Weather";
import ChartComponentForgeojson from "../charts/ChartComponentForgeojson";
import LoadingSmallAnimation from "../components/Loadingsmallanimation";
import SoilHealthCard from "../components/SoilHealthCard";
import StressTable from "../components/StressTable";
import { ReactComponent as FarmInfo } from "../Assests/FarmInfo.svg";
import { ReactComponent as Cropdata } from "../Assests/Cropdata.svg";
import { ReactComponent as Crophealth } from "../Assests/Crophealth.svg";
import { ReactComponent as Soilhealth } from "../Assests/Soilhealth.svg";
import weather  from "../Assests/weather.svg";


const NewFarm = ({
  mobile,
  farm_name,
  farmInfoUrl,
  geojsonData,
  currentLayer,
  onLayerChange,
  farmCoordinates,
  healthScore,
  healthCategory,
  lstmData,
  geojsonLoading,
  lstmLoading,
  soilLoading,
  soilData,
  multi_index_stress_results,
}) => {
  const convertToDateFormat = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  useEffect(() => {
    document.body.style.overflow = "unset";
    
    
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: "10px",
        // gap: "5px",
        minheight: "100vh",
        // background: "#224831",
      }}
    >
      <Box>
        {/* <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: "10px",
            marginTop: "10px",
          }}
        >
          <NavLink
            to="/map"
            style={{
              textDecoration: "underline",
              // padding: "10px",
              borderRadius: "50px",
              padding: "12px 14px",
              color: "white",
              borderColor: "#FF6600",
              borderWidth: "2px",
              borderStyle: "solid",
              "&:hover": { borderColor: "#FF6600" },
              backdropFilter: "blur(8px)",
            }}
          >
            Draw new farm
          </NavLink>
        </div> */}

        {geojsonLoading || lstmLoading ? (
          <Typography variant="h5" color="#ff6600" sx={{ textAlign: "center" }}>
            Please wait, data is loading...
            <LoadingSmallAnimation />
          </Typography>
        ) : (
          <Box>
            <Box
              sx={{
                background: "#ffffff",
                padding: "20px",
                marginTop: "70px",
                borderRadius: "10px",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <FarmInfo
                  style={{
                    height: "75px",
                    width: "50px",
                    mt: 1,
                    mb: 2,
                    paddingBottom: "10px",
                  }}
                />
                <Typography
                  sx={{
                    flex: 1,
                    textAlign: "left",
                    marginLeft: "10px",
                    fontSize: "h4.fontSize",
                    color: "#224831",
                    mt: 1,
                    mb: 2,
                    fontWeight: "bold",
                    textUnderlineOffset: "6px",
                    paddingBottom: "10px",
                    fontFamily: "open sans",
                  }}
                >
                  Farm Info
                </Typography>
              </Box>

              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={2.4}>
                  <InfoCard title="Mobile" content={mobile || "N/A"} />
                </Grid>
                <Grid item xs={2.4}>
                  <InfoCard title="Farm Name" content={farm_name || "N/A"} />
                </Grid>
                <Grid item xs={2.4}>
                  <InfoCard
                    title="Health Score"
                    content={
                      healthScore ? Number(healthScore).toFixed(2) : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <InfoCard
                    title="Health Category"
                    content={healthCategory || "N/A"}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <InfoCard
                    title="Farm Area"
                    content={`${
                      lstmData?.farm_area
                        ? Number(lstmData.farm_area * 0.404686).toFixed(2)
                        : "N/A"
                    } ha`}
                  />
                </Grid>
                <Grid item>
                  <Box>
                    <Grid container spacing={3}>
                      <Grid item xs={2.4}>
                        <Card
                          style={{
                            margin: "auto",
                            // width: "calc(50% - 10px)",
                            // minWidth: "250px",
                            // height: "100px",
                            backgroundColor: "#224831",
                            backdropFilter: "blur(10px)",

                            height: "100px",
                            borderRadius: "10px",
                            width: "100%",
                            fontFamily: "open sans",
                          }}
                        >
                          <CardContent style={{ textAlign: "left" }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                fontSize: "15px",
                                color: "#fcaf03",
                              }}
                              variant="h6"
                            >
                              Location
                            </Typography>

                            <Typography
                              style={{
                                fontsize: "15px",
                                color: "#ffffff",
                                textAlign: "left",
                              }}
                              variant="body1"
                            >
                              {`${farmCoordinates.latitude}° N, ${farmCoordinates.longitude}° E`}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item xs={9.6}>
                        <Card
                          sx={{
                            // textAlign: "center",

                            backgroundColor: "#224831",
                            backdropFilter: "blur(10px)",

                            height: "100px",
                            borderRadius: "10px",

                            padding: "16px",
                          }}
                        >
                          {/* Additional information about farm status */}
                          <Typography
                            variant="h6"
                            sx={{
                              color: "#fcaf03",
                              marginTop: 0,
                              textAlign: "left",
                              fontFamily: "open sans",
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                          >
                            Farm Status
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "15px",
                              color: "#ffffff",
                              fontFamily: "open sans",
                            }}
                            variant="h6"
                          >
                            {healthCategory === "Good"
                              ? "The crop has performed excellently throughout its growth cycle. The vegetation health indices consistently indicate a strong and healthy development. There are no significant signs of stress or deficiencies."
                              : healthCategory === "Medium"
                              ? "The crop has shown moderate performance throughout its growth cycle. While overall health is acceptable, there have been periods of stress or deficiencies that have impacted the crop’s development. These deficiencies could have affected growth rates and overall yield."
                              : "The crop has faced significant challenges throughout its growth cycle, showing clear signs of stress and deficiencies. These issues have likely had a considerable impact on the crop’s ability to develop optimally."}
                          </Typography>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                background: "#ffffff",
                padding: "20px",
                marginTop: "20px",

                borderRadius: "10px",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Cropdata
                  style={{
                    height: "75px",
                    width: "50px",
                    mt: 1,
                    mb: 2,
                    paddingBottom: "10px",
                  }}
                />
                <Typography
                  sx={{
                    flex: 1,
                    textAlign: "left",
                    marginLeft: "10px",
                    fontSize: "h4.fontSize",
                    color: "#224831",
                    mt: 1,
                    mb: 2,
                    fontWeight: "bold",
                    textUnderlineOffset: "6px",
                    paddingBottom: "10px",
                    fontFamily: "open sans",
                  }}
                >
                  Crop Data
                </Typography>
              </Box>

              <Grid
                container
                rowSpacing={4}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={3}>
                  <InfoCard
                    title="Crop"
                    content={lstmData?.predicted_crop || "N/A"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InfoCard
                    title="Crop Season"
                    content={lstmData?.crop_season || "N/A"}
                  />
                </Grid>
                {lstmData?.predicted_sowing_date && (
                  <Grid item xs={3}>
                    <InfoCard
                      title="Sowing Date"
                      content={convertToDateFormat(
                        lstmData.predicted_sowing_date
                      )}
                    />
                  </Grid>
                )}
                {lstmData?.harvest_date && (
                  <Grid item xs={3}>
                    <InfoCard
                      title="Harvest Date"
                      content={convertToDateFormat(lstmData.harvest_date)}
                    />
                  </Grid>
                )}
                {lstmData?.crop_yield && (
                  <Grid item xs={3}>
                    <InfoCard
                      title="Productivity"
                      content={`${(
                        (Number(lstmData.crop_yield) * 1000) /
                        0.404686
                      ).toFixed(2)} kg/ha`}
                    />
                  </Grid>
                )}
                {lstmData?.total_yield && (
                  <Grid item xs={3}>
                    <InfoCard
                      title="Total Yield"
                      content={`${(Number(lstmData.total_yield) * 1000).toFixed(
                        2
                      )} kg`}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>

            <Box
              sx={{
                background: "#ffffff",
                padding: "20px",
                marginTop: "20px",

                borderRadius: "10px",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Soilhealth
                  style={{
                    height: "75px",
                    width: "50px",
                    mt: 1,
                    mb: 2,
                    paddingBottom: "00px",
                    marginLeft: "15px",
                  }}
                />
                <Typography
                  sx={{
                    flex: 1,
                    textAlign: "left",
                    marginLeft: "10px",
                    fontSize: "h4.fontSize",
                    color: "#224831",
                    mt: 1,
                    mb: 2,
                    fontWeight: "bold",
                    textUnderlineOffset: "6px",
                    paddingBottom: "0px",
                    fontFamily: "open sans",
                  }}
                >
                  Soil Health
                </Typography>
              </Box>
              <SoilHealthCard
                crop={lstmData?.predicted_crop || "N/A"}
                soilData={soilData}
              />
            </Box>
          </Box>
        )}

        {/* Monitoring Data (Map and GeoJSON) */}
        <Box
          sx={{
            background: "#ffffff",
            padding: "20px",
            marginTop: "20px",

            borderRadius: "10px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Crophealth
              style={{
                height: "75px",
                width: "50px",
                mt: 1,
                mb: 2,
                paddingBottom: "10px",
              }}
            />
            <Typography
              sx={{
                flex: 1,
                textAlign: "left",
                marginLeft: "10px",
                fontSize: "h4.fontSize",
                color: "#224831",
                mt: 1,
                mb: 2,
                fontWeight: "bold",
                textUnderlineOffset: "6px",
                paddingBottom: "10px",
                fontFamily: "open sans",
              }}
            >
              Crop Health Data
            </Typography>
          </Box>
          <StressTable
            xs={12}
            multi_index_stress_results={multi_index_stress_results}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",

              borderRadius: "10px",
              background: "#224831",
              padding: "20px",
              marginTop: "20px",

              borderRadius: "10px",
            }}
          >
            <Card
              sx={{
                background: "#224831",
                padding: "20px",

                flex: "1",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  {/* <MonitorHeartOutlinedIcon
                    sx={{ fontSize: "2rem", color: "#ff6600" }}
                  /> */}
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#fcaf03",
                      // marginLeft: "10px",
                      fontWeight: "bold",
                      fontFamily: "open sans",
                    }}
                  >
                    Realtime Monitoring
                  </Typography>
                </Box>
                <Map
                  farmInfoUrl={farmInfoUrl}
                  geojsonData={geojsonData}
                  currentLayer={currentLayer}
                  onLayerChange={onLayerChange}
                />
              </CardContent>
            </Card>
            <Card
              sx={{
                background: "#224831",
                paddingTop: "80px",
                paddingLeft: "20px",

                flex: "1",
              }}
            >
              <CardContent sx={{ marginTop: "15px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                    // marginLeft:'20px'
                    backgroundColor:'#ffffff',
                    borderRadius:'10px', width:'100%', height:'100%'
                  }}
                >
                  {/* <SatelliteAltOutlinedIcon
                    sx={{ fontSize: "3rem", color: "#224831" }}
                  /> */}
                  
                </Box>
                <ChartComponentForgeojson
                  farmInfoUrl={farmInfoUrl}
                  geojsonData={geojsonData}
                  currentLayer={currentLayer}
                />
              </CardContent>
            </Card>
          </Box>
        </Box>

        {/* <Grid container></Grid> */}
        <Grid container spacing={2} sx={{ marginTop: "10px" }}>
          <Grid item md={12} lg={12}></Grid>

          {/* Satellite Data Visualization */}
        </Grid>

        {/* Weather Data */}
        

        <Grid container spacing={2} sx={{ marginTop: "10px" }}>
          <Grid item md={8} lg={8}>
          <Box sx={{
            background: "#ffffff",
            padding: "20px",
            marginTop: "20px",

            borderRadius: "10px",
          }}>
            <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // marginBottom: "16px",
                  }}
                >
            
                  <img
                  src={weather}
                  style={{ height:'50px', width:'50px', marginBottom:'5px'}}
                  />
          <Typography
            sx={{
              flex: 1,
                textAlign: "left",
                marginLeft: "10px",
                fontSize: "h4.fontSize",
                color: "#224831",
                mt: 1,
                mb: 1,
                fontWeight: "bold",
                // textUnderlineOffset: "6px",
                // paddingBottom: "10px",
                fontFamily: "open sans",
            }}
          >
            Weather Forecasting Data
          </Typography>
          </Box>
          <Card
              sx={{
                background: "#ffffff",
                padding: "20px",
                marginTop: "20px",
                border:'60px solid #224831',
                borderRadius: "10px",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  
                  
                </Box>
                <WeatherChart farmCoordinates={farmCoordinates} />
              </CardContent>
            </Card>
        </Box>
           
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NewFarm;
